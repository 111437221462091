import React from "react";
import { Col, Row, Form, InputGroup, Image } from 'react-bootstrap';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
import NoRecordFound from '../../assets/images/norecordfound.png';


const recordPerPageList = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
  { label: 40, value: 40 },
  { label: 50, value: 50 }
]

const PhoneClinicGrid = (props) => {
  const [totalRecord, setTotalRecord] = React.useState({ label: 20, value: 20 })
  const [paginationLength, setPagelength] = React.useState(1)
  const [paginationValue, setPageValue] = React.useState(1)



  return (
    <div style={{ marginBottom: "1rem" }} >
      <Row style={{ margin: "5px" }} >
        <Col md={10} style={{ textAlign: "start" }} >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <div style={{ whiteSpace: "nowrap", marginRight: "10px" }} ><strong>{props.title}</strong> SEARCH</div>
            <div style={{ height: "3px", width: "100%", background: "#ff1a00" }} >
            </div>
          </div>
        </Col>
        {props.buttionLabel &&
          <Col md={2} style={{ textAlign: "end" }} >
            <Button className="add-button" size="small" variant="contained" onClick={props.onClickButton} >{props.buttionLabel}</Button>
          </Col>
        }
      </Row>
      <Row>
        <TableContainer component={Paper} style={{ padding: 0 }} >
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {props.tableHeadData && props.tableHeadData.map((x, indx) => (
                  !x.isHidden && <TableCell key={indx} align='center' style={{ background: '#f1f1f1', color: '#111827' }} >{x.label}</TableCell>
                ))}
                {!props.disableEdit && <TableCell align='center' style={{ background: '#f1f1f1', color: '#111827' }} >{"EDIT"}</TableCell>}
                {!props.disableDelete && <TableCell align='center' style={{ background: '#f1f1f1', color: '#111827' }} >{"DELETE"}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                className="table-rows"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {props.searchData && props.searchData.map((x, indx) => (
                  !x.isHidden ?
                    <TableCell align='center' key={indx}>
                      <InputGroup size="sm">
                        <Form.Control
                          onChange={props.handleChangeSearch(x.key, indx)}
                          disabled={x.isDisable}
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"
                        />
                      </InputGroup>
                    </TableCell> :
                    <TableCell align='center' key={indx} ></TableCell>
                ))}
                {!props.disableEdit && <TableCell align='center' ></TableCell>}
                {!props.disableDelete && <TableCell align='center' ></TableCell>}
              </TableRow>
              {props.children}
            </TableBody>
          </Table>
          {props.children && props.children.length === 0 &&
            <div style={{ textAlign: 'center', padding: '5rem' }} >
              <Image src={NoRecordFound} alt="No record found !!" />
              <h3>No Record Found !!! </h3>
            </div>
          }
        </TableContainer>
      </Row>
      <Row>
        <Col md={6} style={{ textAlign: "start" }}>
          <FormControl style={{ width: '20%' }} >
            <Select
              menuPlacement="auto"
              style={{ height: '32px' }}
              value={totalRecord}
              options={recordPerPageList}
            // onChange={this.handleChangeTotalRecords.bind(this)}
            />
          </FormControl>

        </Col>
        <Col md={6}>
          <Pagination
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            count={paginationLength}
            // onChange={this.handleChangePagination.bind(this)}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PhoneClinicGrid;
