import React, { Component } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button } from "@mui/material";
import { Col, Container, Row, Modal, Form } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
import axios from "axios";
import RefreshIcon from '@mui/icons-material/Refresh';
import { debounceFunc } from './../../helper'
import ReactToPrint from 'react-to-print';
import Bardcode from '../../Components/JobForm/Bardcode';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import PhoneClinicGrid from '../../Components/PhoneClinicGrid';

class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            InventoryList: [],
            showModal: false,
            showData: {
                "PC_SHP_INV_ID": "",
                "PC_SP_INV_SHID": "",
                "PC_SP_INV_PUR_ID": "",
                "ItemType": "",
                "ItemBrand": "",
                "ItemName": "",
                "ItemIMEI": "",
                "ItemDesc": "",
                "ItemRate": "",
                "ItemSellRate": "",
                "ItemStatus": ""
            },

            width: 1,
            height: 60,
            displayValue: true,
            text: "",
            fontOptions: "",
            font: "monospace",
            textAlign: "center",
            textPosition: "bottom",
            textMargin: 0,
            fontSize: 15,
            background: "#ffffff",
            lineColor: "#000000",
            margin: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,


            IMEI_SEARCH: ""

        }
    }

    componentDidMount() {
        let { IMEI_SEARCH, searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getInventory(IMEI_SEARCH, searchGridData, totalRecordsPerPage.value, paginationValue);
    }

    async getInventory(IMEI_SEARCH = "", searchGridData = "", totalRecordsPerPage = 20, recordOffset = 1) {

        let tempFormData = {
            ItemIMEI: IMEI_SEARCH
        }
        let whereCondition = ""
        let isSearchEnable = false

        for (const property in tempFormData) {
            if (tempFormData[property] && tempFormData[property] !== "") {
                isSearchEnable = true
                whereCondition += `${property} LIKE "%${tempFormData[property]}%" AND `
            }
        }


        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }

        let formData = {
            whereCondition,
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),
            PC_SP_ID: localStorage.getItem('PC_GRP_ATT'),
            searchGridData,
            totalRecordsPerPage,
            recordOffset
        };

        console.log('formData', formData)
        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/ShopModule/PCGetDeviceInventory.php`, formData);
        let serviceData = getAllInventory.data
        let InventoryList = []
        let paginationLength = 1

        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            paginationLength = parseInt(getAllInventory.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ InventoryList, paginationLength })
    }

    handleOpenModal(index) {
        let { InventoryList } = this.state;
        let showData = {}
        showData = InventoryList[index]
        this.setState({ showModal: true, showData })
    }

    handleChangeSearch = debounceFunc((label, evt) => {
        this.setState({ [label]: evt.target.value }, () => {
            let {
                IMEI_SEARCH
            } = this.state;
            this.handleSearch(IMEI_SEARCH)
        })
    })

    handleSearch = (
        IMEI_SEARCH = "",
    ) => {
        this.getInventory(IMEI_SEARCH)
    }


    render() {
        let { showData } = this.state
        return (
            <Container>
                <PhoneClinicGrid
                    title="DEVICE"
                    buttionLabel=""
                    onClickButton={() => { }}
                    handleChangeSearch={(key) => this.handleChangeSearch.bind(this, key)}
                    disableDelete
                    tableHeadData={[
                        {
                            label: "NAME",
                            isHidden: false
                        },
                        {
                            label: "TYPE",
                            isHidden: false
                        },
                        {
                            label: "BRAND",
                            isHidden: false
                        },
                        {
                            label: "DESCRPTION",
                            isHidden: false
                        },
                        {
                            label: "IMEI",
                            isHidden: false
                        },
                        {
                            label: "PURCHASE FROM",
                            isHidden: false
                        },
                        {
                            label: "PURCHASE TYPE",
                            isHidden: false
                        },
                        {
                            label: "BUY RATE",
                            isHidden: false
                        },
                        {
                            label: "SALE RATE",
                            isHidden: false
                        },
                        {
                            label: "STATUS",
                            isHidden: false
                        },
                    ]}
                    searchData={
                        [
                            {
                                key: "SHOP_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "SHOP_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "SHOP_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "SHOP_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "IMEI_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "SHOP_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "SHOP_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CASH_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CARD_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "OTHERS_SEARCH",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                >
                    {this.state.InventoryList.map((service, idx) => (
                        <TableRow
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {/* <TableCell align='center' >{service.INV_DATE}</TableCell> */}
                            <TableCell align='center' >{service.ItemName}</TableCell>
                            <TableCell align='center' >{service.ItemType}</TableCell>
                            <TableCell align='center' >{service.ItemBrand}</TableCell>
                            <TableCell align='center' >{service.ItemDesc}</TableCell>
                            <TableCell align='center' >{service.ItemIMEI}</TableCell>
                            <TableCell align='center' >{service.PUR_INV_TO}</TableCell>
                            <TableCell align='center' >{service.PUR_INV_TOTYPE}</TableCell>
                            <TableCell align='center' >{parseFloat(service.ItemRate).toFixed(2)}</TableCell>
                            <TableCell align='center' >{parseFloat(service.ItemSellRate).toFixed(2)}</TableCell>
                            <TableCell align='center' >{service.ItemStatus}</TableCell>
                            <TableCell align='center'>
                                <IconButton style={{ padding: "0px" }} aria-label="Edit Data" component="span" onClick={this.handleOpenModal.bind(this, idx)}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </PhoneClinicGrid>
                <Modal
                    show={this.state.showModal}
                    onHide={() => { this.setState({ showModal: false }) }}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Device Detail
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control
                                        name={"ItemType"}
                                        disabled
                                        style={{ padding: "0.25rem" }}
                                        defaultValue={showData['ItemType']}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control
                                        name={"ItemBrand"}
                                        disabled
                                        style={{ padding: "0.25rem" }}
                                        defaultValue={showData['ItemBrand']}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Item</Form.Label>
                                    <Form.Control
                                        name={"ItemName"}
                                        disabled
                                        style={{ padding: "0.25rem" }}
                                        defaultValue={showData['ItemName']}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Buy Rate</Form.Label>
                                    <Form.Control
                                        name={"ItemRate"}
                                        disabled
                                        defaultValue={showData["ItemRate"]}
                                        style={{ padding: "0.25rem" }}
                                        type={"number"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Sell Rate</Form.Label>
                                    <Form.Control
                                        name={"ItemSellRate"}
                                        disabled
                                        defaultValue={showData["ItemSellRate"]}
                                        style={{ padding: "0.25rem" }}
                                        type={"number"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        name={"ItemStatus"}
                                        disabled
                                        defaultValue={showData["ItemStatus"]}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>IMEI No / Barcode</Form.Label>
                                    <Form.Control
                                        name={"ItemIMEI"}
                                        disabled
                                        defaultValue={showData["ItemIMEI"]}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        name={"ItemDesc"}
                                        disabled
                                        defaultValue={showData["ItemDesc"]}
                                        style={{ padding: "0.25rem" }}
                                        type={"textarea"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Label>Barcode IMEI</Form.Label>
                                <div ref={el => (this.printLabel = el)} >
                                    <Bardcode
                                        value={showData["ItemIMEI"]}
                                        width={this.state.width}
                                        height={this.state.height}
                                        displayValue={this.state.displayValue}
                                        text={this.state.text}
                                        fontOptions={this.state.fontOptions}
                                        font={this.state.font}
                                        textAlign={this.state.textAlign}
                                        textPosition={this.state.textPosition}
                                        textMargin={this.state.textMargin}
                                        fontSize={this.state.fontSize}
                                        background={this.state.background}
                                        lineColor={this.state.lineColor}
                                        margin={this.state.margin}
                                        marginTop={this.state.marginTop}
                                        marginBottom={this.state.marginBottom}
                                        marginLeft={this.state.marginLeft}
                                        marginRight={this.state.marginRight}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactToPrint
                            trigger={() => { return <Button>Print IMEI</Button>; }}
                            content={() => this.printLabel}
                        />
                    </Modal.Footer>
                </Modal>
            </Container >
        );
    }
}

export default Devices;