import React, { Component } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Col, Container, Row, Form } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getISOWeek, formatDate, formatWeek, displayDate } from '../../../helper';
import ToggleButton from '@mui/material/ToggleButton';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import PercentIcon from '@mui/icons-material/Percent';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select from 'react-select'
import axios from "axios";
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
// import SaleInvoiceReport from "./../../../report/SaleInvoiceReport"
import ReactToPrint from 'react-to-print';
import SaleInvoiceReport from '../../../Report/SaleInvoiceReport';
import PhoneClinicGrid from '../../../Components/PhoneClinicGrid';

export default class SaleDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            addSale: false,
            SaleList: [],
            ItemList: [],

            WareHouseList: [{
                value: "F4",
                label: "F4"
            }],


            Customer: "",
            SALE_TO_TYPE: "Customer",
            Warehouse: {
                value: "F4",
                label: "F4"
            },
            SALE_DATE: formatDate(new Date().toDateString()),

            Item: {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            },

            AddedItemList: [],

            InventoryList: [],
            EMAIL_TO: "",
            isEdit: false,
            ShopList: [],
            SaleToShop: "",
            SaleToWarehouse: "",

            // new
            SALE_WEEK: formatWeek(),
            shopPaymentDays: [],
            OTHER_DATA: localStorage.getItem('OTHER_DATA'),
            isdis: false
        };
    }


    componentDidMount() {
        this.getShopSale()
        this.getInventory()
        this.getShopList()
    }

    async getShopList() {
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                ShopList.push({
                    value: shop.PC_SP_ID,
                    label: shop.SP_NAME
                })
            }
        }
        this.setState({ ShopList })
    }

    async getShopSale() {
        let formData = {
            PC_SP_ID: localStorage.getItem('PC_GRP_ATT') || 0
        };
        const getAllSale = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/ShopModule/PCGetGridShopSale.php`, formData);
        let SaleData = getAllSale.data
        let SaleList = []
        if (SaleData.success === 1) {
            SaleList = SaleData.data
        }
        this.setState({ SaleList })
    }

    async getInventory() {
        let formData = {
            PC_SP_ID: localStorage.getItem('PC_GRP_ATT') || 0,
            PC_ITEM_STATUS: "Available"
        };
        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getShopInventory.php`, formData);
        let serviceData = getAllInventory.data

        let ItemList = []
        let InventoryList = []
        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            for (let i = 0; i < InventoryList.length; i++) {
                const element = InventoryList[i];
                ItemList.push({
                    'ItemBrand': element.ItemBrand,
                    'ItemType': element.ItemType,
                    'display': element.ItemName,
                    'label': `${element.ItemName} (${element.ItemIMEI}) ${element.ItemDesc}`,
                    'value': element.ItemIMEI,
                    'id': element.ItemIMEI
                })
            }

        }
        this.setState({ InventoryList, ItemList })

    }

    async hanldeAddSale() {

        let a = formatWeek()
        let weekYearArr = a.split("-");
        weekYearArr[1] = weekYearArr[1].replace("W", "");
        let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
        let WeekOut = await getISOWeek(...MappedValue)
        let shopPaymentDays = []
        if (WeekOut.length > 0) {
            for (let i = 0; i < WeekOut.length; i++) {
                const WeekPerDay = WeekOut[i];
                shopPaymentDays.push({
                    'DateAndDay': WeekPerDay
                })
            }
        }

        this.setState({
            shopPaymentDays,
            addSale: true,
            isEdit: false
        })
        this.getInventory()
    }

    handleSearchgridData() {

    }

    handleUpdateSale(indx) {
        let { SaleList, ShopList } = this.state
        let SaleData = SaleList[indx]
        let shpDetail = ShopList.filter(x => x.value === SaleData.PC_SH_SALE_CUSTOMER)
        let shpValue = {}
        if (shpDetail.length > 0) {
            shpValue = shpDetail[0]
        }
        this.setState({
            isEdit: true,
            addSale: true,
            SALE_WEEK: SaleData.PC_SH_SALE_WEEK,
            Customer: SaleData.PC_SH_SALE_CUSTOMER,
            SaleToShop: shpValue,
            SALE_TO_TYPE: SaleData.PC_SH_SALE_TOTYPE,
            SALE_DATE: SaleData.PC_SH_SALE_DATE,
            AddedItemList: JSON.parse(SaleData.PC_SH_SALE_ITEM),
            shopPaymentDays: JSON.parse(SaleData.PC_SH_SALE_DAYS)
        })
    }

    handleChangeTotalRecords() {

    }

    handleChangePagination() {

    }

    handleChangeUpdatedItem(fieldName, evt, evtA) {
        let { Item, InventoryList } = this.state;
        Item['ItemQuantity'] = 1
        Item['ItemDisc'] = 0
        switch (fieldName) {
            case 'ItemName':
                let findInventory = InventoryList.filter(x => x.ItemIMEI === evt.value)?.[0]
                Item['ItemName'] = evt
                Item['ItemRate'] = parseInt(findInventory.ItemSellRate)
                Item['ItemAmount'] = parseInt(findInventory.ItemSellRate)
                Item['ItemIMEI'] = findInventory.ItemIMEI
                Item['ItemDesc'] = findInventory.ItemDesc
                break;

            case 'ItemDisType':
                Item['ItemDisType'] = evtA
                if (evtA === 'number') {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - Item['ItemDisc']
                } else {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - ((Item['ItemQuantity'] * Item['ItemRate']) * (Item['ItemDisc'] / 100))
                }
                break;
            case 'ItemQuantity':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = (evt.target.value * Item['ItemRate']) - Item['ItemDisc']
                } else {
                    Item['ItemAmount'] = (evt.target.value * Item['ItemRate']) - ((evt.target.value * Item['ItemRate']) * (Item['ItemDisc'] / 100))
                }
                break;
            case 'ItemRate':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * evt.target.value) - Item['ItemDisc']
                } else {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * evt.target.value) - ((Item['ItemQuantity'] * evt.target.value) * (Item['ItemDisc'] / 100))
                }
                break;

            case 'ItemDisc':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - evt.target.value
                } else {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - ((Item['ItemQuantity'] * Item['ItemRate']) * (evt.target.value / 100))
                }
                break;

            case 'ItemIMEI':
                // let findInventory = InventoryList.filter(x => x.PC_ITEM_BARCODE === evt.target.value || x.PC_ITEM_BARCODE === evt.target.value)?.[0]
                Item[evt.target.name] = evt.target.value
                break;

            default:
                Item[evt.target.name] = evt.target.value
                break;

        }
        this.setState({ Item })
    }


    handleValidateItem(Item, AddedItemList, ItemList) {
        if (Item.ItemName === '' || Item?.ItemName?.value === "") {
            Swal.fire(
                'Error',
                'Please select Item',
                'error'
            )
            return false
        }
        if (Item.ItemRate === '' || parseInt(Item.ItemRate) <= 0) {
            Swal.fire(
                'Error',
                'Rate must be greater than 0',
                'error'
            )
            return false
        }
        if (Item.ItemQuantity === '' || parseInt(Item.ItemQuantity) <= 0) {
            Swal.fire(
                'Error',
                'Quantity must be greater than 0',
                'error'
            )
            return false
        }

        for (let i = 0; i < AddedItemList.length; i++) {
            const ItemList = AddedItemList[i];
            if (ItemList.ItemIMEI === Item.ItemIMEI) {
                let RefreshItem = {
                    ItemName: "",
                    ItemIMEI: "",
                    ItemDesc: "",
                    ItemRate: 0,
                    ItemQuantity: 0,
                    ItemDisc: 0,
                    ItemAmount: 0,
                    ItemDisType: "number"
                }
                this.setState({ Item: RefreshItem })
                Swal.fire(
                    'Error',
                    'Device already exists on sale list',
                    'error'
                )
                return false
            }
        }

        let a = ItemList.filter(x => x.id === Item.ItemIMEI)
        if (a.length === 0) {
            let RefreshItem = {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            }
            this.setState({ Item: RefreshItem })
            Swal.fire(
                'Error',
                'No device found on inventory',
                'error'
            )
            return false
        }


        return true
    }

    handleAddItem() {
        let { Item, AddedItemList, ItemList } = this.state
        if (!this.handleValidateItem(Item, AddedItemList, ItemList)) return
        let UpdateItemList = [Item].concat(AddedItemList)
        this.setState({ AddedItemList: UpdateItemList })
        let RefreshItem = {
            ItemName: "",
            ItemIMEI: "",
            ItemDesc: "",
            ItemRate: 0,
            ItemQuantity: 0,
            ItemDisc: 0,
            ItemAmount: 0,
            ItemDisType: "number"
        }
        this.setState({ Item: RefreshItem })

    }

    async handleChange(fieldName, evt) {
        let value
        switch (fieldName) {
            case 'SALE_WEEK':
                value = evt.target.value
                let WeekNumber = evt.target.value;
                let weekYearArr = WeekNumber.split("-");
                weekYearArr[1] = weekYearArr[1].replace("W", "");
                let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                let WeekOut = await getISOWeek(...MappedValue)
                let shopPaymentDays = []
                if (WeekOut.length > 0) {
                    for (let i = 0; i < WeekOut.length; i++) {
                        const WeekPerDay = WeekOut[i];
                        shopPaymentDays.push({
                            'DateAndDay': WeekPerDay
                        })
                    }
                }
                this.setState({ shopPaymentDays })

                break;
            case 'Warehouse':
                value = evt
                break;
            case 'SaleToShop':
            case 'SaleToWarehouse':
                // fieldName = "Customer"
                value = evt
                break;
            default:
                value = evt.target.value
                break;
        }
        this.setState({
            [fieldName]: value
        })
    }

    ValidateInvoice() {
        let { SALE_TO_TYPE } = this.state;

        if (SALE_TO_TYPE === "Customer" && this.state.Customer === "") {
            Swal.fire(
                'Error',
                'Please Enter Customer',
                'error'
            )
            return false
        }

        if (SALE_TO_TYPE === "Shop" && this.state.SaleToShop === "") {
            Swal.fire(
                'Error',
                'Please select Shop',
                'error'
            )
            return false
        }

        if (this.state.AddedItemList.length === 0) {
            Swal.fire(
                'Error',
                'Please Add atleast 1 Item',
                'error'
            )
            return false
        }
        return true
    }

    async handleSaveInvoice() {
        this.setState({ isdis: true })
        if (!this.ValidateInvoice()) return
        let { SALE_TO_TYPE } = this.state
        let formData = {
            PC_SH_SALE_ID: "",
            PC_SH_SALE_SHID: localStorage.getItem('PC_GRP_ATT'),
            PC_SH_SALE_DAYS: JSON.stringify(this.state.shopPaymentDays),
            PC_SH_SALE_WEEK: this.state.SALE_WEEK,
            PC_SH_SALE_CUSTOMER: SALE_TO_TYPE === 'Customer' ? this.state.Customer : this.state.SaleToShop?.value,
            PC_SH_SALE_TOTYPE: SALE_TO_TYPE,
            PC_SH_SALE_DATE: this.state.SALE_DATE,
            PC_SH_SALE_ITEM: JSON.stringify(this.state.AddedItemList),
            PC_SH_SALE_NETAMT: this.renderTotalAmount()
        }

        try {
            let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/ShopModule/PCAddShopSale.php`, formData);
            if (getSaleInvoiceResult.data.status === 200) {
                Swal.fire(
                    'Success',
                    getSaleInvoiceResult.data.message,
                    'success'
                )
            } else {
                Swal.fire(
                    'Error',
                    getSaleInvoiceResult.data.message,
                    'error'
                )
            }
            this.setState({ isdis: false })
            this.handleExit()
        } catch (error) {
            this.setState({ isdis: false })
            console.error('error :>> ', error);
        }
    }

    handleValidate() {
        if (this.state.EMAIL_TO === "") {
            Swal.fire(
                'Error',
                'Please Enter Email Address',
                'error'
            )
            return false
        }
        return true
    }

    async handleSendEmail() {

        if (!this.handleValidate()) return

        let formData = {
            SALE_BY: localStorage.getItem('PC_USR_NAME'),
            SALE_FROM: localStorage.getItem('PC_GRP_ATT'),
            SALE_CUSTOMER: this.state.Customer,
            SALE_WAREHOUSE: this.state.Warehouse.value,
            SALE_DATE: this.state.SALE_DATE,
            SALE_ITEM: JSON.stringify(this.state.AddedItemList),
            EMAIL_TO: this.state.EMAIL_TO
        }
        let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}mail/SendMail.php`, formData);
        if (getSaleInvoiceResult.data.status === 201) {
            Swal.fire(
                'Success',
                'Email has been send!!',
                'success'
            )
        }
    }

    handleExit() {
        this.setState({
            addSale: false,
            ItemList: [],

            WareHouseList: [{
                value: "F4",
                label: "F4"
            }],

            Customer: "",
            Warehouse: {},
            SALE_DATE: formatDate(new Date().toDateString()),

            Item: {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            },

            AddedItemList: [],
            isEdit: false,
            SaleToShop: ""
        })
        this.getShopSale()
    }

    renderTotalAmount() {
        let { AddedItemList } = this.state;
        let Price = AddedItemList.map(({ ItemAmount }) => ItemAmount).reduce((sum, i) => sum + i, 0);
        return Price
    }

    handleKeyPress(evnt) {
        if (evnt.key === 'Enter') {
            this.handleAddItem()
        }
    }

    handleUndoTrans(evt) {
        evt.preventDefault();
        let formData = {
            SALE_BY: localStorage.getItem('PC_USR_NAME'),
            SALE_FROM: localStorage.getItem('PC_GRP_ATT'),
            SALE_CUSTOMER: this.state.Customer,
            SALE_WAREHOUSE: this.state.Warehouse.value,
            SALE_DATE: this.state.SALE_DATE,
            SALE_ITEM: JSON.stringify(this.state.AddedItemList),
            EMAIL_TO: this.state.EMAIL_TO
        }
    }

    handleEnterImei(evnt) {
        if (evnt.key === 'Enter') {
            let { Item, InventoryList } = this.state;
            let FilterItem = InventoryList.filter(x => x.ItemIMEI === Item.ItemIMEI)
            if (FilterItem.length > 0) {
                Item['ItemName'] = {
                    'display': FilterItem[0].ItemName,
                    'label': `${FilterItem[0].ItemName} (${FilterItem[0].ItemIMEI})`,
                    'value': FilterItem[0].ItemIMEI,
                    'id': FilterItem[0].ItemIMEI,
                }
                Item['ItemDesc'] = FilterItem[0].ItemDesc
                Item['ItemRate'] = FilterItem[0].ItemSellRate
                Item['ItemQuantity'] = 1
                Item['ItemDisc'] = 0
                Item['ItemAmount'] = (1 * FilterItem[0].ItemSellRate) - ((1 * FilterItem[0].ItemSellRate) * (0 / 100))
            }
            this.setState({ Item }, () => { this.handleAddItem() })
        }
    }

    handleRemove(index) {
        let { AddedItemList } = this.state;
        AddedItemList.splice(index, 1)
        this.setState({ AddedItemList })
    }

    render() {
        const { addSale } = this.state
        let { Item, shopPaymentDays, ShopList } = this.state;
        return (
            <Container>
                {addSale === false ?
                    <PhoneClinicGrid
                        title="DEVICE SALE"
                        buttionLabel="Add sale"
                        onClickButton={this.hanldeAddSale.bind(this)}
                        handleChangeSearch={(key) => { }}
                        tableHeadData={[
                            {
                                label: "DATE",
                                isHidden: false
                            },
                            {
                                label: "TYPE",
                                isHidden: false
                            },
                            {
                                label: "SALE TO",
                                isHidden: false
                            },
                            {
                                label: "TOTAL AMOUNT",
                                isHidden: false
                            },
                        ]
                        }
                        searchData={
                            [
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                },
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                },
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                },
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                }
                            ]}
                    >
                        {this.state.SaleList.map((service, idx) => {
                            let shpDetail = ShopList.filter(x => x.value === service.PC_SH_SALE_CUSTOMER)
                            let WHS_Cust = service.PC_SH_SALE_CUSTOMER
                            if (shpDetail.length > 0) {
                                WHS_Cust = shpDetail[0].label
                            }
                            return (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <TableCell align='center' >{service.PC_SH_SALE_DATE}</TableCell>
                                    <TableCell align='center' >{service.PC_SH_SALE_TOTYPE}</TableCell>
                                    <TableCell align='center' >{WHS_Cust}</TableCell>
                                    <TableCell align='center' >{service.PC_SH_SALE_NETAMT}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ padding: "0px" }} aria-label="Edit Data" component="span" onClick={this.handleUpdateSale.bind(this, idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={() => { }} >
                                            <RemoveIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            )
                        })}
                    </PhoneClinicGrid>
                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Sale By Shop</legend>
                        </Row>
                        <Row style={{ margin: "5px" }} >
                            <Col md={4} style={{ alignSelf: "end" }}  >
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                                        <span>Week</span>
                                        <span>
                                            {shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}
                                        </span>
                                    </Form.Label>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"week"}
                                        disabled={this.state.isEdit === true && this.state.addSale === true ? true : false}
                                        value={this.state.SALE_WEEK}
                                        onChange={this.handleChange.bind(this, "SALE_WEEK")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                                        <div>
                                            {this.state.SALE_TO_TYPE}
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                        </div>
                                        <div>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.state.SALE_TO_TYPE}
                                                exclusive
                                                disabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                size="small"
                                                name="SALE_TO_TYPE"
                                                onChange={this.handleChange.bind(this, 'SALE_TO_TYPE')}
                                                aria-label="small button group"
                                            >
                                                <ToggleButton value="Customer">Customer</ToggleButton>
                                                <ToggleButton value="Shop">Shop</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </Form.Label>
                                    {
                                        this.state.SALE_TO_TYPE === "Customer" ?
                                            <Form.Control
                                                disabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                style={{ padding: "0.25rem" }}
                                                value={this.state.Customer}
                                                onChange={this.handleChange.bind(this, "Customer")}
                                            />
                                            :
                                            <Select
                                                menuPlacement="auto"
                                                style={{ height: '32px' }}
                                                isDisabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                value={this.state.SaleToShop}
                                                options={this.state.ShopList}
                                                onChange={this.handleChange.bind(this, "SaleToShop")}
                                            />
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={3} style={{ alignSelf: "end" }}  >
                                <Form.Group className="mb-3" >
                                    <Form.Label>Date</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        name="SALE_DATE"
                                        style={{ padding: "0.25rem" }}
                                        type="date"
                                        disabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                        min={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[0]['DateAndDay']) : ''}`}
                                        max={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[6]['DateAndDay']) : ''}`}
                                        value={this.state.SALE_DATE}
                                        onChange={this.handleChange.bind(this, 'SALE_DATE')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.isEdit === false ?
                            <Row style={{ margin: "5px" }} >
                                <Col md={11} >
                                    <Row>
                                        <Col md={5}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Item</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Select
                                                    name={"ItemName"}
                                                    menuPlacement="auto"
                                                    style={{ height: '32px' }}
                                                    value={Item['ItemName']}
                                                    options={this.state.ItemList}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemName')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Rate</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemRate"}
                                                    value={Item["ItemRate"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemRate')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={1}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Qty.</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemQuantity"}
                                                    value={Item["ItemQuantity"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemQuantity')}
                                                />
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={2}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Dis.</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemDisc"}
                                                    value={Item["ItemDisc"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDisc')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label>Discount Type</Form.Label>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={Item["ItemDisType"]}
                                                exclusive
                                                size="small"
                                                name="ItemDisType"
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDisType')}
                                                aria-label="Small Sizes"
                                            >
                                                <ToggleButton value="number"><CurrencyPoundIcon /></ToggleButton>
                                                <ToggleButton value="percent"><PercentIcon /></ToggleButton>
                                            </ToggleButtonGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Amount</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemAmount"}
                                                    value={Item["ItemAmount"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    disabled
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemAmount')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>IMEI No / Barcode</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemIMEI"}
                                                    value={Item["ItemIMEI"]}
                                                    style={{ padding: "0.25rem" }}
                                                    onKeyPress={this.handleEnterImei.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemIMEI')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Description</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemDesc"}
                                                    value={Item["ItemDesc"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"textarea"}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDesc')}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col md={1} style={{ alignSelf: 'center' }} >
                                    <Button style={{ background: 'green', color: 'white' }} onClick={this.handleAddItem.bind(this)} >Add</Button>
                                </Col>
                            </Row>
                            :
                            ""
                        }
                        <Row style={{
                            height: "30vh",
                            overflow: "auto"
                        }}
                        >
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '25vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Name</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >IMEI/Barcode</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Rate</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Discount</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                {!this.state.isEdit ?
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                        <IconButton aria-label="Edit Data" component="span">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    :
                                                    ""
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.AddedItemList.map((ItemDetails, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell >{ItemDetails.ItemName?.display}</TableCell>
                                                    <TableCell >{ItemDetails.ItemIMEI}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemRate}</TableCell>
                                                    <TableCell >{ItemDetails.ItemDisc === "0" || ItemDetails.ItemDisc === 0 ? "" : `${ItemDetails.ItemDisType === 'number' ? '£' : '%'} ${ItemDetails.ItemDisc}`}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemAmount}</TableCell>
                                                    {!this.state.isEdit ?
                                                        <TableCell >
                                                            <IconButton aria-label="Edit Data" component="span" onClick={this.handleRemove.bind(this, idx)} >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        : ""
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TableContainer> */}
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {/* <TableCell colSpan={3} /> */}
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} rowSpan={4} colSpan={3}>Net Sale</TableCell>
                                            <TableCell>£ {this.renderTotalAmount()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                            </Paper>
                        </Row>
                        <Row>
                            <SaleInvoiceReport ref={el => (this.printThermalSale = el)}
                                data={{
                                    ItemList: this.state.AddedItemList,
                                    SaleDate: displayDate(this.state.SALE_DATE),
                                    SaleTo: this.state.SALE_TO_TYPE === 'Customer' ? this.state.Customer : this.state.SaleToShop?.value,
                                    TotalAmount: this.renderTotalAmount(),
                                    OTHER_DATA: this.state.OTHER_DATA
                                }}
                            />
                            <Col md={4}>
                                <Button onClick={this.handleExit.bind(this)} >Exit</Button>
                                {!this.state.isEdit ?
                                    <Button className="add-button" size="small" variant="contained" disabled={this.state.isdis} onClick={this.handleSaveInvoice.bind(this)} >Save</Button>
                                    :
                                    ""
                                }
                            </Col>
                            {this.state.isEdit ?
                                <Col md={8} style={{ textAlign: 'end' }} >
                                    <div style={{ display: "flex", justifyContent: "space-between" }} >
                                        {/* <Button onClick={this.handleUndoTrans.bind(this)} >Undo Transaction</Button> */}
                                        <Form.Control
                                            style={{ padding: "0.25rem", width: "50%" }}
                                            value={this.state.EMAIL_TO}
                                            placeholder="Enter Email"
                                            onChange={this.handleChange.bind(this, "EMAIL_TO")}
                                        />
                                        <div>
                                            <Button onClick={this.handleSendEmail.bind(this)} >Send Email</Button>
                                            <ReactToPrint
                                                trigger={() => { return <Button style={{ background: '#ff7f19', color: "white" }}  >Print</Button> }}
                                                content={() => this.printThermalSale}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                :
                                ""
                            }
                        </Row>
                    </div>
                }
            </Container >
        );
    }
}
