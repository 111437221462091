import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import axios from "axios";
// import Box from '@mui/material/Box';

import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import MenuItem from '@mui/material/MenuItem';
// import Checkbox from '@mui/material/Checkbox';
// import RemoveIcon from '@mui/icons-material/Delete';
import { debounceFunc } from './../../helper'
import Select from 'react-select'
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import PhoneClinicGrid from "../../Components/PhoneClinicGrid";

class CommonWarehouse extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            WarehouseList: [],

            addWarehouse: false,
            WAREHOUSE_ID: "",
            WAREHOUSE_NAME: "",
            WAREHOUSE_EMAIL: "",
            WAREHOUSE_PASS: "",
            WAREHOUSE_ADDRESS: "",
            WAREHOUSE_ACTIVE: true,
        }
    }

    async componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        await this.getDeviceList(searchGridData, totalRecordsPerPage.value, paginationValue);
    }

    async getDeviceList(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetWarehouse.php`, { searchGridData, totalRecordsPerPage, recordOffset });
        let deviceData = getDevice.data
        let WarehouseList = []
        let paginationLength = 1
        if (deviceData.success === 1) {
            WarehouseList = deviceData.data;
            paginationLength = parseInt(deviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ WarehouseList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddPayAccount() {
        this.setState({ addWarehouse: true })
    }

    handleChangeInput(fieldName, event, nxtEvnt) {
        let targetValue
        switch (fieldName) {
            case "WAREHOUSE_ACTIVE":
                targetValue = nxtEvnt
                break;
            default:
                targetValue = event.target.value
                break;
        }
        this.setState({ [fieldName]: targetValue })
    }

    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getDeviceList(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getDeviceList(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getDeviceList(searchGridData, totalRecordsPerPage.value, value)
    }


    handleCancelPayAccount() {
        this.setState({
            addWarehouse: false,
            WAREHOUSE_ID: "",
            WAREHOUSE_NAME: "",
            WAREHOUSE_EMAIL: "",
            WAREHOUSE_PASS: "",
            WAREHOUSE_ADDRESS: "",
            WAREHOUSE_ACTIVE: true
        })
    }

    async handleSubmitPayAccount() {
        this.props.toggleLoader(true)
        let formData = {
            WAREHOUSE_ID: this.state.WAREHOUSE_ID,
            WAREHOUSE_NAME: this.state.WAREHOUSE_NAME,
            WAREHOUSE_EMAIL: "",
            WAREHOUSE_PASS: "",
            WAREHOUSE_ADDRESS: this.state.WAREHOUSE_ADDRESS,
            WAREHOUSE_ACTIVE: this.state.WAREHOUSE_ACTIVE === true ? "1" : "0"
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddWarehouse.php`, formData);

        this.handleCancelPayAccount()
        this.getDeviceList("", 20, 1)
    }


    handleEdit(indx) {
        let { WarehouseList } = this.state;
        this.setState({
            addWarehouse: true,
            WAREHOUSE_ID: WarehouseList[indx]['WAREHOUSE_ID'],
            WAREHOUSE_NAME: WarehouseList[indx]['WAREHOUSE_NAME'],
            WAREHOUSE_EMAIL: WarehouseList[indx]['WAREHOUSE_EMAIL'],
            WAREHOUSE_PASS: WarehouseList[indx]['WAREHOUSE_PASS'],
            WAREHOUSE_ADDRESS: WarehouseList[indx]['WAREHOUSE_ADDRESS'],
            WAREHOUSE_ACTIVE: WarehouseList[indx]['WAREHOUSE_ACTIVE'] === "1" ? true : false
        })
    }


    handleDelete(ACCOUNT_ID) {
        Swal.fire({
            title: 'Do you want to delete warehouse ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.props.toggleLoader(true)
                const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/delete/setup/DeleteWarehouse.php`, { ACCOUNT_ID });
                let shopData = getShops.data
                if (shopData.success === 1 && shopData.message === "You have successfully delete track.") {
                    Swal.fire('Warehouse Deleted!', '', 'success')
                    let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
                    this.getDeviceList(searchGridData, totalRecordsPerPage.value, paginationValue)
                }
                this.props.toggleLoader(false)
            }
        })
    }

    render() {
        return (
            <Container>
                {this.state.addWarehouse === false ?
                    <PhoneClinicGrid
                        title="WAREHOUSE"
                        buttionLabel="Add Warehouse"
                        onClickButton={this.handleAddPayAccount.bind(this)}
                        handleChangeSearch={(key) => { }}
                        tableHeadData={[
                            {
                                label: "NAME",
                                isHidden: false
                            },
                            {
                                label: "ADDRESS",
                                isHidden: false
                            },
                            {
                                label: "ACTIVE",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                    >
                        {this.state.WarehouseList.map((device, idx) => (
                            <TableRow
                                className="table-rows"
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align='center' >{device.WAREHOUSE_NAME}</TableCell>
                                <TableCell align='center' >{device.WAREHOUSE_ADDRESS}</TableCell>
                                <TableCell align='center' >
                                    <Checkbox style={{ padding: "0px" }} size="small" checked={device.WAREHOUSE_ACTIVE === "1" ? true : false} disabled />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} onClick={this.handleEdit.bind(this, idx)} aria-label="Edit Data" component="span">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.handleDelete.bind(this, device.WAREHOUSE_ID)} >
                                        <RemoveIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </PhoneClinicGrid>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Add New Warehouse</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Warehouse</Form.Label>
                                    <Form.Control
                                        value={this.state.WAREHOUSE_NAME}
                                        name={"WAREHOUSE_NAME"}
                                        onChange={this.handleChangeInput.bind(this, "WAREHOUSE_NAME")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        value={this.state.WAREHOUSE_ADDRESS}
                                        name={"WAREHOUSE_ADDRESS"}
                                        onChange={this.handleChangeInput.bind(this, "WAREHOUSE_ADDRESS")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Active</Form.Label>
                                    <FormGroup>
                                        <Checkbox size="small" checked={this.state.WAREHOUSE_ACTIVE} onChange={this.handleChangeInput.bind(this, "WAREHOUSE_ACTIVE")} />
                                    </FormGroup>
                                </Form.Group>
                            </Col>


                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelPayAccount.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitPayAccount.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default CommonWarehouse;