import React from "react"
import { Container, Row, Col, Form, Modal } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import axios from "axios";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
// import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { debounceFunc } from './../../helper'
import Select from 'react-select'
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import { InputGroup, Image } from 'react-bootstrap';

import NoRecordFound from '../../assets/images/norecordfound.png';


class CommonServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            addService: false,
            ServicesList: [],
            UpdatedServiceList: [],


            showModal: false,
            SERVICE_ID: "",
            SERVICE_NAME: ""

        };
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getAllServices(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getAllServices(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        let formData = {
            searchGridData, totalRecordsPerPage, recordOffset,
            PCAS_SERVICE_STATUS: 'Completed'
        };

        const getAllService = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllService.php`, formData);
        let serviceData = getAllService.data
        let UpdatedServiceList = []
        let paginationLength = 1
        if (serviceData.success === 1) {
            UpdatedServiceList = serviceData.data
            paginationLength = parseInt(serviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ UpdatedServiceList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getAllServices(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getAllServices(MyEvt.target.value, 20, 1)
    })

    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getAllServices(searchGridData, totalRecordsPerPage.value, value)
    }


    handleAddService() {
        let { ServicesList } = this.state;
        ServicesList.push({
            SERVICE_NAME: ""
        })
        this.setState({ ServicesList })
    }

    async handleChangeInput(indx, fieldName, evt) {
        let { ServicesList } = this.state;
        ServicesList[indx][fieldName] = evt.target.value
        this.setState({ ServicesList })
    }

    hanldeEditService() {
        this.setState({ addService: true, ServicesList: [] })
    }

    async handleSubmitService() {
        this.props.toggleLoader(true)
        let { ServicesList } = this.state;
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddService.php`, JSON.stringify(ServicesList));
        this.getAllServices("", 20, 1)
        this.handleCancelService()
    }

    handleCancelService() {
        this.setState({
            addService: false,
            ServicesList: [],
            showModal: false,
            SERVICE_ID: "",
            SERVICE_NAME: ""
        })
    }

    handleRemoveService(indx) {
        let { ServicesList } = this.state;
        ServicesList.splice(indx, 1)
        this.setState({ ServicesList })
    }

    handleUpdateService(indx) {
        let { UpdatedServiceList } = this.state
        this.setState({
            showModal: true,
            SERVICE_ID: UpdatedServiceList[indx]['PCAS_ID'],
            SERVICE_NAME: UpdatedServiceList[indx]['PCAS_SERVICE_NAME']
        })
    }

    handleChange(fieldName, evnt) {
        this.setState({
            [fieldName]: evnt.target.value
        })
    }

    async handleUpdateServiceMutate() {
        let formData = {
            SERVICE_ID: this.state.SERVICE_ID,
            SERVICE_NAME: this.state.SERVICE_NAME
        }
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCUpdateService.php`, formData);
        this.setState({ showModal: false })
        this.getAllServices("", 20, 1)
    }

    render() {
        let { addService } = this.state
        return (
            <Container>
                <div style={{ marginBottom: "1rem" }} >
                    <Row style={{ margin: "5px" }} >
                        <Col md={10} style={{ textAlign: "start" }} >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}
                            >
                                <div style={{ whiteSpace: "nowrap", marginRight: "10px" }} ><strong>{"SERVICE"}</strong> SEARCH</div>
                                <div style={{ height: "3px", width: "100%", background: "#ff1a00" }} >
                                </div>
                            </div>
                        </Col>

                        <Col md={2} style={{ textAlign: "end" }} >
                            <Button className="add-button" size="small" variant="contained" onClick={addService === true ? this.handleSubmitService.bind(this) : this.hanldeEditService.bind(this)} >{addService === true ? "Update Service" : "Edit Service"}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <TableContainer component={Paper} style={{ padding: 0 }} >
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell align='center' style={{ background: '#f1f1f1', color: '#111827' }} >{"SERVICE"}</TableCell>
                                        <TableCell align='center' style={{ background: '#f1f1f1', color: '#111827' }} >{addService ? "ADD" : "EDIT"}</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        className="table-rows"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell align='center' >
                                            <InputGroup size="sm">
                                                <Form.Control
                                                    onChange={() => { }}
                                                    disabled={true}
                                                    aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                />
                                            </InputGroup>
                                        </TableCell>

                                        <TableCell align='center' >
                                            {addService && <Button endIcon={"+"} onClick={this.handleAddService.bind(this)} ></Button>}
                                        </TableCell>
                                    </TableRow>
                                    {addService === false ?
                                        this.state.UpdatedServiceList.map((ServiceList, indx) => (
                                            <TableRow
                                                key={indx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align='center'>{ServiceList.PCAS_SERVICE_NAME}</TableCell>
                                                <TableCell align='center'>
                                                    <IconButton style={{ padding: "0px" }} onClick={this.handleUpdateService.bind(this, indx)} aria-label="Edit Data" component="span">
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        this.state.ServicesList.map((service, idx) => (
                                            <TableRow
                                                className="table-rows"
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Form.Control
                                                        value={service.SERVICE_NAME}
                                                        onChange={this.handleChangeInput.bind(this, idx, "SERVICE_NAME")}
                                                        style={{ padding: "0.25rem", width: "100%" }}
                                                    />
                                                </TableCell>
                                                <TableCell ><Button endIcon={"-"} onClick={this.handleRemoveService.bind(this, idx)} ></Button></TableCell>
                                            </TableRow>
                                        ))
                                    }


                                </TableBody>
                            </Table>
                            {this.state.UpdatedServiceList && this.state.UpdatedServiceList.length === 0 &&
                                <div style={{ textAlign: 'center', padding: '5rem' }} >
                                    <Image src={NoRecordFound} alt="No record found !!" />
                                    <h3>No Record Found !!! </h3>
                                </div>
                            }
                        </TableContainer>
                    </Row>
                </div>
                {addService && <Button onClick={this.handleCancelService.bind(this)} >Cancel</Button>}

                <Modal
                    show={this.state.showModal}
                    centered
                >
                    {/* <Modal.Header>

                    </Modal.Header> */}
                    <Modal.Body>
                        <label>Service</label>
                        <Form.Control
                            value={this.state.SERVICE_NAME}
                            onChange={this.handleChange.bind(this, "SERVICE_NAME")}
                            style={{ padding: "0.25rem", width: "100%" }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCancelService.bind(this)}>Cancel</Button>
                        <Button onClick={this.handleUpdateServiceMutate.bind(this)} >Update</Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        );
    }
}

export default CommonServices;