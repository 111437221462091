import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, TableCell, TableRow, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import PhoneClinicGrid from "../../../Components/PhoneClinicGrid";
import axios from "axios";
import Checkbox from '@mui/material/Checkbox';
// import RemoveIcon from '@mui/icons-material/Delete';
import { debounceFunc } from './../../../helper'

class BrandSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            BrandList: [],
            addBrand: false,
            Brand_ID: "",
            Brand_Name: "",
            Active: "1",

            BRAND_SEARCH: ""
        }
    }

    componentDidMount() {
        let { BRAND_SEARCH, searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getBrandList(BRAND_SEARCH, searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    // Brand_Name
    async getBrandList(BRAND_SEARCH = "", searchGridData = "", totalRecordsPerPage = 20, recordOffset = 1) {

        this.props.toggleLoader(true)
        let tempFormData = {
            Brand_Name: BRAND_SEARCH
        }
        let whereCondition = ""
        let isSearchEnable = false

        for (const property in tempFormData) {
            if (tempFormData[property] && tempFormData[property] !== "") {
                isSearchEnable = true
                whereCondition += `${property} LIKE "%${tempFormData[property]}%" AND `
            }
        }

        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }

        let formData = {
            whereCondition,
            totalRecordsPerPage,
            recordOffset,
        };

        console.log('formData', formData)

        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Setups/ItemBrand.php`, formData);
        let deviceData = getDevice.data
        let BrandList = []
        let paginationLength = 1
        if (deviceData.success === 1) {
            BrandList = deviceData.data
            paginationLength = parseInt(deviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ BrandList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddBrand() {
        this.setState({ addBrand: true })
    }

    handleChangeInput(fieldName, event) {
        let targetValue = event.target.value
        this.setState({ [fieldName]: targetValue })
    }


    handleExit() {
        this.setState({
            addBrand: false,
            Brand_ID: "",
            Brand_Name: "",
            Active: "1",
        })
    }

    async handleSubmitDevice() {
        this.props.toggleLoader(true)
        let formData = {
            Brand_ID: this.state.Brand_ID,
            Brand_Name: this.state.Brand_Name,
            Active: this.state.Active,
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Setups/ItemBrand.php`, formData);

        this.handleExit()
        this.getBrandList("", "", 20, 1)
    }


    handleEdit(indx) {
        let { BrandList } = this.state
        this.setState({
            Brand_ID: BrandList[indx]['Brand_ID'],
            Brand_Name: BrandList[indx]['Brand_Name'],
            Active: BrandList[indx]['Active'],
            addBrand: true
        })
    }

    handleChangeSearch = debounceFunc((label, evt) => {
        this.setState({ [label]: evt.target.value }, () => {
            let {
                BRAND_SEARCH
            } = this.state;
            this.handleSearch(BRAND_SEARCH)
        })
    })

    handleSearch = (
        BRAND_SEARCH = "",
    ) => {
        this.getBrandList(BRAND_SEARCH)
    }

    render() {
        return (
            <Container>
                {this.state.addBrand === false ?
                    <PhoneClinicGrid
                        title="BRAND"
                        buttionLabel="Add Brand"
                        onClickButton={this.handleAddBrand.bind(this)}
                        handleChangeSearch={(key) => this.handleChangeSearch.bind(this, key)}
                        tableHeadData={[
                            {
                                label: "BRAND",
                                isHidden: false
                            },
                            {
                                label: "ACTIVE",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "BRAND_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "ACTIVE",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                    >
                        {this.state.BrandList.map((device, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ whiteSpace: 'nowrap' }}
                            >

                                <TableCell align='center'>{device.Brand_Name}</TableCell>
                                <TableCell align='center'>
                                    <Checkbox style={{ padding: "0px" }} size="small" checked={true} disabled />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} onClick={this.handleEdit.bind(this, idx)} aria-label="Edit Data" component="span">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={() => { }} >
                                        <RemoveIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </PhoneClinicGrid>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Add Brand</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        value={this.state.Brand_Name}
                                        name={"Brand_Name"}
                                        onChange={this.handleChangeInput.bind(this, "Brand_Name")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_BRAND}
                                        name={"DEVICE_BRAND"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_BRAND")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_NAME}
                                        name={"DEVICE_NAME"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_NAME")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleExit.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitDevice.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default BrandSetup;