import React, { Component } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import { Col, Container, Row, Form, Modal } from 'react-bootstrap';
import axios from "axios";
import Select from 'react-select'
import Swal from "sweetalert2";
import { getISOWeek, debounceFunc } from '../../../helper';
import PhoneClinicGrid from '../../../Components/PhoneClinicGrid';

export default class WeeklySale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            addWeekSale: false,
            isEdit: false,

            WeekSaleList: [],
            ShopList: [],
            WS_ID: "",
            WeeklyShop: "",
            SaleWeek: "",
            shopDeliveryAmount: "",
            WeekDays: [],
            AllShopData: [],
            ExtraShopExpenses: [],
            PC_WS_STATUS: "PENDING",
            showModal: false,
            showDelModal: false,
            payAccDetails: [],
            DeliveryDetails: [],

            PC_USR_ROLE_ID: localStorage.getItem('PC_USR_ROLE_ID'),
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),

            // PC_USR_ROLE: localStorage.getItem('PC_USR_ROLE'),
            PC_SHOP_ID: localStorage.getItem('PC_GRP_ATT'),

            isShopDisable: false,

        };
    }


    componentDidMount() {
        let {
            WEEK_SEARCH,
            SHOP_SEARCH,
            CASH_SEARCH,
            CARD_SEARCH,
            OTHERS_SEARCH,
            CB_SEARCH,
            STATUS_SEARCH
        } = this.state
        this.getWeeklySale(
            WEEK_SEARCH,
            SHOP_SEARCH,
            CASH_SEARCH,
            CARD_SEARCH,
            OTHERS_SEARCH,
            CB_SEARCH,
            STATUS_SEARCH,
        );
    }

    async getWeeklySale(
        WEEK_SEARCH = "",
        SHOP_SEARCH = "",
        CASH_SEARCH = "",
        CARD_SEARCH = "",
        OTHERS_SEARCH = "",
        CB_SEARCH = "",
        STATUS_SEARCH = ""
    ) {
        this.props.toggleLoader(true)
        let formData = {
            PC_WS_WEEK: WEEK_SEARCH,
            SP_NAME: SHOP_SEARCH,
            WS_Cash: CASH_SEARCH,
            WS_Card: CARD_SEARCH,
            WS_Other: OTHERS_SEARCH,
            PC_WS_BY: CB_SEARCH,
            PC_WS_STATUS: STATUS_SEARCH
        }


        let whereCondition = ""
        let isSearchEnable = false

        for (const property in formData) {
            if (formData[property] && formData[property] !== "") {
                isSearchEnable = true
                whereCondition += `${property} LIKE "%${formData[property]}%" AND `
            }
        }

        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }

        const getAllSale = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/PCGetAllWeeklySale.php`, {
            whereCondition,
            PC_GRP_ID: this.state.PC_GRP_ID,
            PC_SHOP_ID: this.state.PC_SHOP_ID
        });
        let WeeklySaleData = getAllSale.data
        let WeekSaleList = []
        if (WeeklySaleData.success === 1) {
            WeekSaleList = WeeklySaleData.data
        }
        this.setState({ WeekSaleList })
        this.props.toggleLoader(false)
    }


    async hanldeAddWeekSale() {
        let result = await this.getShopList();
        let isShopDisable = false
        let WeeklyShop = "",
            SaleWeek = "",
            WeekDays = []
        if (result && result.data) {
            const weekResult = await this.getWeekNumberFormatted()
            WeekDays = weekResult.WeekDays
            SaleWeek = weekResult.WeekNumber
            let filterResult = result.data.filter(x => x.PC_SP_ID === this.state.PC_SHOP_ID)
            if (filterResult && filterResult.length > 0) {
                isShopDisable = true
                WeeklyShop = {
                    value: filterResult[0].PC_SP_ID,
                    label: filterResult[0].SP_NAME,
                    type: "shop"
                }
            }
        }
        this.setState({ addWeekSale: true, isShopDisable, WeeklyShop, SaleWeek, WeekDays })
    }

    async getShopList() {
        this.props.toggleLoader(true)
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let result = {}
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                if (this.state.PC_SHOP_ID !== shop.PC_SP_ID) {
                    ShopList.push({
                        value: shop.PC_SP_ID,
                        label: shop.SP_NAME,
                        type: "shop"
                    })
                }
            }
            result['data'] = shopData.data
        }
        this.setState({ ShopList, AllShopData: shopData.data })
        this.props.toggleLoader(false)
        return result;
    }

    async getWeekNumberFormatted() {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil((days + 1) / 7);
        const year = date.getFullYear();
        const weekFormatted = `W${weekNumber.toString().padStart(2, '0')}`;
        const week = `${year}-${weekFormatted}`;
        let WeekDays = []
        let weekYearArr = week.split("-");
        weekYearArr[1] = weekYearArr[1].replace("W", "");
        let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
        let WeekOut = await getISOWeek(...MappedValue)
        if (WeekOut.length > 0) {
            for (let i = 0; i < WeekOut.length; i++) {
                const WeekPerDay = WeekOut[i];
                WeekDays.push({
                    'DateAndDay': WeekPerDay,
                    'Card': 0,
                    'Cash': 0,
                    'Others': 0
                })
            }
        }
        return {
            WeekDays,
            WeekNumber: `${year}-${weekFormatted}`
        };
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.handleSearch()
    })

    async handleUpdateSale(indx) {
        let { WeekSaleList } = this.state;
        let WeeklyShop = ""
        let Mydata = WeekSaleList[indx];
        let returnData = await this.getAllShopExpense(Mydata.PC_WS_WEEK, Mydata.PC_WS_SHOPID);



        if (returnData.AllShopData && returnData.AllShopData.data) {
            let filterResult = returnData.AllShopData.data.filter(x => x.PC_SP_ID === this.state.PC_SHOP_ID)
            if (filterResult && filterResult.length > 0) {

                WeeklyShop = {
                    value: filterResult[0].PC_SP_ID,
                    label: filterResult[0].SP_NAME,
                    type: "shop"
                }
            }
        }

        this.setState({
            ExtraShopExpenses: returnData.ExtraShopExpenses,
            payAccDetails: returnData.PayByAccountDetails,
            DeliveryDetails: returnData.DeliveryDetails,
            isEdit: true,
            isShopDisable: true,
            addWeekSale: true,
            WS_ID: Mydata.PC_WS_ID,
            SaleWeek: Mydata.PC_WS_WEEK,
            PC_WS_STATUS: Mydata.PC_WS_STATUS,
            WeeklyShop,
            WeekDays: JSON.parse(Mydata.PC_WS_DETAIL)
        })

    }

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getWeeklySale()
    }

    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getWeeklySale()
    }

    async handleChange(fieldName, evt) {
        let value
        switch (fieldName) {
            case 'WeeklyShop':
                value = evt;
                break;
            case 'SaleWeek':
                value = evt.target.value;
                let WeekNumber = evt.target.value;
                let weekYearArr = WeekNumber.split("-");
                weekYearArr[1] = weekYearArr[1].replace("W", "");
                let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                let WeekOut = await getISOWeek(...MappedValue)
                let WeekDays = []
                if (WeekOut.length > 0) {
                    for (let i = 0; i < WeekOut.length; i++) {
                        const WeekPerDay = WeekOut[i];
                        WeekDays.push({
                            'DateAndDay': WeekPerDay,
                            'Card': 0,
                            'Cash': 0,
                            'Others': 0
                        })
                    }
                }
                this.setState({ WeekDays })
                break;
            default:
                value = evt.target.value
                break;
        }
        this.setState({ [fieldName]: value })
    }

    ValidateSale() {
        if (this.state.SaleWeek === '') {
            Swal.fire(
                'Error',
                'Please Select Sale Week',
                'error'
            )
            return false
        }

        if (this.state.WeeklyShop === '') {
            Swal.fire(
                'Error',
                'Please Select Shop',
                'error'
            )
            return false
        }

        return true
    }

    handleAddTableItem() {
        let { ExtraShopExpenses } = this.state;
        ExtraShopExpenses.push({
            "description": "",
            "amount": 0,
            "isDisabled": false
        })

        this.setState({ ExtraShopExpenses })
    }

    async getAllShopExpense(SALEWEEK, SHOPID) {
        let AllShopData = await this.getShopList() || "";



        // TODO
        let formData = {
            SALEWEEK,
            SHOPID
        };

        let ExtraShopExpenses = []
        let PayByAccountDetails = []
        let DeliveryDetails = []
        let filterShopData = AllShopData?.data.filter(x => +x.PC_SP_ID === +SHOPID);
        if (filterShopData && filterShopData.length > 0) {
            ExtraShopExpenses.push(
                {
                    "description": "Rent Charges",
                    "amount": parseFloat(`${filterShopData[0].SP_RENT_CHARGES}`).toFixed(2),
                    "isDisabled": true
                },
                {
                    "description": "K & N Charges",
                    "amount": parseFloat(`${filterShopData[0].SP_KN_CHARGES}`).toFixed(2),
                    "isDisabled": true
                },
                {
                    "description": "NDR Charges",
                    "amount": parseFloat(`${filterShopData[0].SP_NDR_CHARGES}`).toFixed(2),
                    "isDisabled": true
                }
            )
        }


        const getAllPayAccounts = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/GetWeeklySaleDelivery.php`, formData);
        let shopData = getAllPayAccounts.data;

        if (shopData.dataA && shopData.dataA.length > 0) {
            ExtraShopExpenses.push({
                description: "Pay By Account",
                amount: parseFloat(shopData.dataA[0].TR_AMT || 0).toFixed(2),
                isDisabled: true
            })
        }

        if (shopData.dataB && shopData.dataB.length > 0) {
            PayByAccountDetails = shopData.dataB
        };

        if (shopData.dataAA && shopData.dataAA.length > 0) {
            ExtraShopExpenses.push({
                description: "Delivery",
                amount: parseFloat(shopData.dataAA[0].BD_AMT || 0).toFixed(2),
                isDisabled: true
            })
        }

        if (shopData.data && shopData.data.length > 0) {
            DeliveryDetails = shopData.data
        }

        if (shopData.dataD && shopData.dataD.length > 0) {
            for (let i = 0; i < shopData.dataD.length; i++) {
                const shopDet = shopData.dataD[i];
                ExtraShopExpenses.push({
                    description: shopDet.TR_DET,
                    amount: parseFloat(shopDet.TR_AMT).toFixed(2),
                    isDisabled: true
                })
            }
        }

        return {
            AllShopData,
            ExtraShopExpenses,
            PayByAccountDetails,
            DeliveryDetails
        }

    }

    async handleOpenSale() {
        if (!this.ValidateSale()) return
        this.props.toggleLoader(true)
        if (this.state.WS_ID === "") {
            let { WeeklyShop } = this.state;
            let returnData = await this.getAllShopExpense(this.state.SaleWeek, WeeklyShop.value);
            this.setState({
                ExtraShopExpenses: returnData.ExtraShopExpenses,
                payAccDetails: returnData.PayByAccountDetails,
                DeliveryDetails: returnData.DeliveryDetails
            })
        }

        let WS_Card = parseFloat(this.returnTotalCardSale()).toFixed(2)
        let WS_PRO_LOS = parseFloat(this.returnProfitAndLoss()).toFixed(2)

        let formData = {
            WS_ID: this.state.WS_ID,
            SaleWeek: this.state.SaleWeek,
            WS_STATUS: "PENDING",
            WeeklyShop: this.state.WeeklyShop.value,
            WeekDays: JSON.stringify(this.state.WeekDays),
            ExtraShopExpenses: JSON.stringify([]),
            LastUpdateBY: localStorage.getItem('PC_USR_NAME'),
            WS_Cash: parseFloat(this.returnTotalCashSale()).toFixed(2),
            WS_Card,
            WS_Other: parseFloat(this.returnTotalOtherSale()).toFixed(2),
            WS_OtherExp: parseFloat(this.retunTotalExtraAmount()).toFixed(2),
            WS_PRO_LOS
        }
        try {
            let getSaleResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Finance/PCAddWeeklySale.php`, formData);
            if (getSaleResult.data.status === 201) {
                Swal.fire(
                    'Success',
                    getSaleResult.data.message,
                    'success'
                ).then(res => {
                    if (res.isConfirmed) {
                        if (this.state.WS_ID === "") {
                            this.setState({ WS_ID: getSaleResult.data.extra[0].PC_WS_ID })
                        }
                        this.setState({ isEdit: true, isShopDisable: true })
                    } else {
                        this.handleExit()
                    }
                })
            } else {
                Swal.fire(
                    'Error',
                    getSaleResult.data.message,
                    'error'
                )
            }
            this.props.toggleLoader(false)

        } catch (error) {
            console.error('error', error)
            this.props.toggleLoader(false)
        }
    }

    handleExit() {
        let { totalRecordsPerPage } = this.state
        this.getWeeklySale()
        this.setState({
            isEdit: false,
            addWeekSale: false,
            WS_ID: "",
            SaleWeek: "",
            WeeklyShop: "",
            WeekDays: [],
            ExtraShopExpenses: []
        })
    }

    handleChangeWeekSale(index, evnt) {
        let { WeekDays } = this.state
        WeekDays[index][evnt.target.name] = evnt.target.value
        this.setState({ WeekDays })
    }

    handleChangeExtraWeekSale(index, evnt) {
        let { ExtraShopExpenses } = this.state
        ExtraShopExpenses[index][evnt.target.name] = evnt.target.value
        this.setState({ ExtraShopExpenses })
    }

    returnTotalCashSale() {
        let CashSum = this.state.WeekDays.reduce(function (sum, current) {
            return sum + +current.Cash;
        }, 0);

        return CashSum

    }

    returnTotalCardSale() {
        let CardSum = this.state.WeekDays.reduce(function (sum, current) {
            return sum + +current.Card;
        }, 0);

        return CardSum
    }

    returnTotalOtherSale() {
        let OtherSum = this.state.WeekDays.reduce(function (sum, current) {
            return sum + +current.Others;
        }, 0);

        return OtherSum
    }

    retunTotalExtraAmount() {
        let ExtraSum = this.state.ExtraShopExpenses.reduce(function (sum, current) {
            return sum + +current.amount;
        }, 0);
        return ExtraSum
    }

    returnProfitAndLoss() {
        let ProfitAndLoss = 0
        let TotalSale = this.returnTotalCashSale() + this.returnTotalCardSale() + this.returnTotalOtherSale()
        ProfitAndLoss = TotalSale - this.retunTotalExtraAmount()
        return ProfitAndLoss
    }

    async handleSendForApproval(Status) {
        this.props.toggleLoader(true)
        let formData = {
            WS_ID: this.state.WS_ID,
            WS_STATUS: Status
        }
        try {
            let getSaleResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Finance/PCRequestWeeklySale.php`, formData);
            if (Status === 'APPROVED') {
                let WS_Card = await parseFloat(this.returnTotalCardSale()).toFixed(2)
                let WS_PRO_LOS = await parseFloat(this.returnProfitAndLoss()).toFixed(2)
                let formDataB = {
                    SALE_DATE: this.state.SaleWeek,
                    SHOP_ID: this.state.WeeklyShop.value,
                    WS_Card,
                    WS_PRO_LOS
                }
                await axios.post(`${process.env.REACT_APP_BASE_URL}services/linking/WeeklySale.php`, formDataB);
            }
            if (getSaleResult.data.status === 201) {
                Swal.fire(
                    'Success',
                    getSaleResult.data.message,
                    'success'
                )
                this.handleExit()
            } else {
                Swal.fire(
                    'Error',
                    getSaleResult.data.message,
                    'error'
                )
            }
            this.props.toggleLoader(false)

        } catch (error) {
            console.error('error', error)
            this.props.toggleLoader(false)
        }
    }

    handleDelete(PC_WS_ID) {
        Swal.fire({
            title: 'Do you want to delete weekly sale ?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.props.toggleLoader(true)
                const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/delete/finance/DeleteWeeklySale.php`, { PC_WS_ID });
                let shopData = getShops.data
                if (shopData.success === 1 && shopData.message === "You have successfully delete track.") {
                    Swal.fire('Weekly Sale Deleted!', '', 'success')
                    this.getWeeklySale()
                }
            }
        })
    }

    handleBlurWeekSale(index, evt) {
        let { WeekDays } = this.state
        WeekDays[index][evt.target.name] = parseFloat(evt.target.value).toFixed(2)
        this.setState({ WeekDays })
    }

    handleBlurExtraWeekSale(index, evnt) {
        let { ExtraShopExpenses } = this.state
        ExtraShopExpenses[index][evnt.target.name] = parseFloat(evnt.target.value).toFixed(2)
        this.setState({ ExtraShopExpenses })
    }

    handleRemove(index) {
        let { ExtraShopExpenses } = this.state;
        ExtraShopExpenses.splice(index, 1)
        this.setState({ ExtraShopExpenses })
    }

    handleShowDetails(desc) {
        switch (desc) {
            case "Pay By Account":
                this.setState({ showModal: true })
                break;
            case "Delivery":
                this.setState({ showDelModal: true })
                break;

            default:
                break;
        }
    }


    handleChangeSearch = debounceFunc((label, evt) => {
        this.setState({ [label]: evt.target.value }, () => {


            let {
                WEEK_SEARCH,
                SHOP_SEARCH,
                CASH_SEARCH,
                CARD_SEARCH,
                OTHERS_SEARCH,
                CB_SEARCH,
                STATUS_SEARCH
            } = this.state;
            this.handleSearch(
                WEEK_SEARCH,
                SHOP_SEARCH,
                CASH_SEARCH,
                CARD_SEARCH,
                OTHERS_SEARCH,
                CB_SEARCH,
                STATUS_SEARCH)
        })
    })


    handleSearch = (
        WEEK_SEARCH = "",
        SHOP_SEARCH = "",
        CASH_SEARCH = "",
        CARD_SEARCH = "",
        OTHERS_SEARCH = "",
        CB_SEARCH = "",
        STATUS_SEARCH = "") => {
        this.getWeeklySale(
            WEEK_SEARCH,
            SHOP_SEARCH,
            CASH_SEARCH,
            CARD_SEARCH,
            OTHERS_SEARCH,
            CB_SEARCH,
            STATUS_SEARCH,
        )
    }

    render() {
        const { addWeekSale, isEdit } = this.state;
        let { WeekSaleList } = this.state;

        return (
            <Container>
                {addWeekSale === false ?
                    <PhoneClinicGrid
                        title="WEEKLY SALES"
                        buttionLabel="Week Sale"
                        onClickButton={this.hanldeAddWeekSale.bind(this)}
                        handleChangeSearch={(key) => this.handleChangeSearch.bind(this, key)}
                        tableHeadData={[
                            {
                                label: "WEEK",
                                isHidden: false
                            },
                            {
                                label: "SHOP",
                                isHidden: false
                            },
                            {
                                label: "CASH",
                                isHidden: false
                            },
                            {
                                label: "CARD",
                                isHidden: false
                            },
                            {
                                label: "OTHERS",
                                isHidden: false
                            },
                            {
                                label: "CREATED BY",
                                isHidden: false
                            },
                            {
                                label: "STATUS",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "WEEK_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "SHOP_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "CASH_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "CARD_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "OTHERS_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "CB_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "STATUS_SEARCH",
                                isHidden: false,
                                isDisable: false
                            }
                        ]}
                    >
                        {WeekSaleList.map((service, idx) => {
                            let ParsingData = JSON.parse(service.PC_WS_DETAIL);
                            let OthersSum = ParsingData.reduce(function (sum, current) {
                                return sum + +current.Others;
                            }, 0);

                            return (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <TableCell align='center'>{`${ParsingData[0]['DateAndDay']} - ${ParsingData[6]['DateAndDay']}`}</TableCell>
                                    <TableCell align='center' style={{ cursor: 'pointer', color: "#416bc7" }} >{service.SP_NAME}</TableCell>
                                    <TableCell align='center'>{service.WS_Cash}</TableCell>
                                    <TableCell align='center'>{service.WS_Card}</TableCell>
                                    <TableCell align='center'>{OthersSum}</TableCell>
                                    <TableCell align='center'>{service.PC_WS_BY}</TableCell>
                                    <TableCell align='center' style={{ cursor: 'pointer', color: "#416bc7" }} >{service.PC_WS_STATUS}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ padding: "0px" }} onClick={this.handleUpdateSale.bind(this, idx)} aria-label="Edit Data" component="span">
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.handleDelete.bind(this, service.PC_WS_ID)} >
                                            <RemoveIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </PhoneClinicGrid>
                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Weekly Sale</legend>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <Form.Group className="mb-3" >
                                    <Form.Label
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <span>
                                            Week
                                        </span>
                                        <span>
                                            {this.state.WeekDays.length > 0 ? `(${this.state.WeekDays[0]['DateAndDay']} - ${this.state.WeekDays[6]['DateAndDay']})` : ``}
                                        </span>
                                    </Form.Label>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"week"}
                                        disabled={this.state.isShopDisable}
                                        value={this.state.SaleWeek}
                                        onChange={this.handleChange.bind(this, "SaleWeek")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Shop</Form.Label>
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        isDisabled={this.state.isShopDisable}
                                        value={this.state.WeeklyShop}
                                        options={this.state.ShopList}
                                        onChange={this.handleChange.bind(this, "WeeklyShop")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    isEdit ?
                        <div>
                            <Row>
                                <Col md={6}>
                                    <Paper>
                                        <TableContainer sx={{ maxHeight: '70vh' }}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Day / Date</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Cash</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Card</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Others</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.WeekDays.map((service, idx) => {
                                                        return (
                                                            <TableRow
                                                                className="table-rows"
                                                                key={idx}
                                                                style={{ cursor: 'pointer' }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>{service.DateAndDay}</TableCell>
                                                                <TableCell>
                                                                    <Form.Control
                                                                        name="Cash"
                                                                        onBlur={this.handleBlurWeekSale.bind(this, idx)}
                                                                        style={{ padding: "0.25rem" }}
                                                                        value={service.Cash}
                                                                        onChange={this.handleChangeWeekSale.bind(this, idx)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Form.Control
                                                                        name="Card"
                                                                        style={{ padding: "0.25rem" }}
                                                                        onBlur={this.handleBlurWeekSale.bind(this, idx)}
                                                                        value={service.Card}
                                                                        onChange={this.handleChangeWeekSale.bind(this, idx)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Form.Control
                                                                        name="Others"
                                                                        style={{ padding: "0.25rem" }}
                                                                        onBlur={this.handleBlurWeekSale.bind(this, idx)}
                                                                        value={service.Others}
                                                                        onChange={this.handleChangeWeekSale.bind(this, idx)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <div style={{ background: '#e7e7e7', padding: '5px', textAlign: 'end', display: 'flex', justifyContent: 'flex-end' }} >
                                            <div style={{ width: '40%' }} >
                                                <span style={{ color: '#111827' }} >Total: </span>{parseFloat(this.returnTotalCashSale()).toFixed(2)}
                                            </div>
                                            <div style={{ width: '40%' }} >
                                                <span style={{ color: '#111827' }} >Total: </span>{parseFloat(this.returnTotalCardSale()).toFixed(2)}
                                            </div>
                                            <div style={{ width: '40%' }} >
                                                <span style={{ color: '#111827' }} >Total: </span>{parseFloat(this.returnTotalOtherSale()).toFixed(2)}
                                            </div>
                                        </div>
                                    </Paper>
                                    <div style={{ padding: '5px', float: 'right' }} >
                                        <span style={{ color: '#4842a7' }} >Total Sale: </span>{parseFloat(this.returnTotalCashSale() + this.returnTotalCardSale() + this.returnTotalOtherSale()).toFixed(2)}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Paper>
                                        <TableContainer>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sr.No</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                        {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                        <div onClick={this.handleAddTableItem.bind(this)} >
                                                            <Add className="HoverButtonAdd" />
                                                        </div>
                                                    </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.ExtraShopExpenses.map((service, idx) => (
                                                        <TableRow
                                                            className="table-rows"
                                                            key={idx}
                                                            style={{ cursor: 'pointer' }}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell onClick={this.handleShowDetails.bind(this, service.description)} >{idx + 1}</TableCell>
                                                            <TableCell>
                                                                <Form.Control
                                                                    name="description"
                                                                    style={{ padding: "0.25rem" }}
                                                                    disabled={service.isDisabled}
                                                                    value={service.description}
                                                                    onChange={this.handleChangeExtraWeekSale.bind(this, idx)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Form.Control
                                                                    name="amount"
                                                                    style={{ padding: "0.25rem" }}
                                                                    disabled={service.isDisabled}
                                                                    value={service.amount}
                                                                    onBlur={this.handleBlurExtraWeekSale.bind(this, idx)}
                                                                    onChange={this.handleChangeExtraWeekSale.bind(this, idx)}
                                                                />
                                                            </TableCell>
                                                            {/* {!service.isDisabled && <TableCell>
                                                            <div onClick={this.handleRemove.bind(this, idx)} >
                                                                <Remove />
                                                            </div>
                                                        </TableCell>} */}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <div style={{ background: '#e7e7e7', padding: '5px', textAlign: 'end', display: 'flex', justifyContent: 'flex-end' }} >
                                            <div style={{ width: '40%' }} >
                                                <span style={{ color: '#111827' }} >Total: </span>{parseFloat(this.retunTotalExtraAmount()).toFixed(2)}
                                            </div>
                                        </div>
                                    </Paper>
                                    <div style={{ padding: '5px', float: 'right' }} >
                                        <span style={{ color: '#4842a7' }} >Profit / Loss: </span>{parseFloat(this.returnProfitAndLoss()).toFixed(2)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        :
                        null
                }
                {
                    addWeekSale === true ?
                        <div>
                            <Row>
                                <Col>
                                    <Button onClick={this.handleOpenSale.bind(this)} >{isEdit ? 'Update Sale' : 'Open Sale'}</Button>
                                    <Button onClick={this.handleExit.bind(this)} >Cancel</Button>
                                    {isEdit ? <Button style={{ background: '#befebf', color: '#1c8709' }} variant={'contained'} onClick={this.handleSendForApproval.bind(this, this.state.PC_WS_STATUS === "PENDING" ? "REQUESTED" : "APPROVED")} >{this.state.PC_WS_STATUS === "PENDING" ? "Send For Approval" : "Approve"} </Button> : ''}
                                </Col>
                            </Row>
                        </div>
                        :
                        null
                }
                <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Pay Account Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TableContainer>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sr.No</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >From</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Week</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.payAccDetails.map((payAccDet, idx) => (
                                        <TableRow
                                            className="table-rows"
                                            key={idx}
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{idx + 1}</TableCell>
                                            <TableCell>{payAccDet.ACCOUNT_TITLE}</TableCell>
                                            <TableCell>{payAccDet.TR_WEEK}</TableCell>
                                            <TableCell>{payAccDet.TR_DET}</TableCell>
                                            <TableCell>{payAccDet.TR_AMT}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={() => this.setState({ showModal: false })}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showDelModal} onHide={() => this.setState({ showDelModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Weekly Delivery Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TableContainer>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sr.No</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >From</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Week</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.DeliveryDetails.map((payAccDet, idx) => (
                                        <TableRow
                                            className="table-rows"
                                            key={idx}
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{idx + 1}</TableCell>
                                            <TableCell>{payAccDet.BD_TO}</TableCell>
                                            <TableCell>{payAccDet.BD_WEEK}</TableCell>
                                            <TableCell>{payAccDet.BD_DESC}</TableCell>
                                            <TableCell>{payAccDet.BD_AMT}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={() => this.setState({ showDelModal: false })}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        );
    }
}