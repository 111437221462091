import React, { Component } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Col, Container, Row, Form } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatDate, displayDate } from '../../../helper';
import ToggleButton from '@mui/material/ToggleButton';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import PercentIcon from '@mui/icons-material/Percent';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select from 'react-select'
import axios from "axios";
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import ReactToPrint from 'react-to-print';
import SaleInvoiceReport from '../../../Report/SaleInvoiceReport';
import PhoneClinicGrid from '../../../Components/PhoneClinicGrid';

export default class SaleInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            addSale: false,
            SaleList: [],
            ItemList: [],

            WareHouseList: [{
                value: "F4",
                label: "F4"
            }],


            Customer: "",
            SALE_TO_TYPE: "Customer",
            Warehouse: {
                value: "F4",
                label: "F4"
            },
            Date: formatDate(new Date().toDateString()),
            Description: "",

            Item: {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            },

            AddedItemList: [],

            InventoryList: [],
            EMAIL_TO: "",
            isEdit: false,
            ShopList: [],
            AllWareHouseList: [],
            SaleToShop: "",
            SaleToWarehouse: "",
            OTHER_DATA: localStorage.getItem('OTHER_DATA')
        };
    }


    componentDidMount() {
        this.getSaleInvoice()
        this.getInventory()
        this.getShopList()
        this.getWarehouseList()
    }


    async getShopList() {
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                ShopList.push({
                    value: shop.SP_NAME,
                    label: shop.SP_NAME
                })
            }
        }
        this.setState({ ShopList })
    }

    async getWarehouseList() {
        const getWarehouse = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllWarehouse.php`);
        let warehouseData = getWarehouse.data
        let AllWareHouseList = []
        if (warehouseData.success === 1) {
            for (let i = 0; i < warehouseData.data.length; i++) {
                const warehouse = warehouseData.data[i];
                AllWareHouseList.push({
                    value: warehouse.WAREHOUSE_NAME,
                    label: warehouse.WAREHOUSE_NAME
                })
            }
        }
        this.setState({ AllWareHouseList })
    }

    async getSaleInvoice() {
        let formData = {
            PC_SP_ID: localStorage.getItem('PC_GRP_ATT') || 0
        };
        if (formData.PC_SP_ID !== 0) {
            const getAllSale = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllSale.php`, formData);
            let SaleData = getAllSale.data
            let SaleList = []
            if (SaleData.success === 1) {
                SaleList = SaleData.data
            }
            this.setState({ SaleList })
        }

    }

    async getInventory() {
        let formData = {
            PC_SP_ID: localStorage.getItem('PC_GRP_ATT') || 0
        };
        if (formData.PC_SP_ID !== 0) {
            const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getInventory.php`, formData);
            let serviceData = getAllInventory.data
            let ItemList = []
            let InventoryList = []
            if (serviceData.success === 1) {
                InventoryList = serviceData.data
                for (let i = 0; i < InventoryList.length; i++) {
                    const element = InventoryList[i];
                    ItemList.push({
                        'label': `${element.DEVICE_ID || ""} ${element.INV_ITEM_NAME}`,
                        'value': element.INV_ITEM_NAME,
                        'id': element.INV_ID
                    })
                }

            }
            this.setState({ InventoryList, ItemList })
        }
    }

    hanldeAddSale() {
        this.setState({
            addSale: true
        })
        this.getInventory()
    }

    handleSearchgridData() {

    }

    handleUpdateSale(indx) {
        let { SaleList } = this.state
        let SaleData = SaleList[indx]

        this.setState({
            isEdit: true,
            addSale: true,
            Customer: SaleData.SALE_CUSTOMER,
            SaleToShop: { value: SaleData.SALE_CUSTOMER, label: SaleData.SALE_CUSTOMER },
            SaleToWarehouse: { value: SaleData.SALE_CUSTOMER, label: SaleData.SALE_CUSTOMER },
            SALE_TO_TYPE: SaleData.SALE_TO_TYPE,
            Warehouse: { value: SaleData.SALE_WAREHOUSE, label: SaleData.SALE_WAREHOUSE },
            Date: SaleData.SALE_DATE,
            Description: SaleData.SALE_DESCRIPTION,
            AddedItemList: JSON.parse(SaleData.SALE_ITEM)

        })


    }

    handleChangeTotalRecords() {

    }

    handleChangePagination() {

    }

    handleChangeUpdatedItem(fieldName, evt, evtA) {
        let { Item, InventoryList } = this.state;
        Item['ItemQuantity'] = 1
        Item['ItemDisc'] = 0
        switch (fieldName) {
            case 'ItemName':
                let findInventory = InventoryList.filter(x => x.INV_ITEM_NAME === evt.value)?.[0]
                Item['ItemName'] = evt
                Item['ItemRate'] = parseInt(findInventory.PC_ITEM_SELL_RATE)
                Item['ItemIMEI'] = findInventory.INV_BARCODE
                Item['ItemAmount'] = parseInt(findInventory.PC_ITEM_SELL_RATE)
                Item['ItemDesc'] = findInventory.INV_DESC
                break;

            case 'ItemDisType':
                Item['ItemDisType'] = evtA
                if (evtA === 'number') {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - Item['ItemDisc']
                } else {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - ((Item['ItemQuantity'] * Item['ItemRate']) * (Item['ItemDisc'] / 100))
                }
                break;
            case 'ItemQuantity':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = (evt.target.value * Item['ItemRate']) - Item['ItemDisc']
                } else {
                    Item['ItemAmount'] = (evt.target.value * Item['ItemRate']) - ((evt.target.value * Item['ItemRate']) * (Item['ItemDisc'] / 100))
                }
                break;
            case 'ItemRate':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * evt.target.value) - Item['ItemDisc']
                } else {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * evt.target.value) - ((Item['ItemQuantity'] * evt.target.value) * (Item['ItemDisc'] / 100))
                }
                break;

            case 'ItemDisc':
                Item[evt.target.name] = evt.target.value
                // now setting relative condition
                if (Item['ItemDisType'] === 'number') {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - evt.target.value
                } else {
                    Item['ItemAmount'] = (Item['ItemQuantity'] * Item['ItemRate']) - ((Item['ItemQuantity'] * Item['ItemRate']) * (evt.target.value / 100))
                }
                break;

            case 'ItemIMEI':
                // let findInventory = InventoryList.filter(x => x.INV_BARCODE === evt.target.value || x.INV_BARCODE === evt.target.value)?.[0]
                Item[evt.target.name] = evt.target.value
                break;

            default:
                Item[evt.target.name] = evt.target.value
                break;

        }
        this.setState({ Item })
    }


    handleValidateItem(Item) {
        if (Item.ItemName === '' || Item?.ItemName?.value === "") {
            Swal.fire(
                'Error',
                'Please select Item',
                'error'
            )
            return false
        }
        if (Item.ItemRate === '' || parseInt(Item.ItemRate) <= 0) {
            Swal.fire(
                'Error',
                'Rate must be greater than 0',
                'error'
            )
            return false
        }
        if (Item.ItemQuantity === '' || parseInt(Item.ItemQuantity) <= 0) {
            Swal.fire(
                'Error',
                'Quantity must be greater than 0',
                'error'
            )
            return false
        }
        return true
    }

    handleAddItem() {
        let { Item, AddedItemList } = this.state
        if (!this.handleValidateItem(Item)) return
        let checkIfItemAvailable = AddedItemList.filter(x => x.ItemName.id === Item.ItemName.id)
        let CheckIndex = AddedItemList.findIndex(x => x.ItemName.id === Item.ItemName.id);
        if (checkIfItemAvailable.length > 0) {
            let AddedItemContent = checkIfItemAvailable[0]
            let CalculatedQuantity = parseInt(AddedItemContent['ItemQuantity']) + parseInt(Item['ItemQuantity']);
            AddedItemList[CheckIndex]['ItemDesc'] = Item['ItemDesc'];
            AddedItemList[CheckIndex]['ItemDisc'] = Item['ItemDisc'];
            AddedItemList[CheckIndex]['ItemDisType'] = Item['ItemDisType'];
            AddedItemList[CheckIndex]['ItemRate'] = Item['ItemRate'];
            AddedItemList[CheckIndex]['ItemQuantity'] = CalculatedQuantity;

            if (Item['ItemDisType'] === 'number') {
                Item['ItemAmount'] = (CalculatedQuantity * Item['ItemRate']) - Item['ItemDisc']
            } else {
                Item['ItemAmount'] = (CalculatedQuantity * Item['ItemRate']) - ((CalculatedQuantity * Item['ItemRate']) * (Item['ItemDisc'] / 100))
            }

            AddedItemList[CheckIndex]['ItemAmount'] = parseInt(Item['ItemAmount']);
            this.setState({ AddedItemList })
        } else {
            let UpdateItemList = [Item].concat(AddedItemList)
            this.setState({ AddedItemList: UpdateItemList })
        }
        let RefreshItem = {
            ItemName: "",
            ItemIMEI: "",
            ItemDesc: "",
            ItemRate: 0,
            ItemQuantity: 0,
            ItemDisc: 0,
            ItemAmount: 0,
            ItemDisType: "number"
        }
        this.setState({
            Item: RefreshItem
        })

    }

    handleChange(fieldName, evt) {
        let value
        switch (fieldName) {
            case 'Warehouse':
                value = evt
                break;
            case 'SaleToShop':
            case 'SaleToWarehouse':
                // fieldName = "Customer"
                value = evt
                break;
            default:
                value = evt.target.value
                break;
        }
        this.setState({
            [fieldName]: value
        })
    }

    ValidateInvoice() {
        // if (this.state.Customer === "") {
        //     Swal.fire(
        //         'Error',
        //         'Please Enter Name',
        //         'error'
        //     )
        //     return false
        // }
        if (this.state.AddedItemList.length === 0) {
            Swal.fire(
                'Error',
                'Please Add atleast 1 Item',
                'error'
            )
            return false
        }
        return true
    }

    async handleSaveInvoice() {

        if (!this.ValidateInvoice()) return
        let { SALE_TO_TYPE } = this.state
        let formData = {
            SALE_BY: localStorage.getItem('PC_USR_NAME'),
            SALE_FROM: localStorage.getItem('PC_GRP_ATT'),
            SALE_CUSTOMER: SALE_TO_TYPE === 'Customer' ? this.state.Customer : SALE_TO_TYPE === 'Shop' ? this.state.SaleToShop?.value : this.state.SaleToWarehouse?.value,
            SALE_TO_TYPE: SALE_TO_TYPE,
            SALE_WAREHOUSE: this.state.Warehouse.value,
            SALE_DATE: this.state.Date,
            SALE_DESCRIPTION: this.state.Description,
            SALE_ITEM: JSON.stringify(this.state.AddedItemList)
        }

        let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddSale.php`, formData);
        if (getSaleInvoiceResult.data.status === 200) {
            Swal.fire(
                'Success',
                getSaleInvoiceResult.data.message,
                'success'
            )
        } else {
            Swal.fire(
                'Error',
                getSaleInvoiceResult.data.message,
                'error'
            )
        }
        this.setState({
            addSale: false,
            ItemList: [],

            WareHouseList: [{
                value: "F4",
                label: "F4"
            }],
            Customer: "",
            Warehouse: {},
            Date: formatDate(new Date().toDateString()),
            Description: "",
            Item: {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            },
            AddedItemList: [],
            isEdit: false
        })
        this.getSaleInvoice()
    }

    handleValidate() {
        if (this.state.EMAIL_TO === "") {
            Swal.fire(
                'Error',
                'Please Enter Email Address',
                'error'
            )
            return false
        }
        return true
    }

    async handleSendEmail() {

        if (!this.handleValidate()) return

        let formData = {
            SALE_BY: localStorage.getItem('PC_USR_NAME'),
            SALE_FROM: localStorage.getItem('PC_GRP_ATT'),
            SALE_CUSTOMER: this.state.Customer,
            SALE_WAREHOUSE: this.state.Warehouse.value,
            SALE_DATE: this.state.Date,
            SALE_DESCRIPTION: this.state.Description,
            SALE_ITEM: JSON.stringify(this.state.AddedItemList),
            EMAIL_TO: this.state.EMAIL_TO
        }
        let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}mail/SendMail.php`, formData);
        if (getSaleInvoiceResult.data.status === 201) {
            Swal.fire(
                'Success',
                'Email has been send!!',
                'success'
            )
        }
    }

    handleExit() {
        this.setState({
            addSale: false,
            ItemList: [],

            WareHouseList: [{
                value: "F4",
                label: "F4"
            }],

            Customer: "",
            Warehouse: {},
            Date: formatDate(new Date().toDateString()),
            Description: "",

            Item: {
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemDisc: 0,
                ItemAmount: 0,
                ItemDisType: "number"
            },

            AddedItemList: [],
            isEdit: false
        })
    }

    renderTotalAmount() {
        let { AddedItemList } = this.state;
        let Price = AddedItemList.map(({ ItemAmount }) => ItemAmount).reduce((sum, i) => sum + i, 0);
        return Price
    }

    handleKeyPress(evnt) {
        if (evnt.key === 'Enter') {
            this.handleAddItem()
        }
    }

    handleEnterImei(evnt) {
        if (evnt.key === 'Enter') {
            let { Item, InventoryList } = this.state;

            let FilterItem = InventoryList.filter(x => x.INV_BARCODE === Item.ItemIMEI)

            if (FilterItem.length > 0) {
                Item['ItemName'] = { value: FilterItem[0].INV_ITEM_NAME, label: FilterItem[0].INV_ITEM_NAME }
                Item['ItemDesc'] = FilterItem[0].INV_DESC
                Item['ItemRate'] = FilterItem[0].PC_ITEM_SELL_RATE
                Item['ItemQuantity'] = 1
                Item['ItemDisc'] = 0
                Item['ItemAmount'] = (FilterItem[0].PC_ITEM_QUANTITY * FilterItem[0].PC_ITEM_SELL_RATE) - ((FilterItem[0].PC_ITEM_QUANTITY * FilterItem[0].PC_ITEM_SELL_RATE) * (0 / 100))
            }

            this.setState({ Item }, () => { this.handleAddItem() })
        }

    }

    handleRemove(index) {
        let { AddedItemList } = this.state;
        AddedItemList.splice(index, 1)
        this.setState({ AddedItemList })
    }

    deleteSale(indx) {
        Swal.fire({
            title: 'Do you want to delete sale ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.props.toggleLoader(true)
                const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/delete/sale/DeleteShopSale.php`, { SALE_ID: indx });
                console.log('getShops', getShops)
                let shopData = getShops.data
                if (shopData.success === 1) {
                    Swal.fire(shopData.message, '', 'success')
                    this.getSaleInvoice()
                }
                this.props.toggleLoader(false)
            }
        })
    }


    render() {
        const { addSale } = this.state
        let { Item } = this.state;
        return (
            <Container>
                {addSale === false ?
                    <PhoneClinicGrid
                        title="ITEM SALE"
                        buttionLabel="Add sale"
                        onClickButton={this.hanldeAddSale.bind(this)}
                        handleChangeSearch={(key) => { }}
                        tableHeadData={[
                            {
                                label: "CUSTOMER",
                                isHidden: false
                            },
                            {
                                label: "DESCRIPTION",
                                isHidden: false
                            },
                            {
                                label: "WAREHOUSE",
                                isHidden: false
                            },
                            {
                                label: "DATE",
                                isHidden: false
                            },
                            {
                                label: "SALE BY",
                                isHidden: false
                            },
                        ]
                        }
                        searchData={
                            [
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                },
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                },
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                },
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                },
                                {
                                    key: "SHOP_SEARCH",
                                    isHidden: false,
                                    isDisable: false
                                }
                            ]}
                    >
                        {this.state.SaleList.map((service, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <TableCell align='center' >{service.SALE_CUSTOMER}</TableCell>
                                <TableCell align='center' >{service.SALE_DESCRIPTION}</TableCell>
                                <TableCell align='center' >{service.SALE_WAREHOUSE}</TableCell>
                                <TableCell align='center' >{service.SALE_DATE}</TableCell>
                                <TableCell align='center' >{service.SALE_BY}</TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} aria-label="Edit Data" component="span" onClick={this.handleUpdateSale.bind(this, idx)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.deleteSale.bind(this, service.SALE_ID)} >
                                        <RemoveIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </PhoneClinicGrid>
                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Sale Invoice</legend>
                        </Row>
                        <Row style={{ margin: "5px" }} >
                            <Col md={9}>
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                                        <div>
                                            {this.state.SALE_TO_TYPE}
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                        </div>
                                        <div>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.state.SALE_TO_TYPE}
                                                exclusive
                                                disabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                size="small"
                                                name="SALE_TO_TYPE"
                                                onChange={this.handleChange.bind(this, 'SALE_TO_TYPE')}
                                                aria-label="small button group"
                                            >
                                                <ToggleButton value="Customer">Customer</ToggleButton>
                                                <ToggleButton value="Shop">Shop</ToggleButton>
                                                <ToggleButton value="Warehouse">Warehouse</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </Form.Label>
                                    {
                                        this.state.SALE_TO_TYPE === "Customer" ?
                                            <Form.Control
                                                disabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                style={{ padding: "0.25rem" }}
                                                value={this.state.Customer}
                                                onChange={this.handleChange.bind(this, "Customer")}
                                            />
                                            :
                                            this.state.SALE_TO_TYPE === "Shop" ?
                                                <Select
                                                    menuPlacement="auto"
                                                    style={{ height: '32px' }}
                                                    isDisabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                    value={this.state.SaleToShop}
                                                    options={this.state.ShopList}
                                                    onChange={this.handleChange.bind(this, "SaleToShop")}
                                                />
                                                :
                                                <Select
                                                    menuPlacement="auto"
                                                    style={{ height: '32px' }}
                                                    isDisabled={this.state.isEdit && this.state.addSale === true ? true : false}
                                                    value={this.state.SaleToWarehouse}
                                                    options={this.state.AllWareHouseList}
                                                    onChange={this.handleChange.bind(this, "SaleToWarehouse")}
                                                />
                                    }
                                </Form.Group>
                            </Col>
                            {/* <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Warehouse</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}

                                        value={this.state.Warehouse}
                                        options={this.state.WareHouseList}
                                        onChange={this.handleChange.bind(this, "Warehouse")}
                                    />
                                </Form.Group>
                            </Col> */}
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Date</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"date"}
                                        value={this.state.Date}
                                        disabled
                                        onChange={this.handleChange.bind(this, "Date")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"textarea"}
                                        value={this.state.Description}
                                        onChange={this.handleChange.bind(this, "Description")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.isEdit === false ?
                            <Row style={{ margin: "5px" }} >
                                <Col md={11} >
                                    <Row>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Item</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Select
                                                    name={"ItemName"}
                                                    menuPlacement="auto"
                                                    style={{ height: '32px' }}
                                                    value={Item['ItemName']}
                                                    options={this.state.ItemList}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemName')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Rate</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemRate"}
                                                    value={Item["ItemRate"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemRate')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Qty.</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemQuantity"}
                                                    value={Item["ItemQuantity"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemQuantity')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Dis.</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemDisc"}
                                                    value={Item["ItemDisc"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDisc')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label>Discount Type</Form.Label>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={Item["ItemDisType"]}
                                                exclusive
                                                size="small"
                                                name="ItemDisType"
                                                onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDisType')}
                                                aria-label="Small Sizes"
                                            >
                                                <ToggleButton value="number"><CurrencyPoundIcon /></ToggleButton>
                                                <ToggleButton value="percent"><PercentIcon /></ToggleButton>
                                            </ToggleButtonGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Amount</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemAmount"}
                                                    value={Item["ItemAmount"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    disabled
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemAmount')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>IMEI No / Barcode</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemIMEI"}
                                                    value={Item["ItemIMEI"]}
                                                    style={{ padding: "0.25rem" }}
                                                    onKeyPress={this.handleEnterImei.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemIMEI')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Description</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemDesc"}
                                                    value={Item["ItemDesc"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"textarea"}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDesc')}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col md={1} style={{ alignSelf: 'center' }} >
                                    <Button style={{ background: 'green', color: 'white' }} onClick={this.handleAddItem.bind(this)} >Add</Button>
                                </Col>
                            </Row>
                            :
                            ""
                        }
                        <Row
                            style={{
                                height: "30vh",
                                overflow: "auto"
                            }}
                        >
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '25vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Name</TableCell>
                                                {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell> */}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >IMEI/Barcode</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Rate</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Quantity</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Discount</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                {!this.state.isEdit ?
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                        <IconButton aria-label="Edit Data" component="span">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    :
                                                    ""
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.AddedItemList.map((ItemDetails, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell >{ItemDetails.ItemName?.value}</TableCell>
                                                    {/* <TableCell >{ItemDetails.ItemDesc}</TableCell> */}
                                                    <TableCell >{ItemDetails.ItemIMEI}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemRate}</TableCell>
                                                    <TableCell >{ItemDetails.ItemQuantity}</TableCell>
                                                    <TableCell >{ItemDetails.ItemDisc === "0" || ItemDetails.ItemDisc === 0 ? "" : `${ItemDetails.ItemDisType === 'number' ? '£' : '%'} ${ItemDetails.ItemDisc}`}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemAmount}</TableCell>
                                                    {!this.state.isEdit ?
                                                        <TableCell >
                                                            <IconButton aria-label="Edit Data" component="span" onClick={this.handleRemove.bind(this, idx)} >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        : ""
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TableContainer> */}
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {/* <TableCell colSpan={3} /> */}
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} rowSpan={4} colSpan={3}>Net Sale</TableCell>
                                            <TableCell>£ {this.renderTotalAmount()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                            </Paper>
                        </Row>
                        <Row>
                            <SaleInvoiceReport
                                ref={el => (this.printThermalSale = el)}
                                data={{
                                    ItemList: this.state.AddedItemList,
                                    SaleDate: displayDate(this.state.Date),
                                    SaleTo: this.state.SALE_TO_TYPE === 'Customer' ? this.state.Customer : this.state.SaleToShop?.value,
                                    TotalAmount: this.renderTotalAmount(),
                                    OTHER_DATA: this.state.OTHER_DATA
                                }}
                            />
                            <Col md={6} >
                                <Button onClick={this.handleExit.bind(this)} >Exit</Button>
                                {!this.state.isEdit ?
                                    <Button className="add-button" size="small" variant="contained" onClick={this.handleSaveInvoice.bind(this)} >Save</Button>
                                    :
                                    ""
                                }

                            </Col>
                            {this.state.isEdit ?
                                <Col md={6} style={{ textAlign: 'end' }} >
                                    <div style={{ display: "flex", justifyContent: "space-between" }} >
                                        <Form.Control
                                            style={{ padding: "0.25rem", width: "50%" }}
                                            value={this.state.EMAIL_TO}
                                            placeholder="Enter Email"
                                            onChange={this.handleChange.bind(this, "EMAIL_TO")}
                                        />
                                        <div>
                                            <Button onClick={this.handleSendEmail.bind(this)} >Send Email</Button>
                                            <ReactToPrint
                                                trigger={() => { return <Button style={{ background: '#ff7f19', color: "white" }}  >Print</Button> }}
                                                content={() => this.printThermalSale}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                :
                                ""
                            }
                        </Row>
                    </div>
                }
            </Container>
        );
    }
}