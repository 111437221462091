import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, TableCell, TableRow, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import Checkbox from '@mui/material/Checkbox';
import { debounceFunc } from './../../../helper'
import PhoneClinicGrid from "../../../Components/PhoneClinicGrid";

class ItemCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            CategoryList: [],
            addCategory: false,
            ItemCategoryID_PK: "",
            ItemCategory: "",
            Active: "1",
            CATEGORY_SEARCH: ""
        }
    }

    componentDidMount() {
        let { CATEGORY_SEARCH, searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getCategoryList(CATEGORY_SEARCH, searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getCategoryList(CATEGORY_SEARCH = "", searchGridData = "", totalRecordsPerPage = 20, recordOffset = 1) {

        this.props.toggleLoader(true)
        let tempFormData = {
            ItemCategory: CATEGORY_SEARCH
        }
        let whereCondition = ""
        let isSearchEnable = false

        for (const property in tempFormData) {
            if (tempFormData[property] && tempFormData[property] !== "") {
                isSearchEnable = true
                whereCondition += `${property} LIKE "%${tempFormData[property]}%" AND `
            }
        }


        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }

        let formData = {
            whereCondition,
            totalRecordsPerPage,
            recordOffset,
        };

        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Setups/ItemCategory.php`, formData);
        let deviceData = getDevice.data
        let CategoryList = []
        let paginationLength = 1
        if (deviceData.success === 1) {
            CategoryList = deviceData.data
            paginationLength = parseInt(deviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ CategoryList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddCategory() {
        this.setState({ addCategory: true })
    }

    handleChangeInput(fieldName, event) {
        let targetValue = event.target.value
        // switch (fieldName) {
        //     case "DEVICE_TYPE":
        //         targetValue = event
        //         break;

        //     default:
        //         targetValue = event.target.value
        //         break;
        // }
        this.setState({ [fieldName]: targetValue })
    }

    handleExit() {
        this.setState({
            addCategory: false,
            ItemCategoryID_PK: "",
            ItemCategory: "",
            Active: "1",
        })
    }

    async handleSubmitDevice() {
        this.props.toggleLoader(true)
        let formData = {
            ItemCategoryID_PK: this.state.ItemCategoryID_PK,
            ItemCategory: this.state.ItemCategory,
            Active: this.state.Active,
        }
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Setups/ItemCategory.php`, formData);
        this.handleExit()
        this.getCategoryList("", "", 20, 1)
    }


    handleEdit(indx) {
        let { CategoryList } = this.state
        this.setState({
            ItemCategoryID_PK: CategoryList[indx]['ItemCategoryID_PK'],
            ItemCategory: CategoryList[indx]['ItemCategory'],
            Active: CategoryList[indx]['Active'],
            addCategory: true
        })
    }

    handleChangeSearch = debounceFunc((label, evt) => {
        this.setState({ [label]: evt.target.value }, () => {
            let {
                CATEGORY_SEARCH
            } = this.state;
            this.handleSearch(CATEGORY_SEARCH)
        })
    })

    handleSearch = (
        CATEGORY_SEARCH = "",
    ) => {
        this.getCategoryList(CATEGORY_SEARCH)
    }

    render() {
        return (
            <Container>
                {this.state.addCategory === false ?
                    <PhoneClinicGrid
                        title="CATEGORY"
                        buttionLabel="Add Category"
                        onClickButton={this.handleAddCategory.bind(this)}
                        disableDelete
                        handleChangeSearch={(key) => this.handleChangeSearch.bind(this, key)}
                        tableHeadData={[
                            {
                                label: "CATEGORY",
                                isHidden: false
                            },
                            {
                                label: "ACTIVE",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "CATEGORY_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "ACTIVE",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                    >
                        {this.state.CategoryList.map((device, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <TableCell align='center'>{device.ItemCategory}</TableCell>
                                <TableCell align='center'>
                                    <Checkbox style={{ padding: "0px" }} size="small" checked={device.Active === "1" ? true : false} disabled />
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} onClick={this.handleEdit.bind(this, idx)} aria-label="Edit Data" component="span">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </PhoneClinicGrid>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Add Category</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        value={this.state.ItemCategory}
                                        name={"ItemCategory"}
                                        onChange={this.handleChangeInput.bind(this, "ItemCategory")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_BRAND}
                                        name={"DEVICE_BRAND"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_BRAND")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_NAME}
                                        name={"DEVICE_NAME"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_NAME")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleExit.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitDevice.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default ItemCategory;