import React, { Component } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Col, Container, Row, Form, Modal } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
// import CreatableSelect from 'react-select/creatable';
import axios from "axios";
import Swal from "sweetalert2";
// import Alert from '@mui/material/Alert';
import RefreshIcon from '@mui/icons-material/Refresh';
import { debounceFunc } from './../../helper'
import ReactToPrint from 'react-to-print';
import Bardcode from '../../Components/JobForm/Bardcode';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import PhoneClinicGrid from '../../Components/PhoneClinicGrid';

class WarehouseInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: [
                {
                    key: "INV_ITEM_NAME",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                },
                {
                    key: "INV_CATEGORY",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "INV_BARCODE",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                }
            ],
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,



            showModal: false,
            InventoryList: [],
            ITEM_ID: "",
            ITEM_NAME: "",
            ITEM_CATEGORY: "",
            ITEM_DEVICE: "",
            ITEM_MODEL: "",
            ITEM_DESC: "",
            ITEM_BRAND: {},
            ITEM_BARCODE: "none",
            ITEM_BUY_RATE: "",
            ITEM_SELL_RATE: "",
            ITEM_QUANTITY: "",


            width: 1,
            height: 60,
            displayValue: true,
            text: "",
            fontOptions: "",
            font: "monospace",
            textAlign: "center",
            textPosition: "bottom",
            textMargin: 0,
            fontSize: 15,
            background: "#ffffff",
            lineColor: "#000000",
            margin: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,


            DeviceList: [],
            CategoryList: []

        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getInventory(searchGridData, totalRecordsPerPage.value, paginationValue);
    }

    async getInventory(searchGridData = [], totalRecordsPerPage = 20, recordOffset = 1) {

        let whereCondition = ""
        let isSearchEnable = false

        for (let i = 0; i < searchGridData.length; i++) {
            const eachData = searchGridData[i];
            if (eachData.value !== "") {
                isSearchEnable = true
                whereCondition += `${eachData.key} LIKE "%${eachData.value}%" AND `
            }

        }

        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }

        let formData = {
            PC_WH_ID: localStorage.getItem('PC_GRP_ATT'),
            whereCondition,
            totalRecordsPerPage,
            recordOffset
        };

        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetWHInventory.php`, formData);
        let serviceData = getAllInventory.data
        let InventoryList = []
        let paginationLength = 1

        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            paginationLength = parseInt(getAllInventory.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ InventoryList, paginationLength })
        // }
    }

    handleEdit(indx) {
        let { InventoryList } = this.state;
        let FilteredData = InventoryList[indx];
        this.setState({
            showModal: true,
            ITEM_ID: FilteredData.INV_ID,
            ITEM_NAME: FilteredData.INV_ITEM_NAME,
            ITEM_CATEGORY: { value: FilteredData.INV_CATEGORY, label: FilteredData.INV_CATEGORY },
            ITEM_DEVICE: { value: FilteredData.DEVICE_ID, label: FilteredData.DEVICE_ID },
            ITEM_MODEL: FilteredData.INV_MODEL,
            ITEM_DESC: FilteredData.INV_DESC,
            ITEM_BARCODE: FilteredData.INV_BARCODE,
            ITEM_BUY_RATE: parseFloat(FilteredData.PC_WH_ITEM_BUY_RATE).toFixed(2),
            ITEM_SELL_RATE: parseFloat(FilteredData.PC_WH_ITEM_SELL_RATE).toFixed(2),
            ITEM_QUANTITY: 0
        })
    }

    hanldeAddInventory() {
        this.setState({ showModal: true })
    }

    handleChange(name, evt) {
        switch (name) {
            case "ITEM_CATEGORY":
            case "ITEM_DEVICE":
            case "ITEM_BRAND":
                this.setState({ [name]: evt })
                break;

            default:
                this.setState({ [name]: evt.target.value })
                break;
        }
    }

    handleValidate() {
        if (this.state.ITEM_NAME === "") {
            Swal.fire(
                'Error',
                "Enter Item Name",
                'error'
            )
            return false
        }
        // if (this.state.ITEM_MODEL === "") {
        //     Swal.fire(
        //         'Error',
        //         "Enter Item Model",
        //         'error'
        //     )
        //     return false
        // }
        if (this.state.ITEM_BARCODE === "") {
            Swal.fire(
                'Error',
                "Enter Item Barcode",
                'error'
            )
            return false
        }

        return true
    }

    async handleSave() {
        if (!this.handleValidate()) return

        let FormData = {
            ITEM_ID: this.state.ITEM_ID,
            ITEM_BUY_RATE: this.state.ITEM_BUY_RATE || "0",
            ITEM_SELL_RATE: this.state.ITEM_SELL_RATE || "0",
            ITEM_QUANTITY: this.state.ITEM_QUANTITY || "0",
            ITEM_WH_ID: localStorage.getItem('PC_GRP_ATT')
        }

        try {
            let ReturnDataResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddWarehouseInventory.php`, FormData);
            if (ReturnDataResult.data.status === 200) {
                Swal.fire(
                    'Success',
                    ReturnDataResult.data.message,
                    'success'
                )
            } else {
                Swal.fire(
                    'Error',
                    ReturnDataResult.data.message,
                    'error'
                )
            }

            this.handleCancel()

        } catch (error) {
            console.error('error', error);
        }

    }

    handleCancel() {
        let { totalRecordsPerPage } = this.state
        this.getInventory([], totalRecordsPerPage.value, 1)
        this.setState({
            showModal: false,
            ITEM_ID: "",
            ITEM_NAME: "",
            ITEM_CATEGORY: "",
            ITEM_DEVICE: "",
            ITEM_MODEL: "",
            ITEM_DESC: "",
            ITEM_BRAND: {},
            ITEM_BARCODE: "none",
            ITEM_BUY_RATE: "",
            ITEM_SELL_RATE: "",
            ITEM_QUANTITY: "",
            searchGridData: [
                {
                    key: "INV_ITEM_NAME",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                },
                {
                    key: "INV_CATEGORY",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "INV_BARCODE",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                },
                {
                    key: "SHOP_SEARCH",
                    value: "",
                    isHidden: false,
                    isDisable: true
                }
            ]
        })
    }


    handleChangeSearch = debounceFunc((indx, evt) => {
        let { searchGridData } = this.state;
        searchGridData[indx]["value"] = evt.target.value
        this.setState({ searchGridData }, () => {
            let { searchGridData } = this.state;
            this.handleSearch(searchGridData)
        })
    })

    handleSearch = (searchGridData) => {
        this.getInventory(searchGridData)
    }

    render() {
        return (
            <Container>
                <PhoneClinicGrid
                    title="ITEM"
                    buttionLabel=""
                    onClickButton={() => { }}
                    handleChangeSearch={(key, indx) => this.handleChangeSearch.bind(this, indx)}
                    tableHeadData={[
                        {
                            label: "ITEM",
                            isHidden: false
                        },
                        {
                            label: "BRAND",
                            isHidden: false
                        },
                        {
                            label: "CATEGORY",
                            isHidden: false
                        },
                        {
                            label: "BARCODE",
                            isHidden: false
                        },
                        {
                            label: "BUY RATE",
                            isHidden: false
                        },
                        {
                            label: "SALE RATE",
                            isHidden: false
                        },
                        {
                            label: "QUANTITY",
                            isHidden: false
                        },
                    ]
                    }
                    searchData={this.state.searchGridData}
                >

                    {this.state.InventoryList.map((service, idx) => (
                        <TableRow
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <TableCell align='center' >{service.INV_ITEM_NAME}</TableCell>
                            <TableCell align='center' >{service.DEVICE_ID}</TableCell>
                            <TableCell align='center' >{service.INV_CATEGORY}</TableCell>
                            <TableCell align='center' >{service.INV_BARCODE}</TableCell>
                            <TableCell align='center' >{parseFloat(service.PC_WH_ITEM_BUY_RATE).toFixed(2)}</TableCell>
                            <TableCell align='center' >{parseFloat(service.PC_WH_ITEM_SELL_RATE).toFixed(2)}</TableCell>
                            <TableCell align='center' >{service.PC_WH_ITEM_QUANTITY}</TableCell>
                            <TableCell align='center'>
                                <IconButton style={{ padding: "0px" }} aria-label="Edit Data" component="span" onClick={this.handleEdit.bind(this, idx)}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align='center'>
                                <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={() => { }} >
                                    <RemoveIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </PhoneClinicGrid>

                <Modal
                    show={this.state.showModal}
                    onHide={() => { this.setState({ showModal: false }) }}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Inventory
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Item</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        name="ITEM_NAME"
                                        disabled
                                        value={this.state.ITEM_NAME}
                                        onChange={this.handleChange.bind(this, 'ITEM_NAME')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Brand</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Select
                                        // menuPlacement="auto"
                                        isDisabled
                                        style={{ height: '32px' }}
                                        name="ITEM_DEVICE"
                                        value={this.state.ITEM_DEVICE}
                                        options={[]}
                                        onChange={this.handleChange.bind(this, 'ITEM_DEVICE')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Category</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Select
                                        // menuPlacement="auto"
                                        isDisabled
                                        style={{ height: '32px' }}
                                        name="ITEM_CATEGORY"
                                        value={this.state.ITEM_CATEGORY}
                                        options={[]}
                                        onChange={this.handleChange.bind(this, 'ITEM_CATEGORY')}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Model</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ height: '32px' }}
                                        disabled
                                        name="ITEM_MODEL"
                                        value={this.state.ITEM_MODEL}
                                        onChange={this.handleChange.bind(this, 'ITEM_MODEL')}
                                    />
                                </Form.Group>
                            </Col> */}
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Barcode</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        disabled
                                        style={{ padding: "0.25rem" }}
                                        name="ITEM_BARCODE"
                                        value={this.state.ITEM_BARCODE}
                                        onChange={this.handleChange.bind(this, 'ITEM_BARCODE')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Buying Rate</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        name="ITEM_BUY_RATE"
                                        value={this.state.ITEM_BUY_RATE}
                                        onChange={this.handleChange.bind(this, 'ITEM_BUY_RATE')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Selling Rate</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"number"}
                                        name="ITEM_SELL_RATE"
                                        value={this.state.ITEM_SELL_RATE}
                                        onChange={this.handleChange.bind(this, 'ITEM_SELL_RATE')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Quantity</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"number"}
                                        name="ITEM_QUANTITY"
                                        value={this.state.ITEM_QUANTITY}
                                        onChange={this.handleChange.bind(this, 'ITEM_QUANTITY')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        disabled
                                        type={"textarea"}
                                        name="ITEM_DESC"
                                        value={this.state.ITEM_DESC}
                                        onChange={this.handleChange.bind(this, 'ITEM_DESC')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {/* <Form.Label>Barcode IMEI</Form.Label> */}
                                <div ref={el => (this.printLabel = el)} >
                                    <Bardcode
                                        value={this.state.ITEM_BARCODE}
                                        width={this.state.width}
                                        height={this.state.height}
                                        displayValue={this.state.displayValue}
                                        text={this.state.text}
                                        fontOptions={this.state.fontOptions}
                                        font={this.state.font}
                                        textAlign={this.state.textAlign}
                                        textPosition={this.state.textPosition}
                                        textMargin={this.state.textMargin}
                                        fontSize={this.state.fontSize}
                                        background={this.state.background}
                                        lineColor={this.state.lineColor}
                                        margin={this.state.margin}
                                        marginTop={this.state.marginTop}
                                        marginBottom={this.state.marginBottom}
                                        marginLeft={this.state.marginLeft}
                                        marginRight={this.state.marginRight}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactToPrint
                            trigger={() => { return <Button>Print Barcode</Button>; }}
                            content={() => this.printLabel}
                        />
                        <Button onClick={this.handleCancel.bind(this)}>Cancel</Button>
                        <Button onClick={this.handleSave.bind(this)} >Save</Button>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default WarehouseInventory;