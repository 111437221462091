import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col, Image } from "react-bootstrap"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import RefreshIcon from '@mui/icons-material/Refresh';
// import JobForm from "../../../../components/JobForm";
// import NoRecordFound from '../../../../assets/images/norecordfound.png';
import { debounceFunc } from './../../../../helper'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
import JobForm from "../../../../Components/JobForm";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import PhoneClinicGrid from "../../../../Components/PhoneClinicGrid";


function isJson(string) {
    try {
        JSON.parse(string); // Attempt to parse the string
        return true; // If successful, it's valid JSON
    } catch (e) {
        return false; // If an error occurs, it's not valid JSON
    }
}

const searchData = [
    {
        key: "PC_REP_BARCODE",
        value: "",
        isHidden: false,
        isDisable: false
    },
    {
        key: "SHOP_SEARCH",
        value: "",
        isHidden: false,
        isDisable: true
    },
    {
        key: "CASH_SEARCH",
        value: "",
        isHidden: false,
        isDisable: true
    },
    {
        key: "PC_REP_IMSR",
        value: "",
        isHidden: false,
        isDisable: false
    },
    {
        key: "PC_CUST_FR_NAME",
        value: "",
        isHidden: false,
        isDisable: false
    },
    {
        key: "PC_CUST_EMAIL",
        value: "",
        isHidden: false,
        isDisable: false
    },
    {
        key: "CARD_SEARCH",
        value: "",
        isHidden: false,
        isDisable: true
    },
    {
        key: "OTHERS_SEARCH",
        value: "",
        isHidden: false,
        isDisable: true
    }
]

class RefundJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: JSON.parse(JSON.stringify(searchData)),
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,




            CompletedList: [],
            filteredListData: {},
            openModal: false,
            eligibleFor: 'Refund'
        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getShopCompRequest(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getShopCompRequest(searchGridData = [], totalRecordsPerPage = 20, recordOffset = 1) {


        let whereCondition = ""
        let isSearchEnable = false

        for (let i = 0; i < searchGridData.length; i++) {
            const eachData = searchGridData[i];
            if (eachData.value !== "") {
                isSearchEnable = true
                whereCondition += `${eachData.key} LIKE "%${eachData.value}%" AND `
            }
        }

        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }

        let formData = {
            PC_SHOP: localStorage.getItem("PC_GRP_ATT"),
            whereCondition,
            totalRecordsPerPage,
            recordOffset
        };


        await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getRefundJobs.php`, formData).then((response) => {
            let myRecord = []
            let paginationLength = 1
            if (response.data.success === 1) {
                let JobRecord = response.data.data
                for (let i = 0; i < JobRecord.length; i++) {
                    const myEle = JobRecord[i]
                    myEle.PC_REP_BRAND = isJson(myEle.PC_REP_BRAND) ? JSON.parse(myEle.PC_REP_BRAND) : myEle.PC_REP_BRAND;
                    myEle.PC_REP_MODEL = isJson(myEle.PC_REP_MODEL) ? JSON.parse(myEle.PC_REP_MODEL) : myEle.PC_REP_MODEL;
                    myEle.PC_REP_LIST = isJson(myEle.PC_REP_LIST) ? JSON.parse(myEle.PC_REP_LIST) : myEle.PC_REP_LIST;
                    myEle.PC_REP_TYPE = isJson(myEle.PC_REP_TYPE) ? JSON.parse(myEle.PC_REP_TYPE) : myEle.PC_REP_TYPE;
                    myEle.PC_REP_NOTES = isJson(myEle.PC_REP_NOTES) ? JSON.parse(myEle.PC_REP_NOTES) : myEle.PC_REP_NOTES;
                    myRecord.push(myEle)
                }
                paginationLength = parseInt(response.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
            }
            this.setState({ CompletedList: myRecord, paginationLength })
        }).catch((error) => {
            console.error('error', error);
            this.setState({ CompletedList: [], paginationLength: 1 })
        });
    }

    async handleClose() {
        let { totalRecordsPerPage } = this.state
        await this.getShopCompRequest([], totalRecordsPerPage.value, 1)
        this.setState({ openModal: false, searchGridData: JSON.parse(JSON.stringify(searchData)) })
    }

    handleEdit(indx) {
        let { CompletedList } = this.state;
        let filteredListData = CompletedList[indx]
        this.setState({ filteredListData, openModal: true })
    }

    handleChangeSearch = debounceFunc((indx, evt) => {
        let { searchGridData } = this.state;
        searchGridData[indx]["value"] = evt.target.value
        this.setState({ searchGridData }, () => {
            let { searchGridData } = this.state;
            this.handleSearch(searchGridData)
        })
    })

    handleSearch = (searchGridData) => {
        this.getShopCompRequest(searchGridData)
    }

    render() {
        return (
            <div>
                <PhoneClinicGrid
                    title="REFUND"
                    buttionLabel=""
                    onClickButton={() => { }}
                    disableDelete
                    handleChangeSearch={(key, indx) => this.handleChangeSearch.bind(this, indx)}
                    tableHeadData={[
                        {
                            label: "BARCODE",
                            isHidden: false
                        },
                        {
                            label: "DATE",
                            isHidden: false
                        },
                        {
                            label: "MODEL",
                            isHidden: false
                        },
                        {
                            label: "IMEI / SERIAL.NO",
                            isHidden: false
                        },
                        {
                            label: "CUSTOMER NAME",
                            isHidden: false
                        },
                        {
                            label: "CUSTOMER EMAIL",
                            isHidden: false
                        },
                        {
                            label: "TYPE",
                            isHidden: false
                        },
                        {
                            label: "STATUS",
                            isHidden: false
                        },
                    ]}
                    searchData={this.state.searchGridData}
                >
                    {this.state.CompletedList.map((list, idx) => {
                        return (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <TableCell align='center' >{list.PC_REP_BARCODE}</TableCell>
                                <TableCell align='center' >{list.PC_REP_APP_DATE}</TableCell>
                                <TableCell align='center' >{list.PC_REP_BRAND.value}</TableCell>
                                <TableCell align='center' >{list.PC_REP_IMSR}</TableCell>
                                <TableCell align='center' >{list.PC_CUST_FR_NAME}</TableCell>
                                <TableCell align='center' >{list.PC_CUST_EMAIL}</TableCell>
                                <TableCell align='center' >{list.PC_REP_DATA_TYPE}</TableCell>
                                <TableCell align='center' >{list.PC_REP_STATUS}</TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} aria-label="Edit Data" component="span" onClick={this.handleEdit.bind(this, idx)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })}

                </PhoneClinicGrid>

                <JobForm
                    openModal={this.state.openModal}
                    closeModal={this.handleClose.bind(this)}
                    eligibleFor={this.state.eligibleFor}
                    jobData={this.state.filteredListData}
                />
            </div>
        );
    }
}

export default RefundJob;