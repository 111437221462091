import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import CreatableSelect from 'react-select/creatable';
import axios from "axios";
// import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import MenuItem from '@mui/material/MenuItem';
// import Checkbox from '@mui/material/Checkbox';
// import RemoveIcon from '@mui/icons-material/Delete';
import { debounceFunc } from './../../helper'
import Select from 'react-select'
import PhoneClinicGrid from "../../Components/PhoneClinicGrid";

class CommonDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            DeviceList: [],
            addDevice: false,
            deviceTypeList: [
                { value: 'Phone', label: 'Phone' },
                { value: 'Tablet', label: 'Tablet' },
                { value: 'Laptop', label: 'Laptop' },
                { value: 'Gaming Console', label: 'Gaming Console' }
            ],
            DEVICE_ID: "",
            DEVICE_TYPE: "",
            DEVICE_BRAND: "",
            DEVICE_NAME: ""
        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getDeviceList(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getDeviceList(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDevice.php`, { searchGridData, totalRecordsPerPage, recordOffset });
        let deviceData = getDevice.data
        let DeviceList = []
        let paginationLength = 1
        if (deviceData.success === 1) {
            DeviceList = deviceData.data
            paginationLength = parseInt(deviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ DeviceList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddDevice() {
        this.setState({ addDevice: true })
    }

    handleChangeInput(fieldName, event) {
        let targetValue
        switch (fieldName) {
            case "DEVICE_TYPE":
                targetValue = event
                break;

            default:
                targetValue = event.target.value
                break;
        }
        this.setState({ [fieldName]: targetValue })
    }

    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getDeviceList(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getDeviceList(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getDeviceList(searchGridData, totalRecordsPerPage.value, value)
    }


    handleCancelDevice() {
        this.setState({
            DEVICE_ID: "",
            addDevice: false,
            DEVICE_TYPE: "",
            DEVICE_BRAND: "",
            DEVICE_NAME: ""
        })
    }

    async handleSubmitDevice() {
        this.props.toggleLoader(true)
        let formData = {
            DEVICE_ID: this.state.DEVICE_ID,
            DEVICE_TYPE: this.state.DEVICE_TYPE?.value,
            DEVICE_BRAND: this.state.DEVICE_BRAND,
            DEVICE_NAME: this.state.DEVICE_NAME
        }
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddDevice.php`, formData);
        this.handleCancelDevice()
        this.getDeviceList("", 20, 1)
    }


    handleEdit(indx) {
        let { DeviceList } = this.state
        this.setState({
            DEVICE_ID: DeviceList[indx]['DEVICE_ID'],
            DEVICE_TYPE: { label: DeviceList[indx]['DEVICE_TYPE'], value: DeviceList[indx]['DEVICE_TYPE'] },
            DEVICE_BRAND: DeviceList[indx]['DEVICE_BRAND'],
            DEVICE_NAME: DeviceList[indx]['DEVICE_NAME'],
            addDevice: true
        })
    }

    render() {
        return (
            <Container>
                {this.state.addDevice === false ?
                    <PhoneClinicGrid
                        title="DEVICE"
                        buttionLabel="Add Device"
                        onClickButton={this.handleAddDevice.bind(this)}
                        handleChangeSearch={(key) => { }}
                        tableHeadData={[
                            {
                                label: "DEVICE",
                                isHidden: false
                            },
                            {
                                label: "BRAND",
                                isHidden: false
                            },
                            {
                                label: "MODEL",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                    >
                        {this.state.DeviceList.map((device, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <TableCell align='center' >{device.DEVICE_TYPE}</TableCell>
                                <TableCell align='center' >{device.DEVICE_BRAND}</TableCell>
                                <TableCell align='center' >{device.DEVICE_NAME}</TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} onClick={this.handleEdit.bind(this, idx)} aria-label="Edit Data" component="span">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={() => { }} >
                                        <RemoveIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </PhoneClinicGrid>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Add New Device</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Device</Form.Label>
                                    <CreatableSelect
                                        name={"DEVICE_TYPE"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        options={this.state.deviceTypeList}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_BRAND}
                                        name={"DEVICE_BRAND"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_BRAND")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control
                                        value={this.state.DEVICE_NAME}
                                        name={"DEVICE_NAME"}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_NAME")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelDevice.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitDevice.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default CommonDevices;