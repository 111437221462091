import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ErrorPage from '../Pages/Error';

import { ProtectedRoutes, PublicRoutes, } from './VerifyRoutes';
import { StoresLayout, DeviceRepair } from "./withRoutes";
// Layout
import AdminLayout from "../Layout/AdminLayout";
import ClientLayout from '../Layout/ClientLayout';

// Client
import HomePage from '../client/pages/home';
import About from '../client/pages/About';
import ServicePage from '../client/pages/service';
import Contact from "../client/pages/contact";


// Public Page
import Login from "../Pages/admin/Login";

// Private Page
import Dashboard from "../Pages/admin/Dashboard";
import ItemCategory from "../Pages/admin/Setup/ItemCategory";
import BrandSetup from "../Pages/admin/Setup/BrandSetup";
import CommonInventory from "../Pages/admin/CommonInventory";
import CommonUsers from "../Pages/admin/CommonUsers";
import CommonDevices from "../Pages/admin/CommonDevices";
import CommonServices from "../Pages/admin/CommonServices";
import CommonShops from '../Pages/admin/CommonShops';
import CommonPayAccounts from "../Pages/admin/CommonPayAccounts";
import CommonWarehouse from "../Pages/admin/CommonWarehouse";
import WeeklySale from "../Pages/admin/Accounts/WeeklySale";
// import WeeklyDelivery from "../Pages/admin/WeeklyDelivery";
import ShopPayments from "../Pages/admin/Accounts/ShopPayments";
import AccountPayments from "../Pages/admin/Accounts/AccountPayments";
import ProfitAndLoss from "../Report/ProfitAndLoss";
import AccountBalance from "../Report/AccountBalance";


// Shop
// import Users from "../Pages/admin/Users";
import JobServices from "../Pages/admin/JobServices";
import VendorSetup from "../Pages/admin/VendorSetup";
import ShopJob from "../Pages/admin/ShopJob";
import SaleInvoice from "../Pages/admin/Sale/SaleInvoice";
import Devices from "../Pages/admin/Devices";
import Items from "../Pages/admin/Items";
import PurchaseDevice from "../Pages/admin/Purchase/PurchaseDevice";
import SaleDevice from "../Pages/admin/Sale/SaleDevice";
import RefundDevice from "../Pages/admin/RefundDevice";
import InventoryReport from "../Report/InventoryReport";
import GetItemRate from "../Report/GetItemRate";


// Warehouse
import WarehouseDevice from "../Pages/admin/WarehouseDevice";
import WarehouseInventory from "../Pages/admin/WarehouseInventory";
import WarehouseDeviceSale from "../Pages/admin/Sale/WarehouseDeviceSale";
import WarehouseItemSale from "../Pages/admin/Sale/WarehouseItemSale";
import WarehousePurchaseDevice from "../Pages/admin/Purchase/WarehousePurchaseDevice";
import WarehouseRefundDevice from "../Pages/admin/WarehouseRefundDevice";


import Loader from "../helper/Loader";
import ToastAlert from '../helper/ToastAlert';

export default function AppRoute() {
    const [showLoader, setLoader] = React.useState(false);
    const [toastObj, setToastObj] = React.useState(
        {
            varient: "",
            title: "",
            message: "",
            showAlertProp: false
        }
    );

    const router = createBrowserRouter([
        {
            path: "/",
            element: <PublicRoutes />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "/",
                    element: <ClientLayout><HomePage toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></ClientLayout>
                },
                {
                    path: "/about-us",
                    element: <ClientLayout><About toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></ClientLayout>
                },
                {
                    path: "/services",
                    element: <ClientLayout><ServicePage toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></ClientLayout>
                },
                {
                    path: "/contact-us",
                    element: <ClientLayout><Contact toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></ClientLayout>
                },
                {
                    path: "/stores/:id",
                    element: <ClientLayout><StoresLayout toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></ClientLayout>
                },
                {
                    path: "/repair/:device-type",
                    element: <ClientLayout><DeviceRepair toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></ClientLayout>
                },
                {
                    path: "/admin",
                    element: <Login toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} />,
                }
            ]
        },
        {
            // for Super Admin
            element: <ProtectedRoutes />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "/admin/dashboard",
                    element: <AdminLayout><Dashboard toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/item-category",
                    element: <AdminLayout><ItemCategory toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/brand-setup",
                    element: <AdminLayout><BrandSetup toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/system-inventory",
                    element: <AdminLayout><CommonInventory toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/system-users",
                    element: <AdminLayout><CommonUsers toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/system-devices",
                    element: <AdminLayout><CommonDevices toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/system-services",
                    element: <AdminLayout><CommonServices toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/system-shop",
                    element: <AdminLayout><CommonShops toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/system-pay-account",
                    element: <AdminLayout><CommonPayAccounts toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/system-warehouse",
                    element: <AdminLayout><CommonWarehouse toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/weekly-sale",
                    element: <AdminLayout><WeeklySale toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                // {
                //     path: "/admin/weekly-delivery",
                //     element: <AdminLayout><WeeklyDelivery toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                // },
                {
                    path: "/admin/shop-payments",
                    element: <AdminLayout><ShopPayments toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/account-payments",
                    element: <AdminLayout><AccountPayments toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/report/profit-loss",
                    element: <AdminLayout><ProfitAndLoss toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/report/account-balance",
                    element: <AdminLayout><AccountBalance toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                //     ],
                // },
                // {
                //     // for Shop Owner Only
                //     // element: <ProtectedRoutes />,
                //     errorElement: <ErrorPage />,
                //     children: [
                {
                    path: "/admin/dashboard",
                    element: <AdminLayout><Dashboard toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                // {
                //     path: "/admin/user",
                //     element: <AdminLayout><Users toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                // },
                {
                    path: "/admin/job-services",
                    element: <AdminLayout><JobServices toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/vendor-setup",
                    element: <AdminLayout><VendorSetup toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                // {
                //     path: "/admin/shop-payments",
                //     element: <AdminLayout><ShopPayments toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                // },
                // {
                //     path: "/admin/weekly-sale",
                //     element: <AdminLayout><WeeklySale toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                // },
                {
                    path: "/admin/job",
                    element: <AdminLayout><ShopJob toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/sale-invoice",
                    element: <AdminLayout><SaleInvoice toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/devices",
                    element: <AdminLayout><Devices toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/items",
                    element: <AdminLayout><Items toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/purchase-device",
                    element: <AdminLayout><PurchaseDevice toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/sale-device",
                    element: <AdminLayout><SaleDevice toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/inventory-report",
                    element: <AdminLayout><InventoryReport toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/refund-device",
                    element: <AdminLayout><RefundDevice toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/get-item-rates",
                    element: <AdminLayout><GetItemRate toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                //     ]
                // },
                // {
                //     // for Warehouse Owner Only
                //     // element: <ProtectedRoutes />,
                //     errorElement: <ErrorPage />,
                //     children: [
                {
                    path: "/admin/dashboard",
                    element: <AdminLayout><Dashboard toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/vendor-setup",
                    element: <AdminLayout><VendorSetup toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/warehouse-device",
                    element: <AdminLayout><WarehouseDevice toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/warehouse-inventory",
                    element: <AdminLayout><WarehouseInventory toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/sale-device-wh",
                    element: <AdminLayout><WarehouseDeviceSale toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/sale-item-wh",
                    element: <AdminLayout><WarehouseItemSale toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/purchase-device-wh",
                    element: <AdminLayout><WarehousePurchaseDevice toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/refund-device-wh",
                    element: <AdminLayout><WarehouseRefundDevice toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                //     ]
                // },
                // {
                //     // for Account Payers Only
                //     // element: <ProtectedRoutes />,
                //     errorElement: <ErrorPage />,
                //     children: [
                {
                    path: "/admin/dashboard",
                    element: <AdminLayout><Dashboard toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/admin/account-payments",
                    element: <AdminLayout><AccountPayments toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/report/profit-loss",
                    element: <AdminLayout><ProfitAndLoss toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                },
                {
                    path: "/report/account-balance",
                    element: <AdminLayout><AccountBalance toggleLoader={(props) => setLoader(props)} toggleSnackBar={(...props) => { handleChangeToast(...props) }} /></AdminLayout>,
                }
            ]
        },

    ], { basename: "/" },
    );


    const handleChangeToast = (showAlertProp = false, title = "", message = "", varient = "") => {
        setToastObj(prevState => ({
            ...prevState,
            varient,
            title,
            message,
            showAlertProp
        }))
    }

    return (
        <>
            {showLoader && <Loader />}
            <RouterProvider router={router} />
            <ToastAlert
                setAlert={() => handleChangeToast(false)}
                varient={toastObj.varient}
                title={toastObj.title}
                message={toastObj.message}
                showAlertProp={toastObj.showAlertProp}
            />
        </>
    )
}