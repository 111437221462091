import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Tabs, Tab } from "@mui/material";
// import CreatableSelect from 'react-select/creatable';
import { Add } from '@mui/icons-material';
import axios from "axios";
// import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import MenuItem from '@mui/material/MenuItem';
// import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import { debounceFunc } from './../../helper'
import Select from 'react-select'
import Swal from "sweetalert2";
import PhoneClinicGrid from "../../Components/PhoneClinicGrid";

class CommonPayAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            PayAccountList: [],

            addPayAccount: false,
            isEdit: false,
            ACCOUNT_ID: "",
            ACCOUNT_TITLE: "",
            ACCOUNT_EMAIL: "",
            ACCOUNT_PASSWORD: "",
            OPENING_BALANCE: "",
            OPENING_DATE: "",

            tabValue: 0,
            ShopList: [],
            AllShopData: [],
            ShopPartnerDetail: [],
            ShopCardDetail: []

        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getDeviceList(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getDeviceList(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetPayAccount.php`, { searchGridData, totalRecordsPerPage, recordOffset });

        let deviceData = getDevice.data
        let PayAccountList = []
        let paginationLength = 1
        if (deviceData.success === 1) {
            PayAccountList = deviceData.data
            paginationLength = parseInt(deviceData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ PayAccountList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddPayAccount() {
        this.setState({ addPayAccount: true, isEdit: false })
    }

    handleChangeInput(fieldName, event) {
        let targetValue = event.target.value
        // switch (fieldName) {
        //     default:
        //         targetValue = event.target.value
        //         break;
        // }
        this.setState({ [fieldName]: targetValue })
    }

    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getDeviceList(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getDeviceList(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getDeviceList(searchGridData, totalRecordsPerPage.value, value)
    }


    handleCancelPayAccount() {
        this.setState({
            addPayAccount: false,
            isEdit: false,
            ACCOUNT_ID: "",
            ACCOUNT_TITLE: "",
            ACCOUNT_EMAIL: "",
            ACCOUNT_PASSWORD: "",
            OPENING_BALANCE: "",
            OPENING_DATE: "",
            ShopPartnerDetail: [],
            ShopCardDetail: [],
        })
    }

    async handleSubmitPayAccount() {
        this.props.toggleLoader(true)
        let formData = {
            ACCOUNT_ID: this.state.ACCOUNT_ID,
            ACCOUNT_TITLE: this.state.ACCOUNT_TITLE,
            OPENING_BALANCE: this.state.OPENING_BALANCE,
            OPENING_DATE: this.state.OPENING_DATE,
            ShopPartnerDetail: JSON.stringify(this.state.ShopPartnerDetail),
            ShopCardDetail: JSON.stringify(this.state.ShopCardDetail),
            PC_USR_EMAIL: "",
            PC_USR_PASS_DECRYPT: "",
            PC_GRP_ID: "4",
            PC_USR_ROLE_ID: "2",
        }

        // let SP_WORKER_LIST = []
        // SP_WORKER_LIST.push({
        //     PC_USR_NAME: this.state.ACCOUNT_TITLE,
        //     PC_USR_EMAIL: this.state.ACCOUNT_EMAIL,
        //     PC_USR_PASS_DECRYPT: this.state.ACCOUNT_PASSWORD,
        //     PC_GRP_ID: "4",
        //     PC_USR_ROLE_ID: "2",
        //     PC_GRP_ATT: this.state.ACCOUNT_ID
        // })
        // :: TODO
        // await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddUser.php`, SP_WORKER_LIST);
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddPayAccount.php`, formData);
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/linking/PayAccountUpdate.php`, { "ACC_ID": this.state.ACCOUNT_ID });
        this.handleCancelPayAccount()
        this.getDeviceList("", 20, 1)
    }


    async handleEdit(indx) {
        await this.getShopList();

        let { PayAccountList } = this.state
        this.setState({
            addPayAccount: true,
            isEdit: true,
            ACCOUNT_ID: PayAccountList[indx]['ACCOUNT_ID'],
            ACCOUNT_TITLE: PayAccountList[indx]['ACCOUNT_TITLE'],
            ACCOUNT_EMAIL: PayAccountList[indx]['PC_USR_EMAIL'],
            ACCOUNT_PASSWORD: PayAccountList[indx]['PC_USR_PASS_DECRYPT'],
            OPENING_BALANCE: PayAccountList[indx]['OPENING_BALANCE'],
            OPENING_DATE: PayAccountList[indx]['OPENING_DATE'],
            ShopPartnerDetail: PayAccountList[indx]['ShopPartnerDetail'] ? JSON.parse(PayAccountList[indx]['ShopPartnerDetail']) : [],
            ShopCardDetail: PayAccountList[indx]['ShopCardDetail'] ? JSON.parse(PayAccountList[indx]['ShopCardDetail']) : []
        })
    }

    async getShopList() {
        this.props.toggleLoader(true)
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                ShopList.push({
                    value: shop.PC_SP_ID,
                    label: shop.SP_NAME
                })
            }
        }
        this.setState({ ShopList, AllShopData: shopData.data })
        this.props.toggleLoader(false)
    }

    handleTabChange(evt, val) {
        // if (val === 1) {
        //     await this.getUserList()
        // }
        this.setState({ tabValue: val })
    }

    handleChangeShopPartner(index, fieldName, evt) {
        let { ShopPartnerDetail } = this.state;
        let value;
        if (fieldName === 'ShopName') {
            value = evt;
        } else {
            value = evt.target.value;
        }
        ShopPartnerDetail[index][fieldName] = value
        this.setState({ ShopPartnerDetail })
    }

    handleChangeShopCard(index, fieldName, evt) {
        let { ShopCardDetail } = this.state;
        let value;
        if (fieldName === 'ShopName') {
            value = evt;
        } else {
            value = evt.target.value;
        }
        ShopCardDetail[index][fieldName] = value
        this.setState({ ShopCardDetail })
    }

    handleAddTableItem() {
        let { ShopPartnerDetail } = this.state;
        ShopPartnerDetail.push({
            ShopName: "",
            Percentage: "",
            FromDate: "",
            ToDate: ""
        })
        this.setState({ ShopPartnerDetail })
    }

    handleAddTableItemCard() {
        let { ShopCardDetail } = this.state;
        ShopCardDetail.push({
            ShopName: "",
            FromDate: "",
            ToDate: ""
        })
        this.setState({ ShopCardDetail })
    }

    handleDelete(ACCOUNT_ID) {
        Swal.fire({
            title: 'Do you want to delete account ?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/delete/setup/DeletePayAccount.php`, { ACCOUNT_ID });
                let shopData = getShops.data
                if (shopData.success === 1 && shopData.message === "You have successfully delete track.") {
                    Swal.fire('Account Delete!', '', 'success')
                    let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
                    this.getDeviceList(searchGridData, totalRecordsPerPage.value, paginationValue)
                }
            }
        })
    }

    render() {
        return (
            <Container>
                {this.state.addPayAccount === false ?
                    <PhoneClinicGrid
                        title="PAY ACCOUNT"
                        buttionLabel="Add Pay Account"
                        onClickButton={this.handleAddPayAccount.bind(this)}
                        handleChangeSearch={(key) => { }}
                        tableHeadData={[
                            {
                                label: "ACCOUNT TITLE",
                                isHidden: false
                            },
                            {
                                label: "BALANCE",
                                isHidden: false
                            },
                            {
                                label: "OPENING DATE",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                    >
                        {this.state.PayAccountList.map((device, idx) => (
                            <TableRow
                                className="table-rows"
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align='center' >{device.ACCOUNT_TITLE}</TableCell>
                                <TableCell align='center' scope="row" >{device.OPENING_BALANCE}</TableCell>
                                <TableCell align='center' scope="row" >{device.OPENING_DATE}</TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} onClick={this.handleEdit.bind(this, idx)} aria-label="Edit Data" component="span">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.handleDelete.bind(this, device.ACCOUNT_ID)} >
                                        <RemoveIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </PhoneClinicGrid>
                    :
                    <>
                        <Row>
                            <Col>
                                <h4>Add New Pay Account</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Account Title</Form.Label>
                                    <Form.Control
                                        value={this.state.ACCOUNT_TITLE}
                                        name={"ACCOUNT_TITLE"}
                                        onChange={this.handleChangeInput.bind(this, "ACCOUNT_TITLE")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control
                                        value={this.state.OPENING_BALANCE}
                                        name={"OPENING_BALANCE"}
                                        onChange={this.handleChangeInput.bind(this, "OPENING_BALANCE")}
                                        style={{ padding: "0.25rem" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        value={this.state.OPENING_DATE}
                                        name={"OPENING_DATE"}
                                        type="date"
                                        onChange={this.handleChangeInput.bind(this, "OPENING_DATE")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        value={this.state.ACCOUNT_EMAIL}
                                        name={"ACCOUNT_EMAIL"}
                                        type="email"
                                        autoComplete={"new-email"}
                                        onChange={this.handleChangeInput.bind(this, "ACCOUNT_EMAIL")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        value={this.state.ACCOUNT_PASSWORD}
                                        name={"ACCOUNT_PASSWORD"}
                                        type="password"
                                        autoComplete={"new-password"}
                                        onChange={this.handleChangeInput.bind(this, "ACCOUNT_PASSWORD")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelPayAccount.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitPayAccount.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
                {this.state.isEdit === true ?
                    (
                        <div>
                            <Tabs value={this.state.tabValue} onChange={this.handleTabChange.bind(this)} aria-label="basic tabs example">
                                <Tab label="Shop Partner" />
                                <Tab label="Shop Card" />
                            </Tabs>
                            {this.state.tabValue === 0 ?
                                <Row style={{
                                    margin: "5px",
                                    height: "75vh",
                                    overflow: "auto",
                                    padding: '10px'
                                }} >
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: '70vh' }} style={{ height: '100%' }} >
                                            <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Percentage</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >From</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >To</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                            <div onClick={this.handleAddTableItem.bind(this)} >
                                                                <Add className="HoverButtonAdd" />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.ShopPartnerDetail.map((device, idx) => (
                                                        <TableRow
                                                            className="table-rows"
                                                            key={idx}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" style={{ cursor: 'pointer' }} >
                                                                <Select
                                                                    menuPlacement="auto"
                                                                    style={{ height: '32px' }}
                                                                    value={device['ShopName']}
                                                                    options={this.state.ShopList}
                                                                    onChange={this.handleChangeShopPartner.bind(this, idx, 'ShopName')}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Form.Control
                                                                    value={device['Percentage']}
                                                                    onChange={this.handleChangeShopPartner.bind(this, idx, 'Percentage')}
                                                                    style={{ padding: "0.25rem" }}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Form.Control
                                                                    type="week"
                                                                    value={device['FromDate']}
                                                                    onChange={this.handleChangeShopPartner.bind(this, idx, 'FromDate')}
                                                                    style={{ padding: "0.25rem" }}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Form.Control
                                                                    type="week"
                                                                    value={device['ToDate']}
                                                                    onChange={this.handleChangeShopPartner.bind(this, idx, 'ToDate')}
                                                                    style={{ padding: "0.25rem" }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Row>
                                :
                                <Row style={{
                                    margin: "5px",
                                    height: "75vh",
                                    overflow: "auto",
                                    padding: '10px'
                                }} >
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: '70vh' }} style={{ height: '100%' }} >
                                            <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >From</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >To</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                            <div onClick={this.handleAddTableItemCard.bind(this)} >
                                                                <Add className="HoverButtonAdd" />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.ShopCardDetail.map((device, idx) => (
                                                        <TableRow
                                                            className="table-rows"
                                                            key={idx}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" style={{ cursor: 'pointer' }} >
                                                                <Select
                                                                    menuPlacement="auto"
                                                                    style={{ height: '32px' }}
                                                                    value={device['ShopName']}
                                                                    options={this.state.ShopList}
                                                                    onChange={this.handleChangeShopCard.bind(this, idx, 'ShopName')}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Form.Control
                                                                    type="week"
                                                                    value={device['FromDate']}
                                                                    onChange={this.handleChangeShopCard.bind(this, idx, 'FromDate')}
                                                                    style={{ padding: "0.25rem" }}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Form.Control
                                                                    type="week"
                                                                    value={device['ToDate']}
                                                                    onChange={this.handleChangeShopCard.bind(this, idx, 'ToDate')}
                                                                    style={{ padding: "0.25rem" }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Row>
                            }
                        </div>
                    )
                    :
                    null
                }
            </Container>
        );
    }
}

export default CommonPayAccounts;