import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap"
import Select from 'react-select';

class ShopProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop Name</Form.Label>
                            <Form.Control
                                value={this.props.SP_NAME}
                                name={"SP_NAME"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_NAME")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col md={6}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Link Shop</Form.Label>
                            <Select
                                menuPlacement="auto"
                                style={{ height: '32px', padding: "0.25rem" }}
                                value={this.props.SP_LINKED_SHOP}
                                options={this.props.ShopList.map(x => {
                                    return { value: x.SP_ID, label: x.SP_NAME }
                                })}
                                onChange={this.props.handleChangeInput.bind(this, "SP_LINKED_SHOP")}
                            />
                        </Form.Group>
                    </Col> */}
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop Address</Form.Label>
                            <Form.Control
                                value={this.props.SP_ADDRESS}
                                type={"textarea"}
                                name={"SP_ADDRESS"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_ADDRESS")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{ justifyContent: "space-around", }} >
                    <Col md={3}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Rent Charges</Form.Label>
                            <Form.Control
                                value={this.props.SP_RENT_CHARGES}
                                name={"SP_RENT_CHARGES"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_RENT_CHARGES")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3" >
                            <Form.Label>K & N Charges</Form.Label>
                            <Form.Control
                                value={this.props.SP_KN_CHARGES}
                                name={"SP_KN_CHARGES"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_KN_CHARGES")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3" >
                            <Form.Label>NDR Charges</Form.Label>
                            <Form.Control
                                value={this.props.SP_NDR_CHARGES}
                                name={"SP_NDR_CHARGES"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_NDR_CHARGES")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Calculation Type</Form.Label>
                            <Form.Control
                                as={"select"}
                                value={this.props.SP_CAL_TYPE}
                                name={"SP_CAL_TYPE"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_CAL_TYPE")}
                                style={{ padding: "0.25rem" }}
                            >
                                <option value="">Calculation Type</option>
                                <option value="Cash">Cash Only</option>
                                <option value="Card">Card Only</option>
                                <option value="Both">Both</option>
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>
                {/* <Row style={{ justifyContent: "space-around", }} >
                    <Col md={6}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop Manager</Form.Label>
                            <Form.Control
                                value={this.props.SP_MANAGER}
                                name={"SP_MANAGER"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_MANAGER")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop Manager ID</Form.Label>
                            <Form.Control
                                value={this.props.SP_ID}
                                name={"SP_ID"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_ID")}
                                style={{ padding: "0.25rem", width: "100%" }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop Manager Phone</Form.Label>
                            <Form.Control
                                value={this.props.SP_MANAGER_NUM}
                                name={"SP_MANAGER_NUM"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_MANAGER_NUM")}
                                style={{ padding: "0.25rem", width: "100%" }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Shop Manager Password</Form.Label>
                            <Form.Control
                                value={this.props.SP_MANAGER_PASS_DECRYPT}
                                type={"password"}
                                name={"SP_MANAGER_PASS_DECRYPT"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_MANAGER_PASS_DECRYPT")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                    </Col>
                </Row> */}
                <Row>
                    <Col md={12} >

                        <Form.Group className="mb-3" >
                            <Form.Label>Receipt</Form.Label>
                            <Form.Control
                                value={this.props.SP_PRINT_CONTENT}
                                as="textarea"
                                name={"SP_PRINT_CONTENT"}
                                onChange={this.props.handleChangeInput.bind(this, "SP_PRINT_CONTENT")}
                                style={{ padding: "0.25rem" }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ShopProfile;