import React, { Component } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import { Col, Container, Row } from 'react-bootstrap';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
import axios from "axios";
import RefreshIcon from '@mui/icons-material/Refresh';
import { debounceFunc } from './../../helper'
import PhoneClinicGrid from '../../Components/PhoneClinicGrid';

class WarehouseDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,

            InventoryList: [],
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),
            PC_WARE_ID: localStorage.getItem('PC_GRP_ATT'),

            IMEI_SEARCH: ""

        }
    }

    componentDidMount() {
        let { IMEI_SEARCH, searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getInventory(IMEI_SEARCH, searchGridData, totalRecordsPerPage.value, paginationValue);
    }

    async getInventory(
        IMEI_SEARCH = "",
        searchGridData = "",
        totalRecordsPerPage = 20,
        recordOffset = 1
    ) {
        let tempFormData = {
            ItemIMEI: IMEI_SEARCH
        }
        let whereCondition = ""
        let isSearchEnable = false

        for (const property in tempFormData) {
            if (tempFormData[property] && tempFormData[property] !== "") {
                isSearchEnable = true
                whereCondition += `${property} LIKE "%${tempFormData[property]}%" AND `
            }
        }


        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }
        let formData = {
            whereCondition,
            searchGridData,
            totalRecordsPerPage,
            recordOffset,
            PC_GRP_ID: this.state.PC_GRP_ID,
            PC_WARE_ID: this.state.PC_WARE_ID
        };

        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/WarehouseModule/PCGetDeviceInventory.php`, formData);
        let serviceData = getAllInventory.data
        let InventoryList = []
        let paginationLength = 1

        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            paginationLength = parseInt(getAllInventory.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ InventoryList, paginationLength })

    }

    handleChangeSearch = debounceFunc((label, evt) => {
        this.setState({ [label]: evt.target.value }, () => {
            let {
                IMEI_SEARCH
            } = this.state;
            this.handleSearch(IMEI_SEARCH)
        })
    })

    handleSearch = (
        IMEI_SEARCH = "",
    ) => {
        this.getInventory(IMEI_SEARCH)
    }


    render() {

        return (
            <Container>
                <PhoneClinicGrid
                    title="DEVICE"
                    buttionLabel=""
                    onClickButton={() => { }}
                    handleChangeSearch={(key) => this.handleChangeSearch.bind(this, key)}
                    disableDelete
                    tableHeadData={[
                        {
                            label: "TYPE",
                            isHidden: false
                        },
                        {
                            label: "BRAND",
                            isHidden: false
                        },
                        {
                            label: "NAME",
                            isHidden: false
                        },
                        {
                            label: "DESCRPTION",
                            isHidden: false
                        },
                        {
                            label: "IMEI",
                            isHidden: false
                        },
                        {
                            label: "PURCHASE FROM",
                            isHidden: false
                        },
                        {
                            label: "PURCHASE TYPE",
                            isHidden: false
                        },
                        {
                            label: "BUY RATE",
                            isHidden: false
                        },
                        {
                            label: "SALE RATE",
                            isHidden: false
                        },
                        {
                            label: "STATUS",
                            isHidden: false
                        }
                    ]
                    }
                    searchData={
                        [
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "IMEI_SEARCH",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                >

                    {this.state.InventoryList.map((service, idx) => (
                        <TableRow
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <TableCell align='center' >{service.ItemType}</TableCell>
                            <TableCell align='center' >{service.ItemBrand}</TableCell>
                            <TableCell align='center' >{service.ItemName}</TableCell>
                            <TableCell align='center' >{service.ItemDesc}</TableCell>
                            <TableCell align='center' >{service.ItemIMEI}</TableCell>
                            <TableCell align='center' >{service.PUR_INV_TO}</TableCell>
                            <TableCell align='center' >{service.PUR_INV_TOTYPE}</TableCell>
                            <TableCell align='center' >{service.ItemRate}</TableCell>
                            <TableCell align='center' >{service.ItemSellRate}</TableCell>
                            <TableCell align='center' >{service.ItemStatus}</TableCell>
                            <TableCell align='center'>
                                <IconButton style={{ padding: "0px" }} aria-label="Edit Data" component="span" onClick={() => { }}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}

                </PhoneClinicGrid>
            </Container >
        );
    }
}

export default WarehouseDevice;