import React, { Component } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Col, Container, Row, Form } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import PhoneClinicGrid from '../../Components/PhoneClinicGrid';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
// import CreatableSelect from 'react-select/creatable';
import axios from "axios";
// import Alert from '@mui/material/Alert';
import RefreshIcon from '@mui/icons-material/Refresh';
import Swal from "sweetalert2";
import { debounceFunc } from './../../helper'

class CommonInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,



            addInventory: false,
            InventoryList: [],

            ITEM_ID: "",
            ITEM_NAME: "",
            ITEM_CATEGORY: "",
            ITEM_MODEL: "",
            ITEM_DESC: "",
            ITEM_BARCODE: "",
            ITEM_DEVICE: "",


            DeviceList: [],
            CategoryList: []

        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getCommonInventory(searchGridData, totalRecordsPerPage.value, paginationValue);
    }

    async getCommonInventory(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        let formData = {
            searchGridData,
            totalRecordsPerPage,
            recordOffset
        };
        const getAllInventory = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetCommonInventory.php`, formData);
        let serviceData = getAllInventory.data
        let InventoryList = []
        let paginationLength = 1
        if (serviceData.success === 1) {
            InventoryList = serviceData.data
            paginationLength = parseInt(getAllInventory.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ InventoryList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleEdit(indx) {
        let { InventoryList } = this.state;
        let FilteredData = InventoryList[indx];
        this.getAllDevice()
        this.getAllCategory()
        this.setState({
            addInventory: true,
            ITEM_ID: FilteredData.INV_ID,
            ITEM_NAME: FilteredData.INV_ITEM_NAME,
            ITEM_CATEGORY: {
                'label': FilteredData.INV_CATEGORY_NAME,
                'value': FilteredData.INV_CATEGORY
            },
            ITEM_DEVICE: {
                'label': FilteredData.DEVICE_NAME,
                'value': FilteredData.DEVICE_ID
            },
            ITEM_MODEL: FilteredData.INV_MODEL,
            ITEM_DESC: FilteredData.INV_DESC,
            ITEM_BARCODE: FilteredData.INV_BARCODE
        })
    }

    async getAllDevice() {
        const getDevices = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getAllDevice.php`);
        let deviceData = getDevices.data
        let DeviceList = []
        if (deviceData.success === 1) {
            let InventoryList = deviceData.data
            for (let i = 0; i < InventoryList.length; i++) {
                const element = InventoryList[i];
                DeviceList.push({
                    'label': element.Brand_Name,
                    'value': element.Brand_ID,
                    'id': element.Brand_ID
                })
            }
        }
        this.setState({ DeviceList })
    }

    async getAllCategory() {
        const getDevices = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getAllCategory.php`);
        let deviceData = getDevices.data
        let CategoryList = []
        if (deviceData.success === 1) {
            let InventoryList = deviceData.data
            for (let i = 0; i < InventoryList.length; i++) {
                const element = InventoryList[i];
                CategoryList.push({
                    'label': element.ItemCategory,
                    'value': element.ItemCategoryID_PK
                })
            }
        }
        this.setState({ CategoryList })
    }

    hanldeAddInventory() {
        this.getAllDevice()
        this.getAllCategory()
        this.setState({ addInventory: true })
    }

    handleChange(name, evt) {
        switch (name) {
            case "ITEM_CATEGORY":
            case "ITEM_DEVICE":
                this.setState({ [name]: evt })
                break;

            default:
                this.setState({ [name]: evt.target.value })
                break;
        }
    }

    ValidateInvoice() {
        if (this.state.ITEM_NAME === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                "Enter Item Name",
                'error'
            )
            return false
        }
        if (this.state.ITEM_DEVICE === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                "Select Brand",
                'error'
            )
            return false
        }
        if (this.state.ITEM_CATEGORY === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                "Select Category",
                'error'
            )
            return false
        }
        if (this.state.ITEM_BARCODE === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                "Enter Item Barcode",
                'error'
            )
            return false
        }

        return true
    }

    async handleSave() {
        this.props.toggleLoader(true)
        if (!this.ValidateInvoice()) return
        let FormData = {
            ITEM_ID: this.state.ITEM_ID,
            ITEM_NAME: this.state.ITEM_NAME,
            ITEM_CATEGORY: this.state.ITEM_CATEGORY?.value || "",
            ITEM_MODEL: this.state.ITEM_MODEL,
            ITEM_DESC: this.state.ITEM_DESC,
            ITEM_BARCODE: this.state.ITEM_BARCODE,
            ITEM_DEVICE: this.state.ITEM_DEVICE?.value || "",
        }

        let ReturnDataResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddCommonInventory.php`, FormData);

        if (ReturnDataResult.data.status === 200) {
            Swal.fire(
                'Success',
                ReturnDataResult.data.message,
                'success'
            )
        } else {
            Swal.fire(
                'Error',
                ReturnDataResult.data.message,
                'error'
            )
        }
        this.handleCancel()
    }

    handleCancel() {
        let { totalRecordsPerPage } = this.state
        this.getCommonInventory("", totalRecordsPerPage.value, 1)

        this.setState({
            addInventory: false,
            ITEM_ID: "",
            ITEM_NAME: "",
            ITEM_CATEGORY: "",
            ITEM_DEVICE: "",
            ITEM_MODEL: "",
            ITEM_DESC: "",
            ITEM_BRAND: {},
            ITEM_BARCODE: "",
            ITEM_BUY_RATE: "",
            ITEM_SELL_RATE: "",
            ITEM_QUANTITY: "",
            searchGridData: ""
        })
    }


    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getCommonInventory(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getCommonInventory(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getCommonInventory(searchGridData, totalRecordsPerPage.value, value)
    }

    handleRefresh() {
        let { totalRecordsPerPage } = this.state
        this.getCommonInventory("", totalRecordsPerPage.value, 1)
    }


    render() {
        const { addInventory } = this.state
        return (
            <Container>
                {addInventory === false ?

                    <PhoneClinicGrid
                        title="INVENTORY"
                        buttionLabel="Add Inventory"
                        onClickButton={this.hanldeAddInventory.bind(this)}
                        handleChangeSearch={(key) => { }}
                        tableHeadData={[
                            {
                                label: "ITEM",
                                isHidden: false
                            },
                            {
                                label: "DESCRIPTION",
                                isHidden: false
                            },
                            {
                                label: "CATEGORY",
                                isHidden: false
                            },
                            {
                                label: "BRAND",
                                isHidden: false
                            },
                            {
                                label: "BARCODE",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "ACTIVE",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}
                    >
                        {this.state.InventoryList.map((service, idx) => {
                            return (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <TableCell align='center' >{service.INV_ITEM_NAME}</TableCell>
                                    <TableCell align='center' >{service.INV_DESC}</TableCell>
                                    <TableCell align='center' >{service.INV_CATEGORY_NAME}</TableCell>
                                    <TableCell align='center' >{service.DEVICE_NAME}</TableCell>
                                    <TableCell align='center' >{service.INV_BARCODE}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ padding: "0px" }} onClick={this.handleEdit.bind(this, idx)} aria-label="Edit Data" component="span">
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={() => { }} >
                                            <RemoveIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>

                            )
                        }

                        )}
                    </PhoneClinicGrid>
                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Inventory</legend>
                        </Row>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Item</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        name="ITEM_NAME"
                                        value={this.state.ITEM_NAME}
                                        onChange={this.handleChange.bind(this, 'ITEM_NAME')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Brand</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        name="ITEM_DEVICE"
                                        value={this.state.ITEM_DEVICE}
                                        options={this.state.DeviceList}
                                        onChange={this.handleChange.bind(this, 'ITEM_DEVICE')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Category</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        name="ITEM_CATEGORY"
                                        value={this.state.ITEM_CATEGORY}
                                        options={this.state.CategoryList}
                                        onChange={this.handleChange.bind(this, 'ITEM_CATEGORY')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"textarea"}
                                        name="ITEM_DESC"
                                        value={this.state.ITEM_DESC}
                                        onChange={this.handleChange.bind(this, 'ITEM_DESC')}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Model</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ height: '32px' }}
                                        name="ITEM_MODEL"
                                        value={this.state.ITEM_MODEL}
                                        onChange={this.handleChange.bind(this, 'ITEM_MODEL')}
                                    />
                                </Form.Group>
                            </Col> */}
                            <Col md={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Barcode</Form.Label>
                                    <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        name="ITEM_BARCODE"
                                        value={this.state.ITEM_BARCODE}
                                        onChange={this.handleChange.bind(this, 'ITEM_BARCODE')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={this.handleCancel.bind(this)}>Cancel</Button>
                                <Button onClick={this.handleSave.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Container>
        );
    }
}

export default CommonInventory;