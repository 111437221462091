import React, { Component } from "react";
import { Col, Container, Row, Form, Modal, InputGroup } from 'react-bootstrap';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import CreatableSelect from 'react-select/creatable';
import axios from "axios";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { debounceFunc } from './../../helper'
import Select from 'react-select'
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import PhoneClinicGrid from "../../Components/PhoneClinicGrid";

const ROLE_LIST = [
    // {
    //     id: 1,
    //     value: 1,
    //     label: "Clinic-Admin",
    // },
    {
        id: 2,
        value: 2,
        label: "Manager",
    },
    {
        id: 3,
        value: 3,
        label: "User",
    }
]

const ROLE_FOR_LIST = [
    // {
    //     id: 1,
    //     value: 1,
    //     label: "System",
    // },
    {
        id: 2,
        value: 2,
        label: "Shop",
    },
    {
        id: 3,
        value: 3,
        label: "Warehouse",
    },
    {
        id: 4,
        value: 4,
        label: "Pay Account",
    }
]

class CommonUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {

            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            UserList: [],
            showForm: false,

            GRP_ATT_LIST: [],

            PC_USR_ID: "",
            PC_USR_NAME: "",
            PC_USR_EMAIL: "",
            PC_USR_PASSWORD: "",
            PC_USR_ADDRESS: "",
            PC_USR_ROLE_ID: "",
            PC_GRP_ID: "",
            PC_GRP_ATT: "",

            searchData: [
                {
                    key: "PC_USR_NAME",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "PC_USR_EMAIL",
                    value: "",
                    isHidden: false,
                    isDisable: false
                },
                {
                    key: "role",
                    value: "",
                    isHidden: false,
                    isDisable: true
                },
                {
                    key: "for",
                    value: "",
                    isHidden: false,
                    isDisable: true
                }
            ]
        }
    }

    componentDidMount() {
        let { searchData, totalRecordsPerPage, paginationValue } = this.state
        this.getGridData(searchData, totalRecordsPerPage.value, paginationValue)
    }

    async getGridData(searchData = [], totalRecordsPerPage = 20, recordOffset = 1) {
        this.props.toggleLoader(true)
        let whereCondition = ""
        let isSearchEnable = false

        for (let i = 0; i < searchData.length; i++) {
            const eachData = searchData[i];
            if (eachData.value !== "") {
                isSearchEnable = true
                whereCondition += `${eachData.key} LIKE "%${eachData.value}%" AND `
            }

        }

        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }

        let formData = {
            whereCondition,
            totalRecordsPerPage,
            recordOffset,
        };

        console.log('formData', formData)


        const getData = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Common/GetCommonUsers.php`, formData);
        console.log('getData', getData)
        let data = getData.data
        let UserList = []
        let paginationLength = 1
        if (data.success === 1) {
            UserList = data.data
            paginationLength = parseInt(data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ UserList, paginationLength })
        this.props.toggleLoader(false)
    }

    handleAddDevice() {
        this.setState({
            showForm: true
        })
    }

    async handleChangeInput(fieldName, event) {
        let targetValue
        switch (fieldName) {
            case "PC_USR_ROLE_ID":
            case "PC_GRP_ATT":
                targetValue = event
                break;

            case "PC_GRP_ID":
                switch (event.value) {
                    case 2:
                        await this.getShopList()
                        break;
                    case 3:
                        await this.getWarehouseList()
                        break;
                    case 4:
                        await this.getAllPayaccount()
                        break;
                    default:
                        break;
                }
                targetValue = event
                break;

            default:
                targetValue = event.target.value
                break;
        }
        this.setState({ [fieldName]: targetValue })
    }

    async getShopList(selectedID = "") {
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let GRP_ATT_LIST = [], selectedData = ""
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                if (selectedID === shop.PC_SP_ID) {
                    selectedData = {
                        "value": shop.PC_SP_ID,
                        "label": shop.SP_NAME,
                        "type": "shop"
                    }
                }
                GRP_ATT_LIST.push({
                    "value": shop.PC_SP_ID,
                    "label": shop.SP_NAME,
                    "type": "shop"
                })
            }
        }
        this.setState({ GRP_ATT_LIST, PC_GRP_ATT: selectedData })
    }

    async getWarehouseList(selectedID = "") {
        const getWarehouse = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllWarehouse.php`);
        let warehouseData = getWarehouse.data
        let GRP_ATT_LIST = [], selectedData = ""
        if (warehouseData.success === 1) {
            for (let i = 0; i < warehouseData.data.length; i++) {
                const warehouse = warehouseData.data[i];
                if (selectedID === warehouse.WAREHOUSE_ID) {
                    selectedData = {
                        value: warehouse.WAREHOUSE_ID,
                        label: warehouse.WAREHOUSE_NAME,
                        type: "warehouse"
                    }
                }
                GRP_ATT_LIST.push({
                    value: warehouse.WAREHOUSE_ID,
                    label: warehouse.WAREHOUSE_NAME,
                    type: "warehouse"
                })
            }
        }
        this.setState({ GRP_ATT_LIST, PC_GRP_ATT: selectedData })
    }

    async getAllPayaccount(selectedID = "") {
        const getAllPayAccounts = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllPayAccount.php`);
        let deviceData = getAllPayAccounts.data
        let GRP_ATT_LIST = [], selectedData = ""
        if (deviceData.success === 1) {
            for (let i = 0; i < deviceData.data.length; i++) {
                const eachAccount = deviceData.data[i];
                if (selectedID === eachAccount.ACCOUNT_ID) {
                    selectedData = {
                        "label": eachAccount.ACCOUNT_TITLE,
                        "value": eachAccount.ACCOUNT_ID,
                        "type": "account"
                    }
                }
                GRP_ATT_LIST.push({
                    "label": eachAccount.ACCOUNT_TITLE,
                    "value": eachAccount.ACCOUNT_ID,
                    "type": "account"
                })
            }
        }
        this.setState({ GRP_ATT_LIST, PC_GRP_ATT: selectedData })
    }

    handleCancelDevice() {
        this.setState({
            showForm: false,
            PC_USR_ID: "",
            PC_USR_NAME: "",
            PC_USR_EMAIL: "",
            PC_USR_PASSWORD: "",
            PC_USR_ADDRESS: "",
            PC_USR_ROLE_ID: "",
            PC_GRP_ID: "",
            PC_GRP_ATT: "",
        })
    }

    async handleSubmitDevice() {
        this.props.toggleLoader(true)



        let formData = {
            PC_USR_ID: this.state.PC_USR_ID,
            PC_USR_NAME: this.state.PC_USR_NAME,
            PC_USR_EMAIL: this.state.PC_USR_EMAIL,
            PC_USR_PASSWORD: this.state.PC_USR_PASSWORD,
            PC_USR_ADDRESS: this.state.PC_USR_ADDRESS,
            PC_USR_ROLE_ID: this.state.PC_USR_ROLE_ID?.value,
            PC_GRP_ID: this.state.PC_GRP_ID?.value,
            PC_GRP_ATT: this.state.PC_GRP_ATT?.value,
        }

        let getuserResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddUser.php`, formData);

        this.props.toggleLoader(false)
        if (getuserResult.data.status === 200) {
            Swal.fire(
                'Success',
                getuserResult.data.message,
                'success'
            )
            this.handleCancelDevice()
            this.getGridData("", 20, 1)
        } else {
            Swal.fire(
                'Error',
                getuserResult.data.message,
                'error'
            )
        }

    }


    handleEdit(indx) {
        let { UserList } = this.state;
        let userData = UserList[indx];
        let role = "", roleFor = ""
        let filterRole = ROLE_LIST.filter(x => x.id === +userData.PC_USR_ROLE_ID)
        let filterRoleFor = ROLE_FOR_LIST.filter(x => x.id === +userData.PC_GRP_ID)
        if (filterRole && filterRole.length > 0) {
            role = filterRole[0]
        }
        if (filterRoleFor && filterRoleFor.length > 0) {
            roleFor = filterRoleFor[0]
        }

        if (userData.PC_GRP_ATT !== "") {
            switch (+userData.PC_GRP_ID) {
                case 2:
                    this.getShopList(userData.PC_GRP_ATT)
                    break;
                case 3:
                    this.getWarehouseList(userData.PC_GRP_ATT)
                    break;
                case 4:
                    this.getAllPayaccount(userData.PC_GRP_ATT)
                    break;
                default:
                    break;
            }
        }

        this.setState({



            PC_USR_ID: userData['PC_USR_ID'],
            PC_USR_NAME: userData['PC_USR_NAME'],
            PC_USR_EMAIL: userData['PC_USR_EMAIL'],
            PC_USR_PASS_DECRYPT: userData['PC_USR_PASS_DECRYPT'],
            PC_USR_ADDRESS: userData['PC_USR_ADDRESS'],

            PC_USR_ROLE_ID: role,
            PC_GRP_ID: roleFor,


            showForm: true
        })
    }


    handleChangeSearch = debounceFunc((label, evt) => {
        // this.setState({ [label]: evt.target.value }, () => {


        //     let {
        //         WEEK_SEARCH,
        //         SHOP_SEARCH,
        //         CASH_SEARCH,
        //         CARD_SEARCH,
        //         OTHERS_SEARCH,
        //         CB_SEARCH,
        //         STATUS_SEARCH
        //     } = this.state;
        //     this.handleSearch(
        //         WEEK_SEARCH,
        //         SHOP_SEARCH,
        //         CASH_SEARCH,
        //         CARD_SEARCH,
        //         OTHERS_SEARCH,
        //         CB_SEARCH,
        //         STATUS_SEARCH)
        // })
    })


    handleDelete(id) {

    }


    handleChangeSearch = debounceFunc((indx, evt) => {
        let { searchData } = this.state;
        searchData[indx]["value"] = evt.target.value
        this.setState({ searchData }, () => {
            let { searchData } = this.state;
            this.handleSearch(searchData)
        })
    })

    handleSearch = (searchData) => {
        this.getGridData(searchData)
    }

    render() {
        return (
            <Container>
                {this.state.showForm === false ?
                    <PhoneClinicGrid
                        title="USER"
                        buttionLabel="Add User"
                        onClickButton={this.handleAddDevice.bind(this)}
                        handleChangeSearch={(key, indx) => this.handleChangeSearch.bind(this, indx)}
                        tableHeadData={[
                            {
                                label: "USER",
                                isHidden: false
                            },
                            {
                                label: "EMAIL",
                                isHidden: false
                            },
                            {
                                label: "ROLE",
                                isHidden: false
                            },
                            {
                                label: "FOR",
                                isHidden: false
                            },
                        ]}
                        searchData={this.state.searchData}
                    >
                        {this.state.UserList.map((device, idx) => {
                            let role = ""
                            let filterRole = ROLE_LIST.filter(x => x.id === +device.PC_USR_ROLE_ID)
                            if (filterRole && filterRole.length > 0) {
                                role = filterRole[0].label
                            }
                            return (
                                <TableRow
                                    className="table-rows"
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align='center'  >{device.PC_USR_NAME}</TableCell>
                                    <TableCell align='center' style={{ cursor: 'pointer', color: "#416bc7" }}  >{device.PC_USR_EMAIL}</TableCell>
                                    <TableCell align='center' >{role}</TableCell>
                                    <TableCell align='center' >{device.PC_GRP_ID + ' ' + device.PC_GRP_ATT}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ padding: "0px" }} onClick={this.handleEdit.bind(this, idx)} aria-label="Edit Data" component="span">
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.handleDelete.bind(this, device.PC_USR_ID)} >
                                            <RemoveIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </PhoneClinicGrid>

                    :
                    <>
                        <Row style={{ margin: "5px" }} >
                            <Col md={12} style={{ textAlign: "start" }} >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <div style={{ whiteSpace: "nowrap", marginRight: "10px" }} ><strong>CREATE</strong> USER</div>
                                    <div style={{ height: "3px", width: "100%", background: "#ff1a00" }} >
                                    </div>
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        name={"PC_USR_NAME"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        onChange={this.handleChangeInput.bind(this, "PC_USR_NAME")}
                                        value={this.state.PC_USR_NAME}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name={"PC_USR_EMAIL"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        autoComplete="new-password"
                                        onChange={this.handleChangeInput.bind(this, "PC_USR_EMAIL")}
                                        value={this.state.PC_USR_EMAIL}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        name={"PC_USR_PASSWORD"}
                                        type="password"
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        autoComplete="new-password"
                                        onChange={this.handleChangeInput.bind(this, "PC_USR_PASSWORD")}
                                        value={this.state.PC_USR_PASSWORD}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} >
                                <Form.Group className="mb-3" >
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        name={"PC_USR_ADDRESS"}
                                        as={"textarea"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        onChange={this.handleChangeInput.bind(this, "PC_USR_ADDRESS")}
                                        value={this.state.PC_USR_ADDRESS || ""}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} >
                                <Form.Group className="mb-3" >
                                    <Form.Label>Role</Form.Label>
                                    <Select
                                        name={"PC_USR_ROLE_ID"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        onChange={this.handleChangeInput.bind(this, "PC_USR_ROLE_ID")}
                                        options={ROLE_LIST}
                                        value={this.state.PC_USR_ROLE_ID}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} >
                                <Form.Group className="mb-3" >
                                    <Form.Label>Role For</Form.Label>
                                    <Select
                                        name={"PC_GRP_ID"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        onChange={this.handleChangeInput.bind(this, "PC_GRP_ID")}
                                        options={ROLE_FOR_LIST}
                                        value={this.state.PC_GRP_ID}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} >
                                <Form.Group className="mb-3" >
                                    <Form.Label>Role To</Form.Label>
                                    <Select
                                        name={"PC_GRP_ATT"}
                                        style={{ padding: "0.25rem", zIndex: "1000px" }}
                                        onChange={this.handleChangeInput.bind(this, "PC_GRP_ATT")}
                                        options={this.state.GRP_ATT_LIST}
                                        value={this.state.PC_GRP_ATT}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelDevice.bind(this)} >Cancel</Button>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleSubmitDevice.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        );
    }
}

export default CommonUsers;