import React from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import CreatableSelect from 'react-select/creatable';
import axios from "axios";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import { debounceFunc } from './../../helper'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select'
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import PhoneClinicGrid from "../../Components/PhoneClinicGrid";

class JobServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,




            addService: false,
            ServicesList: [],
            repairTypeList: [
                { value: 'Phone', label: 'Phone' },
                { value: 'Tablet', label: 'Tablet' },
                { value: 'Laptop', label: 'Laptop' },
                { value: 'Gaming Console', label: 'Gaming Console' },
                { value: 'Watch', label: 'Watch' }
            ],
            repairBrandList: [],
            repairDeviceList: [],
            repairServiceList: [],

            PCSS_ID: "",
            DEVICE_TYPE: "",
            DEVICE_BRAND: "",
            DEVICE_NAME: "",
            SERVICE_NAME: "",
            SERVICE_COST: "",
            SERVICE_PRE_COST: ""
        };
    }


    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getServiceByShop(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getServiceByShop(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetServicesByShop.php`, {
            PC_SHOP: localStorage.getItem('PC_GRP_ATT'),
            searchGridData,
            totalRecordsPerPage,
            recordOffset
        }).then((response) => {
            let ServicesList = []
            let paginationLength = 1
            if (response.data.success === 1) {
                ServicesList = response.data.data
                paginationLength = parseInt(response.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
            }
            this.setState({ ServicesList, paginationLength })
            this.props.toggleLoader(false)
        }).catch((error) => {
            console.error(error);
            this.setState({ ServicesList: [], paginationLength: 1 })
            this.props.toggleLoader(false)
        });

    }

    async handleChangeInput(fieldName, evt) {
        let targetValue
        switch (fieldName) {
            case 'DEVICE_TYPE':
                targetValue = evt
                let deviceBrand = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceBrand.php`, { DEVICE: evt.value });
                let repairBrandList = []
                if (deviceBrand.data.success === 1) {
                    let devices = deviceBrand.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const device = devices[i];
                        repairBrandList.push({
                            value: device.DEVICE_BRAND,
                            label: device.DEVICE_BRAND
                        })
                    }
                }
                this.setState({ repairBrandList })
                break;

            case "DEVICE_BRAND":
                targetValue = evt
                let deviceType = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceName.php`, { BRAND: evt.value, DEVICE: this.state.DEVICE_TYPE?.value });
                let repairDeviceList = []
                if (deviceType.data.success === 1) {
                    let devices = deviceType.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const deviceName = devices[i];
                        repairDeviceList.push({
                            value: deviceName.DEVICE_NAME,
                            label: deviceName.DEVICE_NAME
                        })
                    }
                }
                this.setState({ repairDeviceList })
                break;

            case "DEVICE_NAME":
            case "SERVICE_NAME":
                targetValue = evt
                break;

            default:
                targetValue = evt.target.value
                break;
        }
        this.setState({ [fieldName]: targetValue })
    }

    hanldeEditService() {
        this.getAllServices()
        this.setState({ addService: true })
    }

    async getAllServices() {
        let formData = {
            PCAS_SERVICE_STATUS: 'Completed'
        };

        const getAllService = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllServiceRecord.php`, formData);
        let serviceData = getAllService.data
        let repairServiceList = []
        if (serviceData.success === 1) {
            for (let i = 0; i < serviceData.data.length; i++) {
                const deviceName = serviceData.data[i];
                repairServiceList.push({
                    value: deviceName.PCAS_SERVICE_NAME,
                    label: deviceName.PCAS_SERVICE_NAME
                })
            }
        }
        this.setState({ repairServiceList })
    }

    async handleSubmitService() {
        let formData = {
            PCSS_ID: this.state.PCSS_ID,
            PCSS_SHOP_NAME: localStorage.getItem('PC_GRP_ATT'),
            PCSS_DEVICE_TYPE: this.state.DEVICE_TYPE?.value,
            PCSS_DEVICE_BRAND: this.state.DEVICE_BRAND?.value,
            PCSS_DEVICE_NAME: this.state.DEVICE_NAME?.value,
            PCSS_SERVICE: this.state.SERVICE_NAME?.value,
            PCSS_SERVICE_COST: this.state.SERVICE_COST,
            PCSS_SERVICE_PREM: this.state.SERVICE_PRE_COST
        }

        let data = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCAddShopService.php`, formData);
        this.handleCancelService()
        this.getServiceByShop("", 20, 1)
    }

    handleCancelService() {
        this.setState({
            addService: false,
            PCSS_ID: "",
            DEVICE_TYPE: "",
            DEVICE_BRAND: "",
            DEVICE_NAME: "",
            SERVICE_NAME: "",
            SERVICE_COST: "",
            SERVICE_PRE_COST: ""
        })
    }

    handleUpdateShop(idx) {
        let { ServicesList } = this.state;
        this.setState({
            PCSS_ID: ServicesList[idx]['PCSS_ID'],
            DEVICE_TYPE: { value: ServicesList[idx]['PCSS_DEVICE_TYPE'], label: ServicesList[idx]['PCSS_DEVICE_TYPE'] },
            DEVICE_BRAND: { value: ServicesList[idx]['PCSS_DEVICE_BRAND'], label: ServicesList[idx]['PCSS_DEVICE_BRAND'] },
            DEVICE_NAME: { value: ServicesList[idx]['PCSS_DEVICE_NAME'], label: ServicesList[idx]['PCSS_DEVICE_NAME'] },
            SERVICE_NAME: { value: ServicesList[idx]['PCSS_SERVICE'], label: ServicesList[idx]['PCSS_SERVICE'] },
            SERVICE_COST: ServicesList[idx]['PCSS_SERVICE_COST'],
            SERVICE_PRE_COST: ServicesList[idx]['PCSS_SERVICE_PREM'],
            addService: true
        })
    }


    // Pagination Work

    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getServiceByShop(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getServiceByShop(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getServiceByShop(searchGridData, totalRecordsPerPage.value, value)
    }

    handleRefresh() {
        let { totalRecordsPerPage } = this.state
        this.getServiceByShop("", totalRecordsPerPage.value, 1)
    }

    render() {
        let { addService } = this.state
        return (
            <Container>
                {addService === false ?
                    <PhoneClinicGrid
                        title="JOB SERVICE"
                        buttionLabel="UPDATE SERVICE"
                        onClickButton={this.hanldeEditService.bind(this)}
                        handleChangeSearch={(key) => { }}
                        tableHeadData={[
                            {
                                label: "DEVICE",
                                isHidden: false
                            },
                            {
                                label: "BRAND",
                                isHidden: false
                            },
                            {
                                label: "MODEL",
                                isHidden: false
                            },
                            {
                                label: "SERVICES",
                                isHidden: false
                            },
                            {
                                label: "EXPRESS",
                                isHidden: false
                            },
                            {
                                label: "PREMIUM",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "CATEGORY",
                                isHidden: false,
                                isDisable: true
                            }
                        ]}

                    >
                        {this.state.ServicesList.map((service, idx) => (
                            <TableRow
                                className="table-rows"
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align='center' >{service.PCSS_DEVICE_TYPE}</TableCell>
                                <TableCell align='center' >{service.PCSS_DEVICE_BRAND}</TableCell>
                                <TableCell align='center' >{service.PCSS_DEVICE_NAME}</TableCell>
                                <TableCell align='center' >{service.PCSS_SERVICE}</TableCell>
                                <TableCell align='center' >{service.PCSS_SERVICE_COST}</TableCell>
                                <TableCell align='center' >{service.PCSS_SERVICE_PREM}</TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ padding: "0px" }} onClick={this.handleUpdateShop.bind(this, idx)} aria-label="Edit Data" component="span">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={() => { }} >
                                        <RemoveIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </PhoneClinicGrid>

                    :
                    <>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>DEVICE</Form.Label>
                                    <CreatableSelect
                                        style={{ padding: "0.25rem" }}
                                        isDisabled={this.state.PCSS_ID === "" ? false : true}
                                        options={this.state.repairTypeList}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_TYPE")}
                                        value={this.state.DEVICE_TYPE}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>BRAND</Form.Label>
                                    <CreatableSelect
                                        options={this.state.repairBrandList}
                                        isDisabled={this.state.PCSS_ID === "" ? false : true}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_BRAND")}
                                        style={{ padding: "0.25rem" }}
                                        value={this.state.DEVICE_BRAND}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>MODEL</Form.Label>
                                    <CreatableSelect
                                        options={this.state.repairDeviceList}
                                        isDisabled={this.state.PCSS_ID === "" ? false : true}
                                        onChange={this.handleChangeInput.bind(this, "DEVICE_NAME")}
                                        style={{ padding: "0.25rem" }}
                                        value={this.state.DEVICE_NAME}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>SERVICES</Form.Label>
                                    <CreatableSelect
                                        options={this.state.repairServiceList}
                                        isDisabled={this.state.PCSS_ID === "" ? false : true}
                                        onChange={this.handleChangeInput.bind(this, "SERVICE_NAME")}
                                        style={{ padding: "0.25rem" }}
                                        value={this.state.SERVICE_NAME}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>EXPRESS COST</Form.Label>
                                    <Form.Control
                                        value={this.state.SERVICE_COST}
                                        onChange={this.handleChangeInput.bind(this, "SERVICE_COST")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>PREMIUM COST</Form.Label>
                                    <Form.Control
                                        value={this.state.SERVICE_PRE_COST}
                                        onChange={this.handleChangeInput.bind(this, "SERVICE_PRE_COST")}
                                        style={{ padding: "0.25rem", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "end" }} >
                                <Button onClick={this.handleCancelService.bind(this)} >Cancel</Button>
                                <Button variant="contained" color='success' onClick={this.handleSubmitService.bind(this)} >Save</Button>
                            </Col>
                        </Row>
                    </>

                }

            </Container >
        );
    }
}

export default JobServices;