import React, { Component } from 'react';
import { Col, Container, Row, Form, InputGroup } from 'react-bootstrap';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Select from 'react-select';
import Swal from "sweetalert2";
import { getISOWeek, formatDate, debounceFunc } from '../../../helper';
import { Add, Remove } from '@mui/icons-material';
import PhoneClinicGrid from '../../../Components/PhoneClinicGrid';

class AccountPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,
            addAccPayment: false,
            isEdit: false,

            AccPaymentsList: [],
            ShopList: [],

            AllShopData: [],



            AccountList: [],

            AP_ID: "",
            PaymentWeek: "",
            ShopPayment: "",
            PrevWeek: "0",
            CWCard: "0",
            TotalAmount: "0",
            NetAmount: "0",
            Expense: "0",
            AccountPayableDetails: [],
            AccPayClaimDetail: [],
            shopPaymentDays: [],
            groupedOptions: [],
            AllWareHouseList: [],
            PC_USR_ROLE_ID: localStorage.getItem('PC_USR_ROLE_ID'),
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID'),
            PC_SHOP_ID: localStorage.getItem('PC_GRP_ATT'),

            isAccountDisable: false,

            AP_EXP: "",
            ACCOUNT_TITLE: "",
        }
    }


    componentDidMount() {
        let { AP_EXP, ACCOUNT_TITLE, CREATED_BY, AP_STATUS, searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getAccountPayments(AP_EXP, ACCOUNT_TITLE, CREATED_BY, AP_STATUS, searchGridData, totalRecordsPerPage.value, paginationValue);
        this.getShopList();
        this.getAllPayaccount();
        this.getWarehouseList();
    }

    async getAccountPayments(AP_EXP = "", ACCOUNT_TITLE = "", CREATED_BY = "", AP_STATUS = "", searchGridData = "", totalRecordsPerPage = 20, recordOffset = 1) {
        this.props.toggleLoader(true)
        let tempFormData = {
            ACCOUNT_TITLE: ACCOUNT_TITLE,
            AP_EXP: AP_EXP,
            CREATED_BY,
            AP_STATUS
        }
        let whereCondition = ""
        let isSearchEnable = false

        for (const property in tempFormData) {
            if (tempFormData[property] && tempFormData[property] !== "") {
                isSearchEnable = true
                whereCondition += `${property} LIKE "%${tempFormData[property]}%" AND `
            }
        }


        if (isSearchEnable) {
            whereCondition = "WHERE " + whereCondition;
            whereCondition = whereCondition.substring(0, whereCondition.length - 4)
        }
        let formData = {
            whereCondition,
            totalRecordsPerPage,
            recordOffset,
            PC_USR_ROLE_ID: this.state.PC_USR_ROLE_ID,
            PC_GRP_ID: this.state.PC_GRP_ID,
            PC_SHOP_ID: this.state.PC_SHOP_ID
        }

        console.log('formData', formData)
        const getSP = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/Finance/PCGetAllAccountPayment.php`, formData);
        let ShopPaymentData = getSP.data
        console.log('ShopPaymentData', ShopPaymentData)
        let AccPaymentsList = []
        let paginationLength = 1
        if (ShopPaymentData.success === 1) {
            AccPaymentsList = ShopPaymentData.data
            paginationLength = parseInt(ShopPaymentData.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
        }
        this.setState({ AccPaymentsList, paginationLength })
        this.props.toggleLoader(false)
    }

    async getShopList() {
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                ShopList.push({
                    value: shop.PC_SP_ID,
                    label: shop.SP_NAME,
                    type: "shop"
                })
            }
        }
        this.setState({ ShopList, AllShopData: shopData.data })
    }

    async getWarehouseList() {
        const getWarehouse = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllWarehouse.php`);
        let warehouseData = getWarehouse.data
        let AllWareHouseList = []
        if (warehouseData.success === 1) {
            for (let i = 0; i < warehouseData.data.length; i++) {
                const warehouse = warehouseData.data[i];
                AllWareHouseList.push({
                    value: warehouse.WAREHOUSE_NAME,
                    label: warehouse.WAREHOUSE_NAME,
                    type: "warehouse"
                })
            }
        }
        this.setState({ AllWareHouseList })
    }

    async getAllPayaccount() {

        const getAllPayAccounts = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetAllPayAccount.php`);

        let deviceData = getAllPayAccounts.data
        let AccountList = []

        if (deviceData.success === 1) {

            for (let i = 0; i < deviceData.data.length; i++) {
                const eachAccount = deviceData.data[i];
                AccountList.push({
                    "label": eachAccount.ACCOUNT_TITLE,
                    "value": eachAccount.ACCOUNT_ID,
                    "type": "account"
                })
            }

        }

        this.setState({ AccountList, AllAccountData: deviceData.data })

    }


    hanldeAddAccPayment() {
        let isAccountDisable = false
        let ShopPayment = ""
        let AccountPayableDetails = []

        let { PC_SHOP_ID, AllAccountData } = this.state;
        if (PC_SHOP_ID && PC_SHOP_ID !== 'null') {
            isAccountDisable = true
            let filtershopPayment = AllAccountData.filter(x => x.ACCOUNT_ID === PC_SHOP_ID)?.[0]
            ShopPayment = {
                "label": filtershopPayment.ACCOUNT_TITLE,
                "value": filtershopPayment.ACCOUNT_ID,
                "type": "account"
            }

            let minimumDel = 10
            for (let i = 0; i < minimumDel; i++) {
                AccountPayableDetails.push({
                    ShopPay_Date: "",
                    // ShopPay_Acc: "",
                    ShopPay_Desc: "",
                    ShopPay_Shop: "",
                    ShopPay_Amnt: 0
                })
            }
            this.setState({ AccountPayableDetails })
        }



        this.setState({
            addAccPayment: true,
            isEdit: false,
            isAccountDisable,
            ShopPayment,
        })
        this.handleSelectOptions();

    }

    async handleChange(fieldName, evt) {
        let value
        switch (fieldName) {
            case 'ShopPayment':
                value = evt;
                let AccountPayableDetails = []
                let minimumDel = 10
                for (let i = 0; i < minimumDel; i++) {
                    AccountPayableDetails.push({
                        ShopPay_Date: "",
                        // ShopPay_Acc: "",
                        ShopPay_Desc: "",
                        ShopPay_Shop: "",
                        ShopPay_Amnt: 0
                    })
                }
                this.setState({ AccountPayableDetails })
                break;
            case 'PaymentWeek':
                value = evt.target.value;
                let WeekNumber = evt.target.value;
                let weekYearArr = WeekNumber.split("-");
                weekYearArr[1] = weekYearArr[1].replace("W", "");
                let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                let WeekOut = await getISOWeek(...MappedValue)
                let shopPaymentDays = []
                if (WeekOut.length > 0) {
                    for (let i = 0; i < WeekOut.length; i++) {
                        const WeekPerDay = WeekOut[i];
                        shopPaymentDays.push({
                            'DateAndDay': WeekPerDay
                        })
                    }
                }
                this.setState({ shopPaymentDays })
                break;
            default:
                value = evt.target.value
                break;
        }
        this.setState({ [fieldName]: value })
    }

    handleChangeShopPayment(index, fieldName, evt) {
        let { AccountPayableDetails } = this.state;
        let targetValue
        switch (fieldName) {
            case 'ShopPay_Acc':
            case 'ShopPay_Shop':
                targetValue = evt ? evt : "";
                break;

            default:
                targetValue = evt.target.value;
                break;
        }
        AccountPayableDetails[index][fieldName] = targetValue;
        this.setState({ AccountPayableDetails }, () => {
            if (fieldName === 'ShopPay_Amnt') {
                let { AccountPayableDetails, TotalAmount } = this.state;
                let ExtraSum = AccountPayableDetails.reduce(function (sum, current) {
                    return sum + +current.ShopPay_Amnt;
                }, 0);
                let NetAmount = +TotalAmount - +ExtraSum
                this.setState({ Expense: ExtraSum, NetAmount })

            }
        });
    }

    handleValidate() {
        if (this.state.PaymentWeek === "") {
            Swal.fire(
                'Error',
                'Please Select Payment Week',
                'error'
            )
            return false
        }
        if (this.state.ShopPayment === "") {
            Swal.fire(
                'Error',
                'Please Select Shop',
                'error'
            )
            return false
        }
        return true
    }

    async handleSubmitAccPayment() {

        if (!this.handleValidate()) return;
        let { AccountPayableDetails } = this.state;

        // case 1
        // If user select only account
        let filterAccData = AccountPayableDetails.filter(x => x.ShopPay_Acc !== "" && x.ShopPay_Shop === "")
        let AccDataSum = filterAccData.reduce(function (sum, current) {
            return sum + parseInt(current.ShopPay_Amnt);
        }, 0);


        // case 2
        // If user select only shop
        let filterShopData = AccountPayableDetails.filter(x => x.ShopPay_Shop !== "" && x.ShopPay_Acc === "");
        let ShopDataSum = filterShopData.reduce(function (sum, current) {
            return sum + parseInt(current.ShopPay_Amnt);
        }, 0);



        // case 3
        // If user select both shop and account
        let filterData = AccountPayableDetails.filter(x => x.ShopPay_Shop !== "" && x.ShopPay_Acc !== "")
        let DataSum = filterData.reduce(function (sum, current) {
            return sum + parseInt(current.ShopPay_Amnt);
        }, 0);


        let formData = {
            AP_ID: this.state.AP_ID,
            PaymentWeek: this.state.PaymentWeek,
            ShopPayment: this.state.ShopPayment.value,
            PrevWeek: this.state.PrevWeek.toString(),
            CWCard: this.state.CWCard.toString(),
            TotalAmount: this.state.TotalAmount.toString(),
            NetAmount: this.state.NetAmount.toString(),
            Expense: this.state.Expense.toString(),
            AccountPayableDetails: JSON.stringify(AccountPayableDetails),
            shopPaymentDays: JSON.stringify(this.state.shopPaymentDays),
            WeekSaleDetail: JSON.stringify(filterData),
            AP_STATUS: "PENDING",
            LastUpdateBY: localStorage.getItem('PC_USR_NAME'),
            ShopDataSum
        }

        try {
            let getSaleResult = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/Finance/PCAddAccountPayment.php`, formData);

            // ::TODO
            let linkData = {
                ShopPayment: this.state.ShopPayment.value,
                AccountPayableDetails: JSON.stringify(AccountPayableDetails),
                PaymentWeek: this.state.PaymentWeek
            }

            await axios.post(`${process.env.REACT_APP_BASE_URL}services/linking/AccountPaymentUpdate.php`, linkData);

            if (getSaleResult.data.status === 201) {
                Swal.fire(
                    'Success',
                    getSaleResult.data.message,
                    'success'
                ).then(res => {
                    if (getSaleResult.data.message === 'Account Payment Opened') {
                        if (res.isConfirmed) {
                            this.setState({ isEdit: true, AP_ID: getSaleResult.data.extra[0].AP_ID })
                        }
                    } else {
                        this.handleExit()
                    }
                })
            } else {
                Swal.fire(
                    'Error',
                    getSaleResult.data.message,
                    'error'
                )
            }

        } catch (error) {
            console.error('error :>> ', error);
        }


    }

    handleExit() {
        let { totalRecordsPerPage } = this.state
        this.getAccountPayments("", "", "", "", "", totalRecordsPerPage.value, 1);
        this.setState({

            isEdit: false,
            addAccPayment: false,


            AP_ID: "",
            PaymentWeek: "",
            ShopPayment: "",
            PrevWeek: "0",
            CWCard: "0",
            TotalAmount: "0",
            NetAmount: "0",
            Expense: "0",
            AccountPayableDetails: [],
            shopPaymentDays: []
        })

    }

    handleSelectOptions() {
        let { ShopList, AccountList, AllWareHouseList } = this.state;
        let groupedOptions = [
            {
                label: "Shop",
                options: ShopList
            },
            {
                label: "Account",
                options: AccountList
            },
            {
                label: "Warehouse",
                options: AllWareHouseList
            }
        ];
        this.setState({ groupedOptions })
    }

    handleUpdateAccPay(index) {
        let { AccPaymentsList, AccountList } = this.state;
        let myData = AccPaymentsList[index];

        // await this.UpdateShopHistory(myData);
        this.handleSelectOptions();

        let WeeklyShop = AccountList.filter(x => x.value === myData.AP_SHOP_ID)[0]
        this.setState({
            isEdit: true,
            addAccPayment: true,
            isAccountDisable: true,
            AP_ID: myData.AP_ID,
            PaymentWeek: myData.AP_WEEK,
            ShopPayment: WeeklyShop,
            PrevWeek: myData.AP_PRE_WEEK,
            CWCard: myData.AP_CWCard,
            TotalAmount: myData.AP_TOTAMT,
            NetAmount: myData.AP_NTAMT,
            Expense: myData.AP_EXP,
            AccountPayableDetails: JSON.parse(myData.AP_DETAILS),
            shopPaymentDays: JSON.parse(myData.AP_WEEK_DAYS)
        })

    }

    async handleDelete(AP_ID) {
        Swal.fire({
            title: 'Do you want to delete Account Payment ?',
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.props.toggleLoader(true)
                const getShops = await axios.post(`${process.env.REACT_APP_BASE_URL}services/delete/finance/DeleteAccountPayment.php`, { AP_ID });
                let shopData = getShops.data;
                if (shopData.success === 1 && shopData.message === "You have successfully delete track.") {
                    Swal.fire('Shop Payment Deleted!', '', 'success')
                    this.getAccountPayments("", "", "", "", "", 20, 1)
                }
            }
        })
    }

    handleAddTableItem() {
        let { AccountPayableDetails } = this.state;
        AccountPayableDetails.push({
            ShopPay_Date: "",
            // ShopPay_Acc: "",
            ShopPay_Desc: "",
            ShopPay_Shop: "",
            ShopPay_Amnt: 0
        })

        this.setState({ AccountPayableDetails })
    }



    handleRemoveTableItem(index) {
        let { AccountPayableDetails } = this.state;
        AccountPayableDetails.splice(index, 1)
        this.setState({ AccountPayableDetails })
    }

    handleChangeSearch = debounceFunc((label, evt) => {
        this.setState({ [label]: evt.target.value }, () => {
            let {
                AP_EXP,
                ACCOUNT_TITLE,
                CREATED_BY,
                AP_STATUS
            } = this.state;
            this.handleSearch(AP_EXP, ACCOUNT_TITLE, CREATED_BY, AP_STATUS)
        })
    })

    handleSearch = (
        AP_EXP = "",
        ACCOUNT_TITLE = "",
        CREATED_BY = "",
        AP_STATUS = "",
    ) => {
        this.getAccountPayments(AP_EXP, ACCOUNT_TITLE, CREATED_BY, AP_STATUS)
    }


    render() {
        let { addAccPayment, shopPaymentDays, AccountPayableDetails } = this.state

        return (

            <Container>
                {!addAccPayment ?
                    <PhoneClinicGrid
                        title="ACCOUNT PAYMENT"
                        buttionLabel="Account Payment"
                        onClickButton={this.hanldeAddAccPayment.bind(this)}
                        handleChangeSearch={(key) => this.handleChangeSearch.bind(this, key)}
                        tableHeadData={[
                            {
                                label: "WEEK",
                                isHidden: false
                            },
                            {
                                label: "ACCOUNT",
                                isHidden: false
                            },
                            {
                                label: "WEEKLY EXPENSE",
                                isHidden: false
                            },
                            {
                                label: "CREATED BY",
                                isHidden: false
                            },
                            {
                                label: "STATUS",
                                isHidden: false
                            },
                        ]}
                        searchData={[
                            {
                                key: "WEEK_SEARCH",
                                isHidden: false,
                                isDisable: true
                            },
                            {
                                key: "ACCOUNT_TITLE",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "AP_EXP",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "CREATED_BY",
                                isHidden: false,
                                isDisable: false
                            },
                            {
                                key: "AP_STATUS",
                                isHidden: false,
                                isDisable: false
                            }
                        ]}
                    >
                        {this.state.AccPaymentsList.map((service, idx) => {
                            let ParsingData = JSON.parse(service.AP_WEEK_DAYS);
                            let ShopName = service.AP_SHOP_ID;
                            let FilterShop = this.state.AccountList.filter(x => x.value === service.AP_SHOP_ID)
                            if (FilterShop.length > 0) {
                                ShopName = FilterShop[0].label
                            }
                            return (
                                <TableRow
                                    // className="table-rows"
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <TableCell align='center' >{`${ParsingData[0]['DateAndDay']} - ${ParsingData[6]['DateAndDay']}`}</TableCell>
                                    <TableCell align='center' style={{ cursor: 'pointer', color: "#416bc7" }} >{ShopName}</TableCell>
                                    <TableCell align='center' >{parseFloat(service.AP_EXP).toFixed(2)}</TableCell>
                                    <TableCell align='center' >{service.CREATED_BY}</TableCell>
                                    <TableCell align='center' style={{ cursor: 'pointer', color: "#416bc7" }}>{service.AP_STATUS}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ padding: "0px" }} aria-label="Edit Data" component="span" onClick={this.handleUpdateAccPay.bind(this, idx)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <IconButton style={{ color: '#c73f3f', padding: "0px" }} aria-label="Remove Data" component="span" onClick={this.handleDelete.bind(this, service.SP_ID)} >
                                            <RemoveIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </PhoneClinicGrid>

                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Weekly Account Payments</legend>
                        </Row>
                        <Row>
                            <Col md={8} >
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <span>
                                                    Week
                                                </span>
                                                <span>
                                                    {shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}
                                                </span>
                                            </Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                type={"week"}
                                                disabled={this.state.isEdit === true && this.state.addAccPayment === true ? true : false}
                                                value={this.state.PaymentWeek}
                                                onChange={this.handleChange.bind(this, "PaymentWeek")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Account</Form.Label>
                                            <Select
                                                menuPlacement="auto"
                                                style={{ height: '32px' }}
                                                isDisabled={this.state.isAccountDisable}
                                                value={this.state.ShopPayment}
                                                options={this.state.AccountList}
                                                onChange={this.handleChange.bind(this, "ShopPayment")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} >
                                <Row>
                                    <Col md={6}>
                                        {/* <Form.Group className="mb-3" >
                                            <Form.Label>Prev Week</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addAccPayment === true ? true : false}
                                                value={this.state.PrevWeek}
                                                onChange={this.handleChange.bind(this, "PrevWeek")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Total Amount</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addAccPayment === true ? true : false}
                                                value={this.state.TotalAmount}
                                                onChange={this.handleChange.bind(this, "TotalAmount")}
                                            />
                                        </Form.Group> */}
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Expense</Form.Label>
                                            <Form.Control
                                                size={"small"}
                                                style={{ padding: "0.25rem" }}
                                                disabled={true}
                                                value={parseFloat(this.state.Expense).toFixed(2)}
                                                onChange={this.handleChange.bind(this, "Expense")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>CW Card</Form.Label>
                                            <Form.Control
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addAccPayment === true ? true : false}
                                                value={this.state.CWCard}
                                                onChange={this.handleChange.bind(this, "CWCard")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Net Amount</Form.Label>
                                            <Form.Control
                                                size={"small"}
                                                style={{ padding: "0.25rem" }}
                                                disabled={this.state.isEdit === true && this.state.addAccPayment === true ? true : false}
                                                value={this.state.NetAmount}
                                                onChange={this.handleChange.bind(this, "NetAmount")}
                                            />
                                        </Form.Group>

                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                        {this.state.isEdit && AccountPayableDetails.length > 0 ?
                            <Row>
                                <Col md={12} >
                                    <Paper>
                                        <TableContainer sx={{ maxHeight: '70vh' }}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >SNo</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Date</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >To</TableCell>
                                                        {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Account</TableCell> */}
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                        <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                            <div onClick={this.handleAddTableItem.bind(this)} >
                                                                <Add className="HoverButtonAdd" />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {AccountPayableDetails.map((service, idx) => {
                                                        return (
                                                            <TableRow
                                                                className="table-rows"
                                                                key={idx}
                                                                style={{ cursor: 'pointer' }}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>{idx + 1}</TableCell>

                                                                <TableCell>
                                                                    <Form.Control
                                                                        name="ShopPay_Date"
                                                                        style={{ padding: "0.25rem" }}
                                                                        type="date"
                                                                        min={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[0]['DateAndDay']) : ''}`}
                                                                        max={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[6]['DateAndDay']) : ''}`}
                                                                        value={service.ShopPay_Date}
                                                                        onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Date')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        name="ShopPay_Shop"
                                                                        menuPlacement="auto"
                                                                        isClearable={true}
                                                                        style={{ height: '32px' }}
                                                                        value={service.ShopPay_Shop}
                                                                        options={this.state.groupedOptions}
                                                                        onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Shop')}
                                                                    />
                                                                </TableCell>
                                                                {/* <TableCell>
                                                                    <Select
                                                                        name="ShopPay_Acc"
                                                                        menuPlacement="auto"
                                                                        isClearable={true}
                                                                        style={{ height: '32px' }}
                                                                        value={service.ShopPay_Acc}
                                                                        options={this.state.AccountList}
                                                                        onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Acc')}
                                                                    />
                                                                </TableCell> */}
                                                                <TableCell>
                                                                    <Form.Control
                                                                        name="ShopPay_Desc"
                                                                        style={{ padding: "0.25rem" }}
                                                                        value={service.ShopPay_Desc}
                                                                        onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Desc')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Form.Control
                                                                        name="ShopPay_Amnt"
                                                                        style={{ padding: "0.25rem" }}
                                                                        value={service.ShopPay_Amnt}
                                                                        onChange={this.handleChangeShopPayment.bind(this, idx, 'ShopPay_Amnt')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div onClick={this.handleRemoveTableItem.bind(this, idx)} >
                                                                        <Remove className="HoverButtonRemove" />
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Col>
                            </Row>
                            :
                            ""
                        }
                        <Row>
                            <Col>
                                <Button onClick={this.handleSubmitAccPayment.bind(this)} >{this.state.isEdit ? 'Update Payment' : 'Add Payment'}</Button>
                                <Button onClick={this.handleExit.bind(this)} >Cancel</Button>
                                {/* {isEdit ? <Button style={{ background: '#befebf', color: '#1c8709' }} variant={'contained'} onClick={this.handleSendForApproval.bind(this)} >Send For Approval</Button> : ''} */}
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={12} >
                                <Paper>
                                    <TableContainer sx={{ maxHeight: '70vh' }}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Shop</TableCell>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Date</TableCell>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.AccPayClaimDetail.map((service, idx) => {
                                                    return (
                                                        <TableRow
                                                            className="table-rows"
                                                            key={idx}
                                                            style={{ cursor: 'pointer' }}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Col>
                        </Row> */}

                    </div>
                }
            </Container>
        );
    }
}

export default AccountPayments;